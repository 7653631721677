import { getCookie, createCookie, isBrowser } from "../context/helpers";

function getRandomAorB(percentage) {
  const randomValue = Math.random();

  if (randomValue < percentage) {
    return "A";
  }
  return "B";
}

function useGetABTest(percentage, type) {
  if (isBrowser) {
    const COOKIE_KEY = `AB_${type}`;
    const cookieABTest = getCookie(COOKIE_KEY);

    if (!cookieABTest) {
      createCookie(COOKIE_KEY, getRandomAorB(percentage), 4);
    }
    return getCookie(COOKIE_KEY);
  }
  return "A";
}

export default useGetABTest;
