import PropTypes from "prop-types";
import { useMemo } from "react";
import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import useManyCheckInventory from "../hooks/useManyCheckInventory";
import ProductAlternativeColor from "./productAlternatives/ProductAlternativeColor";
import ProductAlternativeTextBlock from "./productAlternatives/ProductAlternativeTextBlock";
import { ComponentType } from "../constants/ComponentType";

const ALTERNATIVES_NAME = {
  COLOR: "Color",
};

const ALTERNATIVES_ORDER = ["COLOR", "DEFAULT"];

function getAlternativeNameOrder(attrName) {
  const key = Object.entries(ALTERNATIVES_NAME).find(([, value]) => value === attrName)?.[0];
  return ALTERNATIVES_ORDER.includes(key)
    ? ALTERNATIVES_ORDER.indexOf(key)
    : ALTERNATIVES_ORDER.indexOf("DEFAULT");
}

function getFormattedAttributesFromAlternative(alternative, inventoryData) {
  const inventoryDataIsLoading = inventoryData.isLoading;
  const inventoryDataIsFound = inventoryData?.isFound;
  const inventoryDataIsAvailableForSale = inventoryData?.availableForSale;
  const separator = /\s*-\s*/;

  // Split by - with or without spacing around it
  return alternative.attributes?.reduce((acc, attr) => {
    const [attrName, ...attrArgs] = attr.split(separator);

    acc[attrName] = {
      attrName,
      attrArgs,
      handle: alternative.handle,
      isFound: inventoryDataIsLoading ? true : inventoryDataIsFound,
      availableForSale: inventoryDataIsLoading ? true : inventoryDataIsAvailableForSale,
    };
    return acc;
  }, {});
}

function areAttributesEqual(attrArgsA, attrArgsB) {
  return (
    attrArgsA.length === attrArgsB.length &&
    attrArgsA.every((attrArg, idx) => attrArg === attrArgsB[idx])
  );
}

function countSimilarAttributes(originalAlternative, alternative) {
  return Object.entries(originalAlternative).reduce(
    (acc, [key, value]) =>
      areAttributesEqual(value.attrArgs, alternative[key]?.attrArgs || []) ? acc + 1 : acc,
    0
  );
}

function ProductAlternatives({
  handle,
  productAlternatives,
  componentType,
  handleAlternativeClick,
  stepPrefix, // used to show multi step indicator
}) {
  const alternatives = useMemo(
    () => productAlternatives?.alternatives || [],
    [productAlternatives]
  );
  const productsData = useManyCheckInventory(alternatives);

  // alternativesByAttributes = { "Color": [...], "Pendant": [...], "Choose a product": [...] }
  const alternativesByAttributes = useMemo(() => {
    const formattedAlternativesByAttribute = {};
    const pageProductAlternativeIndex = alternatives.findIndex((alt) => alt.handle === handle);
    if (pageProductAlternativeIndex === -1) return null;
    const formattedAlternativesByHandle = {
      [handle]: getFormattedAttributesFromAlternative(
        alternatives[pageProductAlternativeIndex],
        productsData[pageProductAlternativeIndex]
      ),
    };
    const pageProductAttributes = formattedAlternativesByHandle[handle];

    alternatives?.forEach((alt, idx) => {
      const alternativeAttribute = getFormattedAttributesFromAlternative(alt, productsData[idx]);
      formattedAlternativesByHandle[alt.handle] = alternativeAttribute;
      Object.entries(alternativeAttribute).forEach(([key, value]) => {
        // Search for a similar alternative, if one with identical attribute exist,
        // we take the one that match the page prodruct the closest.
        const competingAttributeIndex = (formattedAlternativesByAttribute[key] || []).findIndex(
          (formattedAlt) => areAttributesEqual(formattedAlt.attrArgs, value.attrArgs)
        );

        if (competingAttributeIndex === -1) {
          formattedAlternativesByAttribute[key] = [
            ...(formattedAlternativesByAttribute[key] || []),
            value,
          ];
        } else {
          const competingAttributeSimilarities = countSimilarAttributes(
            pageProductAttributes,
            formattedAlternativesByHandle[
              formattedAlternativesByAttribute[key][competingAttributeIndex].handle
            ]
          );
          const currentAttributeSimilarities = countSimilarAttributes(
            pageProductAttributes,
            alternativeAttribute
          );
          if (currentAttributeSimilarities > competingAttributeSimilarities) {
            formattedAlternativesByAttribute[key][competingAttributeIndex] = value;
          }
        }
      });
    });

    return formattedAlternativesByAttribute;
  }, [productsData, alternatives, handle]);

  if (!alternativesByAttributes || alternatives.length === 1) {
    return null;
  }

  return (
    <div key={`${handle}-alternatives`} className="product-alternatives">
      {Object.entries(alternativesByAttributes)
        .sort(
          ([attrNameA], [attrNameB]) =>
            getAlternativeNameOrder(attrNameA) - getAlternativeNameOrder(attrNameB)
        )
        .map(([attrName, alternativesOfAttr]) => {
          if (attrName === ALTERNATIVES_NAME.COLOR) {
            return (
              <ProductAlternativeColor
                key={attrName}
                pageProductHandle={handle}
                alternatives={alternativesOfAttr}
                handleAlternativeClick={handleAlternativeClick}
                componentType={componentType}
              />
            );
          }
          if (componentType !== ComponentType.CP && componentType !== ComponentType.CP_LAZY) {
            return (
              <ProductAlternativeTextBlock
                key={attrName}
                pageProductHandle={handle}
                alternatives={alternativesOfAttr}
                labelTitle={attrName}
                stepPrefix={stepPrefix}
                componentType={componentType}
              />
            );
          }
          return null;
        })}
    </div>
  );
}

ProductAlternatives.propTypes = {
  handle: PropTypes.string.isRequired,
  productAlternatives: PropTypes.shape({
    alternatives: PropTypes.arrayOf(
      PropTypes.shape({
        attributes: PropTypes.arrayOf(PropTypes.string).isRequired,
        handle: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  componentType: PropTypes.string,
  handleAlternativeClick: PropTypes.func,
  stepPrefix: PropTypes.string,
};

export default withALErrorBoundary({
  name: "ProductAlternatives",
  priority: "P2",
})(ProductAlternatives);
