import { Script } from "gatsby";

const pinterestScript = () => ({
  __html: `
  !function(e){if(!window.pintrk){window.pintrk = function () {
    window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
      n=window.pintrk;n.queue=[],n.version="3.0";var
      t=document.createElement("script");t.async=!0,t.src=e;var
      r=document.getElementsByTagName("script")[0];
      r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
    pintrk('load', '2617658230499');
    pintrk('page');`,
});

export function wrapPageElement({ element }) {
  return (
    <>
      {element}
      <Script dangerouslySetInnerHTML={pinterestScript()} />
    </>
  );
}
