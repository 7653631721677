import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../../../helpers/ErrorBoundary/ALErrorBoundary";
import MenuCategory from "./menuContainer/MenuCategory";
import MenuLinkWithImage from "./menuContainer/MenuLinkWithImage";

function MenuContainer({ categories, imageLinks, menuTitle, closeMenu }) {
  return (
    <div
      className={`menu_content_elements  ${
        !categories?.length ? "menu_content_elements_no_text" : ""
      }`}
    >
      {categories && categories.length > 0 && (
        <div className="menu_content_elements_l">
          {categories.map((category) => (
            <MenuCategory
              key={category.id}
              category={category}
              menuTitle={menuTitle}
              closeMenu={closeMenu}
            />
          ))}
        </div>
      )}

      {imageLinks && imageLinks.length > 0 && (
        <ul className="menu_content_elements_i">
          {imageLinks.map((imageLink) => (
            <MenuLinkWithImage
              key={imageLink.id}
              link={imageLink.link.link.link}
              title={imageLink.link.title}
              image={imageLink.image}
              menuTitle={menuTitle}
              closeMenu={closeMenu}
            />
          ))}
        </ul>
      )}
    </div>
  );
}

MenuContainer.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  menuTitle: PropTypes.string,
  imageLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.object,
      link: PropTypes.object,
      title: PropTypes.string,
    })
  ),
  closeMenu: PropTypes.func,
};

export default withALErrorBoundary({
  name: "MenuContainer",
  priority: "P2",
})(MenuContainer);
