export const calDate = (currDate, endDate) => {
  let seconds;
  let minutes;
  let hours;
  const millSecs = endDate.getTime() - currDate.getTime();

  seconds = Math.floor(millSecs / 1000);
  minutes = Math.floor(seconds / 60);
  seconds %= 60;
  hours = Math.floor(minutes / 60);
  minutes %= 60;
  const days = Math.floor(hours / 24);
  hours %= 24;

  return { days, hours, minutes, seconds };
};

export const countdown = (start, end) => {
  const timerStart = new Date(start);
  const timerEnd = new Date(end);
  const currDate = new Date();

  if (currDate >= timerStart && currDate < timerEnd) {
    return calDate(currDate, timerEnd);
  }
  return null;
};
