import { useContext } from "react";
import { LuxeContext } from "../../context/LuxeProvider";
import { useAuth } from "../../context/AuthProvider";

import { removeURLParam } from "../../context/helpers";
import CloseIcon from "../icons/svgs/close.svg";

import * as Styles from "./styles.module.scss";

function MembershipCreditFrame({ style = {}, setDisplayCredits }) {
  const { luxe } = useContext(LuxeContext);
  const { showLogin } = useAuth();

  return (
    <div className={Styles.membership_credit_frame} style={style}>
      <div
        className={Styles.membership_credit_frame_close}
        onClick={() => {
          removeURLParam("credit", null, true);
          setDisplayCredits(false);
        }}
      >
        <CloseIcon fill="#fbf7ec" />
      </div>
      {luxe.subscriptionCredit ? (
        <>
          <h3 className="h3 h3--bold h3--uc m-0 mb-20">
            ${luxe.subscriptionCredit} credit available
          </h3>
          <p className="subtext">
            Shop this collection early with your available credit. Use the slider at checkout to
            redeem your money off!
          </p>
        </>
      ) : (
        <>
          <h3 className="h3 h3--bold h3--uc m-0 mb-20">Already a Luxe member?</h3>
          <p className={`subtext ${Styles.membership_credit_frame_login}`} onClick={showLogin}>
            <u className="subtext--bold">Sign in</u> to get early access to this collection!
          </p>
        </>
      )}
    </div>
  );
}

export default MembershipCreditFrame;
