import { Link } from "gatsby";
import { useLocalizedSentenceDict } from "../../../../hooks/useSentenceDict";

export default function ContentCare() {
  const dict = useLocalizedSentenceDict();
  return (
    <>
      <p className="subtext">
        {dict.get(
          "Our jewelry is designed to be water-resistant, so you don't have to worry about taking it off when you're near water. However, we recommend limiting prolonged exposure to water to keep your jewelry looking its best."
        )}
      </p>
      <br />
      <p className="subtext">
        {dict.get(
          "When you're not wearing your pieces, we suggest storing them in the original Ana Luisa pouch in a cool, dry place to help maintain their luster and extend their lifespan."
        )}
      </p>
      <br />
      <p className="subtext">
        {dict.get("For more details, check out our")}&nbsp;
        <Link className="link--underline" to="/pages/how-to-take-care">
          {dict.get("Jewelry care page")}
        </Link>
        .
      </p>
    </>
  );
}
