import { memo } from "react";
import PropTypes from "prop-types";
import IconStar from "../../../icons/svgs/star.svg";
import IconChecked from "../../../icons/svgs/check.svg";
import { withALErrorBoundary } from "../../../../helpers/ErrorBoundary/ALErrorBoundary";
import useSEOSentenceToLinkConversion from "../../../../hooks/useSEOSentenceToLinkConversion";

import * as Styles from "./bestReviews/styles.module.scss";

function Review({ content, name, stars }) {
  const contentWithSEO = useSEOSentenceToLinkConversion(content);

  return (
    <div className={Styles.bestReviews__card} data-testid="best-review-card">
      <span className={Styles.bestReviews__card__stars}>
        {[...Array(stars)].map((_, index) => (
          <IconStar fill="#2D2927" stroke="#2D2927" width="16" key={index} />
        ))}
      </span>
      <p className={Styles.bestReviews__card__body}>{contentWithSEO}</p>
      <div className={Styles.bestReviews__card__customer}>
        <p className={Styles.bestReviews__card__customer__name}>{name}</p>
        <IconChecked width="14" height="14" />
      </div>
    </div>
  );
}

function BestReviews({ scrollToReviews, reviews }) {
  if (reviews.length === 0) {
    return null;
  }
  return (
    <div className={Styles.bestReviews}>
      <h2 className={Styles.bestReviews__title}>Most Helpful Reviews</h2>
      <div className={Styles.bestReviews__container}>
        {reviews.map((bestReview) => (
          <Review
            key={bestReview?.id}
            content={bestReview?.content?.content}
            name={bestReview?.name}
            stars={bestReview?.stars}
          />
        ))}
      </div>
      <button
        onClick={() => scrollToReviews && scrollToReviews()}
        className={Styles.scrollToReviews}
        type="button"
      >
        View all reviews
      </button>
    </div>
  );
}

BestReviews.propTypes = {
  scrollToReviews: PropTypes.func,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      stars: PropTypes.number,
    })
  ),
};

export default memo(
  withALErrorBoundary({
    name: "BestReviews",
    priority: "P3",
  })(BestReviews)
);
