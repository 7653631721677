import { useContext } from "react";
import { Popup } from "./Popup";

import StoreContext from "../context/store";

import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import ProductDetailContent from "./ProductDetailsContent";
import { ComponentType } from "../constants/ComponentType";

import CloseIcon from "./icons/svgs/close-round.svg";

function QuickViewPopup() {
  const context = useContext(StoreContext);

  if (!context.store.quickViewProductHandle) {
    return null;
  }

  return (
    <Popup
      open={!!context.store.quickViewProductHandle}
      closeOnDocumentClick
      onClose={context.closeQuickView}
      className="quickview_popup"
      modal
      nested
    >
      <button type="button" className="close_popup" onClick={context.closeQuickView}>
        <CloseIcon width="30" height="30" fill="none" stroke="#2D2927" />
      </button>
      <ProductDetailContent
        productHandle={context.store.quickViewProductHandle}
        productLinkToPDP={context.store.quickViewLinkToPDP}
        showProductDetailsLink
        componentType={ComponentType.QUICKVIEW}
      />
    </Popup>
  );
}

export default withALErrorBoundary({
  name: "QuickViewPopup",
  priority: "P3",
})(QuickViewPopup);
