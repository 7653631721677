// extracted by mini-css-extract-plugin
export var backdrop = "styles-module--backdrop--639c8";
export var backdropClosed = "styles-module--backdrop-closed--4aa51";
export var backdropVisible = "styles-module--backdrop-visible--819af";
export var bottomSection = "styles-module--bottom-section--bb486";
export var container = "styles-module--container--9fd3d";
export var drawer = "styles-module--drawer--72d47";
export var drawerClosed = "styles-module--drawer-closed--3b123";
export var drawerOpen = "styles-module--drawer-open--3dfdf";
export var header = "styles-module--header--06bd2";
export var products = "styles-module--products--5ae60";