import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { useALError, withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useAuth } from "../../context/AuthProvider";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";

import { ALLoading, ALButton } from "../ALComponents";

import IconClose from "../icons/svgs/close-round.svg";
import "./accountPopup.scss";

function CreateAccount({ onClose }) {
  const { sendReport } = useALError();
  const dict = useLocalizedSentenceDict();
  const { showLogin, signup, isLoading, error } = useAuth();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { email, password, firstName, lastName } = data;
    signup(email, password, firstName, lastName, `${window.location.origin}/account/`).catch(
      (e) => {
        sendReport(e, { name: "CreateAccount", priority: "P1" });
        return e?.data || JSON.stringify(e);
      }
    );
  };

  return (
    <form className="account-form" onSubmit={handleSubmit(onSubmit)}>
      <button type="button" className="close-icon" onClick={onClose}>
        <IconClose width="30" fill="none" stroke="#2D2927" />
      </button>

      <h2 className="account-title">{dict.get("Create Account")}</h2>
      <p className="instructions">
        {dict.get("Create an account for")}{" "}
        <span className="al_p--bold">{dict.get("faster checkout, order updates, and more!")}</span>
      </p>
      <input
        placeholder={dict.get("Email")}
        type="email"
        className="account_input"
        required
        {...register("email")}
      />
      <div className="name-container">
        <input
          placeholder={dict.get("First Name")}
          className="account_input"
          {...register("firstName")}
        />
        <input
          placeholder={dict.get("Last Name")}
          className="account_input"
          {...register("lastName")}
        />
      </div>
      <input
        placeholder={dict.get("Password")}
        type="password"
        className="account_input"
        required
        {...register("password")}
      />
      <p className="legend">{dict.get("Must be 6+ characters and include 1 number.")}</p>

      <ALButton
        className="submit-btn"
        type="submit"
        fullWidth
        size="large"
        variant="primary"
        disabled={isLoading}
      >
        {isLoading ? <ALLoading fill="white" /> : dict.get("SIGN UP")}
      </ALButton>

      {error && (
        <div className="error" data-testid="create-account-errors">
          {error}
        </div>
      )}

      <p className="microtext registration-agreement-text">
        {dict.get(
          "By registering, I agree to Ana Luisa's [Terms & Conditions](/policies/terms-of-service), [Privacy Policy](/policies/privacy-policy) and receiving marketing emails."
        )}
      </p>
      <p>
        {dict.get("Already have an account?")}{" "}
        <button type="button" className="secondary-action" onClick={showLogin}>
          {dict.get("Login here")}
        </button>
      </p>
    </form>
  );
}

CreateAccount.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default withALErrorBoundary({
  name: "CreateAccount",
  priority: "P1",
})(CreateAccount);
