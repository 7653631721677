import { Script } from "gatsby";

const hotjarScript = () => ({
  __html: `
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:752349,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
  `,
});

export function wrapPageElement({ element }) {
  if (typeof window !== "undefined") {
    const botUserAgentsArray = ["Googlebot", "Storebot-Google", "HeadlessChrome"];
    const { userAgent } = window.navigator;
    if (userAgent) {
      let isBotUserAgent = 0;
      for (let j = 0; j < botUserAgentsArray.length; j++) {
        if (userAgent.toLowerCase().indexOf(botUserAgentsArray[j].toLowerCase()) !== -1) {
          isBotUserAgent = 1;
          break;
        }
      }

      if (isBotUserAgent === 1) {
        return element;
      }
    }
  }
  return (
    <>
      {element}
      <Script
        id="hotjar-script"
        dangerouslySetInnerHTML={hotjarScript()}
        strategy="off-main-thread"
      />
    </>
  );
}
