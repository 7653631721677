import { useEffect, useState } from "react";
import { isBrowser } from "../../context/helpers";

function pad(num) {
  return `${num}`.padStart(2, "0");
}

function DefaultTimer({ expiryTime, onExpirationTime, timerTypeUpdated }) {
  const calculateTimeLeft = (now, expiryTime) => {
    if (isBrowser) {
      const timeLeft = expiryTime - now;
      return {
        h: Math.floor((timeLeft / (1000 * 60 * 60)) % 24),
        m: Math.floor((timeLeft / 1000 / 60) % 60),
        s: Math.floor((timeLeft / 1000) % 60),
      };
    }
    return {
      h: 0,
      m: 59,
      s: 59,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(new Date().getTime(), expiryTime));

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      let currentExpiryTime = expiryTime;
      if (expiryTime <= now) {
        currentExpiryTime = onExpirationTime();
      }
      setTimeLeft(calculateTimeLeft(now, currentExpiryTime));
    }, 1000);
    return () => clearInterval(interval);
  }, [timerTypeUpdated, expiryTime]);

  return (
    <>
      {pad(timeLeft.h)}h : {pad(timeLeft.m)}m : {pad(timeLeft.s)}s
    </>
  );
}
export default DefaultTimer;
