import { useContext, useEffect } from "react";
import { HiddenElementsContext } from "../context/HiddenElements";
import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";

function FloatingPromotion() {
  const { shouldHide } = useContext(HiddenElementsContext);

  useEffect(() => {
    const floatingPromotion = document.getElementById("ju_bbox");

    if (floatingPromotion) {
      floatingPromotion.style.display = shouldHide ? "none" : "block";
    }
  }, [shouldHide]);

  return null;
}

export default withALErrorBoundary({
  name: "FloatingPromotion",
  priority: "P3",
})(FloatingPromotion);
