import { useContext, useState } from "react";
import { PropTypes } from "prop-types";
import { Popup } from "../../Popup";

import StoreContext from "../../../context/store";
import { resizeShopifyImage } from "../../../context/helpers";

import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import useDeviceDetect from "../../../hooks/useDeviceDetect";

import IconClose from "../../icons/svgs/close.svg";
import { ALButton } from "../../ALComponents";
import AddIcon from "../../icons/svgs/add.svg";
import ALSkeleton from "../../al_components/ALSkeleton";

import "./optionalItemPopup/styles.scss";

function OptionalItemPopup({ byobLineItems, handleOptionalItemAdd, percentageOff }) {
  const { setBYOBOptionalItemPopup, store } = useContext(StoreContext);
  const dict = useLocalizedSentenceDict();
  const { isMobile } = useDeviceDetect();

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const closePopup = () => {
    setBYOBOptionalItemPopup(false);
  };

  return (
    <Popup
      open={store.isBYOBOptionalItemPopupOpen}
      modal
      closeOnDocumentClick
      className="optional_item_popup"
      onClose={closePopup}
    >
      <button type="button" className="close-icon" onClick={closePopup}>
        <IconClose width="30" fill="#2D2927" />
      </button>
      <div>
        <p className="h3 h3--bold h3--uc optional_item_popup__title">
          {dict.get(`${percentageOff}% off, anyone?`)}
        </p>
        <p className="text">
          {dict.get(`Add another item & get ${percentageOff}% off your bundle!`)}
        </p>
        <div className="optional_item_popup__products">
          {byobLineItems.map((item) => (
            <div key={item?.id}>
              {!isImageLoaded && (
                <div className="optional_item_popup__products_loading">
                  <ALSkeleton />
                </div>
              )}
              <img
                onLoad={() => setIsImageLoaded(true)}
                src={resizeShopifyImage(item?.variant?.image?.url, "200x200")}
                alt={item?.variant?.image?.altText}
                style={!isImageLoaded ? { position: "absolute" } : null}
              />
            </div>
          ))}
          <button
            type="button"
            className="optional_item_popup__products_placeholder"
            data-testid="image_placeholder"
            onClick={() => handleOptionalItemAdd(byobLineItems, true)}
          >
            <AddIcon width={isMobile ? 11 : 13} fill="#DEDEDE" />
          </button>
        </div>
        <ALButton
          className="optional_item_popup__button"
          fullWidth
          onClick={() => handleOptionalItemAdd(byobLineItems, true)}
        >
          {dict.get("Choose & Save")}
        </ALButton>
      </div>
    </Popup>
  );
}

OptionalItemPopup.propTypes = {
  byobLineItems: PropTypes.array,
  handleOptionalItemAdd: PropTypes.func,
  percentageOff: PropTypes.number,
};

export default OptionalItemPopup;
