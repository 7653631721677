import axios from "axios";
import { useContext, useMemo } from "react";
import { useQueries } from "@tanstack/react-query";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { GeoContext } from "../context/GeoProvider";

const CACHE_AVAILABILITY = 5 * 60 * 1000; // 5 minutes

// Return an array of [available: boolean, variants: array] from the check-inventory call
function useManyCheckInventory(products) {
  const { sendReport } = useALError();
  const { gePriceDetails, isLoadingCountry } = useContext(GeoContext);
  const countryCode = gePriceDetails?.CountryCode;

  const results = useQueries({
    queries: products.map((product) => {
      const handle = product?.handle;
      return {
        queryKey: ["product-availability", { handle, countryCode }],
        queryFn: () =>
          axios.get(
            `/.netlify/functions/check-inventory?handle=${handle}&countryCode=${countryCode}&multi=true`
          ),
        staleTime: CACHE_AVAILABILITY,
        enabled: !!handle && !!countryCode && !isLoadingCountry,
        useErrorBoundary: true,
        meta: { name: "useManyCheckInventory", priority: "P1", sendReport },
      };
    }),
  });

  const memoizedResult = useMemo(
    () =>
      results.map(({ data, isLoading }) => ({
        handle: data?.data?.handle,
        availableForSale: !!data?.data?.availableForSale,
        variants: data?.data?.variants || [],
        isLowInStock: data?.data?.isLowInStock,
        isLoading,
        isFound: !!data?.data?.isFound,
      })),
    [results]
  );

  return memoizedResult;
}

export default useManyCheckInventory;
