import { useRef } from "react";

import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import BrowseProductsProduct from "./blocks/BrowseProductsProduct";

import IconArrowCircleMd from "./icons/svgs/arrow-circle-md.svg";

import useDeviceDetect from "../hooks/useDeviceDetect";
import useResponsiveWithHydrationFix from "../hooks/useResponsiveWithHydrationFix";
import { useProductData2 } from "../context/products";
import { useCurrentCountryCode } from "../hooks/usePrices";
import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import { ComponentType } from "../constants/ComponentType";

import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import "./recommendedProducts/styles.scss";

export default function RecommendedProducts({
  title = "Recommended Products",
  handles,
  collection,
  recommendationId,
  componentType,
  handleProductClick,
}) {
  const dict = useLocalizedSentenceDict();
  const { isMobile } = useDeviceDetect();
  const scrollableElement = useRef(null);
  const countryCode = useCurrentCountryCode();
  const { data: products } = useProductData2(handles, "short", countryCode);
  const classes = [componentType?.toLowerCase()];

  SwiperCore.use([Navigation, Pagination, A11y]);

  const productsData = products.filter(
    (product) => product && !product.node.variants.every((v) => v.availableForSale == false)
  );

  const classTitleHp = useResponsiveWithHydrationFix("h3 h3--bold", "h2 h2--bold");

  const titleClassNames = {
    default: classTitleHp,
    [ComponentType.PDP]: "pdp_recommended_header_title",
    [ComponentType.SEARCH]: "h3 h3--bold h3--uc",
  };

  return productsData && productsData.length > 0 ? (
    <div className={`recommended ${classes.reduce((str, e) => `${str} recommended_${e}`, "")}`}>
      <div className="recommended_header">
        {title ? (
          <h2
            className={`recommended_header_title ${titleClassNames.default} ${titleClassNames[componentType]}`}
          >
            {dict.get(title)}
          </h2>
        ) : null}
        {!isMobile ? (
          <div className="recommended_buttons">
            <div className={`swiper_prev_button bought_together_${collection}`}>
              <IconArrowCircleMd width="32" stroke="#2D2927" />
            </div>
            <div className={`swiper_next_button bought_together_${collection}`}>
              <IconArrowCircleMd width="32" stroke="#2D2927" />
            </div>
          </div>
        ) : null}
      </div>
      {isMobile ? (
        <div>
          <div className={`recommended_content ${classes || ""}`} ref={scrollableElement}>
            {productsData.map((p, i) => (
              <BrowseProductsProduct
                handle={p.node.handle}
                product={p}
                key={`browse_product_${i}`}
                sectionTitle={title}
                recommendationId={recommendationId}
                componentType={componentType}
                handleProductClick={handleProductClick}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="recommended_content">
          <Swiper
            navigation={{
              nextEl: `.swiper_next_button.bought_together_${collection}`,
              prevEl: `.swiper_prev_button.bought_together_${collection}`,
              disabledClass:
                componentType === ComponentType.PDP
                  ? "swiper_disabled_button_recommended"
                  : "swiper_disabled_button",
            }}
            spaceBetween={8}
            slidesPerView={4}
            slidesPerGroup={4}
          >
            {productsData.map((p, i) => (
              <SwiperSlide key={`browse_product_${i}`}>
                <BrowseProductsProduct
                  handle={p.node.handle}
                  product={p}
                  sectionTitle={title}
                  recommendationId={recommendationId}
                  componentType={componentType}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  ) : null;
}
