import PropTypes from "prop-types";
import axios from "axios";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "@reach/router";

import { CartContext } from "./CartProvider";
import { DiscountContext } from "./DiscountProvider";
import { useCurrentFormatCurrency } from "../hooks/usePrices";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";

import { createCookie, deleteCookie, getAllUrlParams, getCookie } from "./helpers";
import { getShopifyId } from "../../functions/helpers";

const defaultLoopReturnContext = {
  isLoopReturnEnabled: false,
  returnId: "",
  currency: "",
  absoluteTotal: 0, // base + credit
  total: 0, // *current* (if started) loop cart value
  base: 0, // total value of returned products
  credit: 0, // additional credit offered for return
  remainingAbsoluteTotal: 0,
  formattedRemainingAbsoluteTotal: "",
  redirectURL: "",
  customerName: "",
  loopSubdomain: "",
  initiateCheckout: () => {},
};

export const LoopReturnContext = createContext(defaultLoopReturnContext);

const LOOP_API_KEY = process.env.GATSBY_LOOP_API_KEY;
const LOOP_COOKIE_KEY = "loop_return_data";

async function loopCreateCart(variantIds) {
  const options = {
    method: "POST",
    url: "https://api.loopreturns.com/api/v1/cart/",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      "X-Authorization": LOOP_API_KEY,
    },
    data: { cart: variantIds },
  };
  const { data } = await axios.request(options);
  // Example of response:
  // data: {
  //   "token": "16844436903f56a4bc6a1d202fcb2f64a6d61590502717",
  //   "data": {
  //     "cart": [
  //       42090376364290
  //     ]
  //   }
  // }
  return { token: data.token, cart: data.data.cart };
}

// Get base price; of an item
function getBasePrice(item) {
  if (item.variant.compareAtPrice) {
    return parseFloat(item.variant.compareAtPrice.amount);
  }
  return parseFloat(item.variant.price.amount);
}

export function useAmountRefunded(cartTotalPrice) {
  const { base, absoluteTotal } = useContext(LoopReturnContext);
  let returnCredit = absoluteTotal / 100;
  if (cartTotalPrice <= base / 100) {
    // Bonus credit only valid if purchase higher than the base amount
    returnCredit = base / 100;
  }
  return returnCredit - cartTotalPrice;
}

function LoopReturnProvider({ children }) {
  const { cart, resetCart } = useContext(CartContext);
  const { discountInfo, setFinalDiscount } = useContext(DiscountContext);
  const formatCurrency = useCurrentFormatCurrency();
  const location = useLocation();
  const [loopParams, setLoopParams] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const isLoopReturnEnabled = !!loopParams.returnId;
  const { sendReport } = useALError();

  const removeLoopReturnCookie = useCallback(() => deleteCookie(LOOP_COOKIE_KEY), []);

  const initiateCheckout = useCallback(
    async (shopifyLineItems) => {
      setIsLoading(true);
      try {
        // Create Loop Return cart with the current cart
        const loopCart = await loopCreateCart(
          shopifyLineItems
            .map((lineItem) => Array(lineItem.quantity).fill(getShopifyId(lineItem.variant.id)))
            .flat()
        );

        // Reset the cart (Shopify) so the customer sees an empty cart next time they arrive on the website
        resetCart();

        // Delete cookie
        removeLoopReturnCookie();
        setIsLoading(false);

        // Redirect customer to the Loop Return checkout experience
        window.location.href = `https://returns.analuisa.com/#/cart/v2/${loopCart.token}`;
      } catch (error) {
        sendReport(error, { name: "initiateCheckout", priority: "P5" });
        // Optionally, rethrow the error to allow it to bubble up
        throw error;
      }
    },
    [resetCart, sendReport, removeLoopReturnCookie]
  );

  // Enforce no discount when Loop Return is enabled
  useEffect(() => {
    if (isLoopReturnEnabled && discountInfo?.type !== "no_discount") {
      setFinalDiscount({ type: "no_discount", value: 0, code: "null" });
    }
  }, [discountInfo?.type, setFinalDiscount, isLoopReturnEnabled]);

  // Detect URL params and initiate the Loop Return experience
  useEffect(() => {
    const queryParams = getAllUrlParams(location.href);
    if (queryParams.loop_return_id && queryParams.loop_return_id !== loopParams.returnId) {
      // Reset a previously created cart
      resetCart();
      // Set empty discount
      setFinalDiscount({ type: "no_discount", value: 0, code: "null" });
      // Expose Loop params
      const data = {
        returnId: queryParams.loop_return_id,
        currency: queryParams.loop_currency || defaultLoopReturnContext.currency,
        absoluteTotal:
          Number(queryParams.loop_base) + Number(queryParams.loop_credit) ||
          defaultLoopReturnContext.absoluteTotal,
        total: Number(queryParams.loop_total) || defaultLoopReturnContext.total,
        base: Number(queryParams.loop_base) || defaultLoopReturnContext.base,
        credit: Number(queryParams.loop_credit) || defaultLoopReturnContext.credit,
        remainingCredit:
          Number(queryParams.loop_credit) || defaultLoopReturnContext.remainingCredit,
        formattedRemainingAbsoluteTotal: formatCurrency(
          queryParams.loop_credit || defaultLoopReturnContext.formattedRemainingCredit
        ),
        loopSubdomain: queryParams.loop_subdomain || defaultLoopReturnContext.loopSubdomain,
        redirectURL: queryParams.loop_redirect_url || defaultLoopReturnContext.redirectURL,
        customerName: queryParams.loop_customer_name || defaultLoopReturnContext.customerName,
      };
      setLoopParams(data);
      createCookie(LOOP_COOKIE_KEY, JSON.stringify(data), 7);
    }
  }, [location, resetCart, loopParams.returnId, setFinalDiscount, formatCurrency]);

  // Load existing loop data if customer is currently doing a return
  useEffect(() => {
    const loopReturnData = getCookie(LOOP_COOKIE_KEY);
    if (loopReturnData) {
      const parsedData = JSON.parse(loopReturnData);
      setLoopParams(parsedData);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setLoopParams((state) => {
      const creditInDollar = state.absoluteTotal / 100;
      const totalPrice = cart.lines.reduce((acc, item) => acc + getBasePrice(item), 0);
      const remainingAbsoluteTotal = creditInDollar > totalPrice ? creditInDollar - totalPrice : 0;
      return {
        ...state,
        remainingAbsoluteTotal,
        formattedRemainingAbsoluteTotal: formatCurrency(remainingAbsoluteTotal.toFixed(2)),
      };
    });
  }, [loopParams.total, formatCurrency, cart.lines]);

  const state = useMemo(
    () => ({
      ...loopParams,
      isLoopReturnEnabled,
      initiateCheckout,
      isLoading,
      removeLoopReturnCookie,
    }),
    [loopParams, initiateCheckout, isLoopReturnEnabled, isLoading, removeLoopReturnCookie]
  );

  return <LoopReturnContext.Provider value={state}>{children}</LoopReturnContext.Provider>;
}

LoopReturnProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoopReturnProvider;
