export default {
  AE: "AE",
  AG: "AG",
  AD: "AD",
  AI: "AI",
  AL: "AL",
  AM: "AM",
  AR: "AR",
  AT: "AT",
  AU: "AU",
  AW: "AW",
  AZ: "AZ",
  BA: "BA",
  BB: "BB",
  BD: "BD",
  BE: "BE",
  BG: "BG",
  BJ: "BJ",
  BL: "BL",
  BM: "BM",
  BN: "BN",
  BO: "BO",
  BR: "BR",
  BS: "BS",
  BW: "BW",
  BY: "BY",
  BZ: "BZ",
  CA: "CA",
  CH: "CH",
  CK: "CK",
  CL: "CL",
  CN: "CN",
  CO: "CO",
  CR: "CR",
  CV: "CV",
  CY: "CY",
  CZ: "CZ",
  DE: "DE",
  DJ: "DJ",
  DK: "DK",
  DM: "DM",
  DO: "DO",
  DZ: "DZ",
  EC: "EC",
  EE: "EE",
  EG: "EG",
  ES: "ES",
  ET: "ET",
  FI: "FI",
  FJ: "FJ",
  FK: "FK",
  FO: "FO",
  FR: "FR",
  GB: "GB",
  GD: "GD",
  GE: "GE",
  GG: "GG",
  GI: "GI",
  GL: "GL",
  GN: "GN",
  GR: "GR",
  GT: "GT",
  GW: "GW",
  HK: "HK",
  HN: "HN",
  HR: "HR",
  HT: "HT",
  HU: "HU",
  ID: "ID",
  IE: "IE",
  IL: "IL",
  IS: "IS",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  KE: "KE",
  KG: "KG",
  KH: "KH",
  KM: "KM",
  KN: "KN",
  KR: "KR",
  KY: "KY",
  KZ: "KZ",
  LA: "LA",
  LB: "LB",
  LC: "LC",
  LI: "LI",
  LK: "LK",
  LS: "LS",
  LT: "LT",
  LU: "LU",
  LV: "LV",
  MA: "MA",
  MC: "MC",
  MD: "MD",
  ME: "ME",
  MK: "MK",
  MN: "MN",
  MO: "MO",
  MQ: "MQ",
  MS: "MS",
  MT: "MT",
  MU: "MU",
  MV: "MV",
  MW: "MW",
  MX: "MX",
  MY: "MY",
  MZ: "MZ",
  NA: "NA",
  NG: "NG",
  NI: "NI",
  NL: "NL",
  NO: "NO",
  NP: "NP",
  NR: "NR",
  NU: "NU",
  NZ: "NZ",
  PA: "PA",
  PE: "PE",
  PF: "PF",
  PG: "PG",
  PH: "PH",
  PK: "PK",
  PL: "PL",
  PR: "PR",
  PT: "PT",
  PY: "PY",
  QA: "QA",
  RO: "RO",
  RS: "RS",
  SA: "SA",
  SB: "SB",
  SC: "SC",
  SE: "SE",
  SG: "SG",
  SI: "SI",
  SK: "SK",
  SM: "SM",
  SR: "SR",
  SV: "SV",
  SZ: "SZ",
  TC: "TC",
  TH: "TH",
  TO: "TO",
  TP: "TP",
  TR: "TR",
  TT: "TT",
  TV: "TV",
  TW: "TW",
  TZ: "TZ",
  UA: "UA",
  US: "US",
  UY: "UY",
  UZ: "UZ",
  VA: "VA",
  VE: "VE",
  VG: "VG",
  VU: "VU",
  WS: "WS",
  ZA: "ZA",
  ZM: "ZM",
  ZW: "ZW",
  GF: "FR",
  GP: "FR",
  NC: "FR",
  RE: "FR",
};
