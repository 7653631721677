export const Country = {
  BE: "BE",
  CA: "CA",
  CH: "CH",
  DE: "DE",
  ES: "ES",
  FR: "FR",
  IT: "IT",
  NL: "NL",
  PT: "PT",
  US: "US",
  MX: "MX",
};
