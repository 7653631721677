import { useEffect, useContext } from "react";
// eslint-disable-next-line import/no-unresolved
import { IntelligemsContext, useIgTrack } from "@intelligems/headless/gatsby";

import { GeoContext } from "../context/GeoProvider";
import { CartContext } from "../context/CartProvider";

const IntelligemsHOC = ({ children }) => {
  const { setActiveCurrencyCode } = useContext(IntelligemsContext);
  const { cart } = useContext(CartContext);
  const { gePriceDetails } = useContext(GeoContext);

  useIgTrack({
    cartOrCheckoutToken: cart?.cartId,
    currency: gePriceDetails?.CurrencyCode,
    country: gePriceDetails?.CountryCode,
  });

  // Whenever the currency code changes, let Intelligems know about it
  useEffect(() => {
    if (gePriceDetails?.CurrencyCode) {
      setActiveCurrencyCode(gePriceDetails.CurrencyCode);
    }
  }, [gePriceDetails?.CurrencyCode, setActiveCurrencyCode]);

  return children;
};

export default IntelligemsHOC;
