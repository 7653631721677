import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import LocalePickerContent from "./LocalePickerContent";

function LocalePickerSearchResults({ results, handleClick }) {
  return (
    <div className="locale_picker__container">
      {results.map((result) => (
        <LocalePickerContent
          key={`locale_search_${result?.country}`}
          locale={result}
          handleClick={handleClick}
        />
      ))}
    </div>
  );
}

LocalePickerSearchResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string,
      countryName: PropTypes.string,
      currency: PropTypes.string,
      locale: PropTypes.string,
    })
  ).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default withALErrorBoundary({
  name: "LocalePickerSearchResults",
  priority: "P3",
})(LocalePickerSearchResults);
