import { useContext } from "react";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { LoopReturnContext } from "../../context/LoopReturnProvider";
import { ALButton } from "../ALComponents";
import "./announcementBarLoopReturn/AnnouncementBarLoopReturn.scss";

export default function AnnouncementBarLoopReturn() {
  const { formattedRemainingAbsoluteTotal, redirectURL, removeLoopReturnCookie } =
    useContext(LoopReturnContext);
  const dict = useLocalizedSentenceDict();

  return (
    <div className="ab_loop-return" data-testid="announcement-bar-loop-return">
      <div className="ab_loop-return__info">
        <div className="ab_loop-return__info__description subtext">
          {dict.get("Available Credit")}
        </div>
        <div className="ab_loop-return__info__credit h4 h4--bold h4--uc">
          {formattedRemainingAbsoluteTotal}
        </div>
      </div>
      <div className="ab_loop-return__actions">
        <a href={`https://${redirectURL}`}>
          <ALButton variant="secondary" onClick={removeLoopReturnCookie}>
            {dict.get("GO BACK")}
          </ALButton>
        </a>
      </div>
    </div>
  );
}
