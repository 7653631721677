import { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Popup } from "../Popup";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import IconClose from "../icons/svgs/close.svg";
import IconAdd from "../icons/svgs/add.svg";
import IconMinus from "../icons/svgs/minus.svg";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import "./ringSizeGuidePopupUp/styles.scss";

function RingSizeGuidePopUp() {
  const [isHowToOpen, setIsHowToOpen] = useState(false);
  const [openSizeGuide, setOpenSizeGuide] = useState(false);
  const closeSizeGuideModal = () => setOpenSizeGuide(false);

  const sizeChartImage = useResponsiveWithHydrationFix(
    <StaticImage
      className="size_guide_container_chart"
      src="../../images/size_chart/size-conversion-mobile.png"
      alt="Ring Size Chart Ana Luisa Jewelry"
      loading="eager"
    />,
    <StaticImage
      className="size_guide_container_chart"
      src="../../images/size_chart/size-conversion-desktop.png"
      alt="Ring Size Chart Ana Luisa Jewelry"
      loading="eager"
    />
  );

  const howToContainer = useResponsiveWithHydrationFix(
    <div className="size_guide_container_howto">
      <div
        className="size_guide_container_howto_title"
        onClick={() => setIsHowToOpen(!isHowToOpen)}
      >
        <p className="al_subtext al_p--bold">How to find your circumference</p>
        {isHowToOpen ? <IconMinus width="30" stroke="#2d2927" /> : <IconAdd fill="#2d2927" />}
      </div>
      {isHowToOpen ? (
        <ul className="size_guide_container_howto_content">
          <li className="al_subtext">
            Cut a thin strip of paper or string that measures the approximate length of your hand.
          </li>
          <li className="al_subtext">
            Wrap the paper or string around the finger you’ll be wearing the ring on. It should be
            wrapped snugly, but not too tight.
          </li>
          <li className="al_subtext">
            Mark the point where the paper or string overlaps around the circumference of your
            finger.
          </li>
          <li className="al_subtext">
            Using the millimeter or inch side of a ruler, lay the paper or string flat and measure
            the length from one end to the marking. That measurement will be your finger’s
            circumference.
          </li>
        </ul>
      ) : null}
    </div>,
    <div className="size_guide_container_howto">
      <div className="size_guide_container_howto_title">
        <p className="al_subtext al_p--bold">How to find your circumference</p>
      </div>

      <ul className="size_guide_container_howto_content">
        <li className="al_subtext">
          Cut a thin strip of paper or string that measures the approximate length of your hand.
        </li>
        <li className="al_subtext">
          Wrap the paper or string around the finger you’ll be wearing the ring on. It should be
          wrapped snugly, but not too tight.
        </li>
        <li className="al_subtext">
          Mark the point where the paper or string overlaps around the circumference of your finger.
        </li>
        <li className="al_subtext">
          Using the millimeter or inch side of a ruler, lay the paper or string flat and measure the
          length from one end to the marking. That measurement will be your finger’s circumference.
        </li>
      </ul>
    </div>
  );

  return (
    <>
      <p className="pdp_details_variant_size_guide" onClick={() => setOpenSizeGuide((o) => !o)}>
        Size guide
      </p>
      <Popup
        className="size_guide_popup"
        open={openSizeGuide}
        closeOnDocumentClick
        onClose={closeSizeGuideModal}
      >
        <div className="size_guide_container">
          <div className="size_guide_container_close" onClick={closeSizeGuideModal}>
            <IconClose width="30" fill="#2D2927" />
          </div>
          <p className="size_guide_container_title al_h3">Size Guide</p>
          <p className="size_guide_container_subtitle al_subtext">
            Find your circumference measurement in the guide below to confirm your ring size. If
            you're in between sizes, we suggest sizing up.
          </p>

          {howToContainer}

          {sizeChartImage}
        </div>
      </Popup>
    </>
  );
}

export default withALErrorBoundary({
  name: "RingSizeGuidePopUp",
  priority: "P3",
})(RingSizeGuidePopUp);
