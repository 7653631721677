import { isBrowser } from "../helpers";

function gtmSendData(data) {
  if (isBrowser) {
    if (window.dataLayer) {
      window.dataLayer.push(data);
    } else {
      console.error("window.dataLayer not found");
    }
  }
}
export function gtmSendEvent(event, data) {
  gtmSendData({ ...data, event });
}

export function gtmSendAddToWishlist(
  { type, productName, price, productId, currency },
  appendGlobalData
) {
  gtmSendEvent(
    "add_to_wishlist",
    appendGlobalData({
      product_id: productId,
    })
  );

  // Clear the previous ecommerce object
  gtmSendData({ ecommerce: null });

  // GA4 - add_to_wishlist
  gtmSendData({
    event: "add_to_wishlist",
    currency,
    ecommerce: {
      items: [
        {
          item_name: productName,
          item_id: productId,
          price,
          item_brand: "Ana Luisa",
          item_category: type,
          quantity: 1,
        },
      ],
    },
  });
}

export function gtmSendAddToCart({ price, title, productId, category, currency, quantity }) {
  // Clear the previous ecommerce object
  gtmSendData({ ecommerce: null });

  // Send enhanced commerce tracking data
  gtmSendEvent("addToCart", {
    ecommerce: {
      currencyCode: currency,
      add: {
        products: [
          {
            name: title,
            id: productId,
            price,
            brand: "Ana Luisa",
            category,
            quantity,
          },
        ],
      },
    },
  });

  // Clear the previous ecommerce object
  gtmSendData({ ecommerce: null });
}

export function gtmSendRemoveFromCart({ price, title, productId, category, currency, quantity }) {
  // Clear the previous ecommerce object
  gtmSendData({ ecommerce: null });

  // GA4 - remove_from_cart
  gtmSendData({
    event: "remove_from_cart",
    currency,
    ecommerce: {
      items: [
        {
          item_name: title,
          item_id: productId,
          price,
          item_brand: "Ana Luisa",
          item_category: category,
          quantity,
        },
      ],
    },
  });
}

export function gtmSendProductClick({ price, title, productId, category }) {
  // Clear the previous ecommerce object
  gtmSendData({ ecommerce: null });

  // Send enhanced commerce tracking data
  gtmSendEvent("productClick", {
    ecommerce: {
      click: {
        products: [
          {
            name: title,
            id: productId,
            price,
            brand: "Ana Luisa",
            category,
          },
        ],
      },
    },
  });
}

export function gtmSendViewCollectionPage(
  { currency, collectionId, url, products },
  appendGlobalData
) {
  gtmSendEvent(
    "viewCollectionPage",
    appendGlobalData({
      url,
      product_category: collectionId,
      ecommerce: {
        currencyCode: currency,
        impressions: products.map((p, i) => ({
          name: p.title,
          id: p.shopifyId.replace("gid://shopify/Product/", ""),
          price: p.variants[0].price,
          brand: "Ana Luisa",
          category: p.productType,
          position: i + 1,
        })),
      },
      products_ids: products.map((p) => p.shopifyId.replace("gid://shopify/Product/", "")),
    })
  );
}

export function gtmSendViewProductPage({ price, title, productType, productId }, appendGlobalData) {
  gtmSendEvent(
    "viewProductPage",
    appendGlobalData({
      product_price: price,
      product_name: title,
      product_category: productType,
      product_id: productId,
    })
  );

  // Clear the previous ecommerce object
  gtmSendData({ ecommerce: null });

  // Send enhanced commerce tracking data
  gtmSendData({
    ecommerce: {
      detail: {
        products: [
          {
            name: title,
            id: productId.replace("gid://shopify/Product/", ""),
            price,
            brand: "Ana Luisa",
            category: productType,
          },
        ],
      },
    },
  });

  // Clear the previous ecommerce object
  gtmSendData({ ecommerce: null });

  // GA4 - view_item
  gtmSendData({
    event: "view_item",
    // currency, - MISSING TO ADD
    ecommerce: {
      items: [
        {
          item_name: title,
          item_id: productId,
          price,
          item_brand: "Ana Luisa",
          item_category: productType,
          quantity: 1,
        },
      ],
    },
  });
}

export function gtmSendBundleUpdate(
  { handle, price, currency, productId, step },
  appendGlobalData
) {
  gtmSendEvent(
    "addProductToBundle",
    appendGlobalData({
      product_price: price,
      product_handle: handle,
      product_id: productId,
      currency,
      step,
    })
  );
}

export function gtmSendAddBundleToCart(
  { fullPrice, finalPrice, currency, products },
  appendGlobalData
) {
  gtmSendEvent(
    "addBundleToCart",
    appendGlobalData({
      full_price: fullPrice,
      final_price: finalPrice,
      currency,
      products,
    })
  );
}

export function gtmSendViewCart({ cart, currency }) {
  const items = cart?.lines.reduce((red, item) => {
    red.push({
      item_id: item?.variant?.product?.id.replace("gid://shopify/Product/", ""),
      item_name: item?.title,
      price: item?.final_price,
      quantity: item?.quantity,
      item_brand: "Ana Luisa",
    });
    return red;
  }, []);

  // Clear the previous ecommerce object
  gtmSendData({ ecommerce: null });

  gtmSendData({
    event: "view_cart",
    currency,
    ecommerce: {
      items,
    },
  });
}

export function gtmSendSearchEvent({ searchTerm }) {
  // Clear the previous ecommerce object
  gtmSendData({ ecommerce: null });

  gtmSendData({
    event: "search",
    ecommerce: {
      search_term: searchTerm,
    },
  });
}

export function gtmSendSignUp() {
  gtmSendData({ event: "sign_up" });
}
