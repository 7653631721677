import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { LuxeContext } from "../../context/LuxeProvider";
import { useAuth } from "../../context/AuthProvider";
import luxePerks from "./luxeSelection/perks.json";

import { useCurrentFormatCurrency } from "../../hooks/usePrices";

import SubscriptionPopUp from "../SubscriptionPopUp";

import LuxeCheck from "../icons/svgs/luxe_check.svg";
import LuxeUncheck from "../icons/svgs/luxe_uncheck.svg";
import LuxeLogo from "../icons/svgs/luxe_logo.svg";
import LuxeSelected from "../icons/svgs/luxe_selected.svg";
import LuxeUnselected from "../icons/svgs/luxe_unselected.svg";
import IconAccount from "../icons/svgs/account.svg";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import "./luxeSelection/styles.scss";

function LuxeSelection({ sideCartInfo, discountInfo }) {
  const { luxe, setLuxeActive } = useContext(LuxeContext);
  const [popupVisible, setPopupVisible] = useState(false);

  const { showLogin, accountDetails } = useAuth();
  const formatCurrency = useCurrentFormatCurrency();
  const isLuxe = luxe.isLuxeActive || luxe.hasLuxeInCart || luxe.shouldAddLuxeInNextATC;
  // TODO: Update for other discount type (% off, ...)
  const maxTieredDiscount = discountInfo.discounts[discountInfo.discounts.length - 1][1];
  if (!luxe.isLuxeEnabled) return null;

  return luxe.customerSubscription &&
    (luxe.customerSubscription.status === "ACTIVE" ||
      luxe.customerSubscription.status === "PAUSE") ? (
    <div className="cart_luxe">
      <p className="cart_luxe_detail">AL Luxe Member - Extra 5% off</p>
      <p className="cart_luxe_balance">
        Balance: <b>${luxe.subscriptionCredit} in credits</b> (apply at checkout)
      </p>
    </div>
  ) : (
    <div className="perks">
      <div className="perks_table">
        <div className="perks_table_tr perks_table_tr--mostpopular">
          <div className="perks_table_th">&nbsp;</div>
          <div className="perks_table_luxe">
            <div className="perks_table_luxe--mostpopular">Most popular</div>
          </div>
          <div className="perks_table_nonmember">&nbsp;</div>
        </div>
        {luxePerks.perks.map((perk, i) => (
          <div
            className={`perks_table_tr perks_table_tr--${perk.type}`}
            key={`perk_${perk.type}_${i}`}
          >
            <div className="perks_table_th">
              {perk.segments?.map((segment, index) => (
                <span key={index} className={segment.bold ? "bold" : ""}>
                  {segment.text}
                </span>
              ))}
            </div>
            <div
              className={`perks_table_luxe${isLuxe ? " perks_table_luxe--active" : ""} microtext`}
              onClick={() => setLuxeActive(true, luxe.subscriptionPlan, sideCartInfo)}
            >
              {typeof perk.al_luxe_member === "boolean" ? (
                <LuxeCheck
                  stroke={`${isLuxe ? "#FBF7EC" : "#2D2927"}`}
                  width="11px"
                  height="11px"
                />
              ) : perk.al_luxe_member === "AL LUXE" ? (
                <>
                  {isLuxe ? (
                    <LuxeSelected className="perks_table_luxe_select" width="19px" height="19px" />
                  ) : (
                    <LuxeUnselected
                      className="perks_table_luxe_select"
                      width="19px"
                      height="19px"
                    />
                  )}
                  <LuxeLogo
                    className="perks_table_luxe_logo"
                    fill={`${isLuxe ? "#FBF7EC" : "#2D2927"}`}
                    width="13.5px"
                    height="13px"
                  />
                  {perk.al_luxe_member}
                </>
              ) : perk.type === "footer" ? (
                <div className="perks_table_luxe_price">
                  {formatCurrency(sideCartInfo.finalAmountLux)}
                </div>
              ) : (
                perk.al_luxe_member.replace("{0}", maxTieredDiscount + 5)
              )}
            </div>
            <div
              className={`perks_table_nonmember${
                !isLuxe ? " perks_table_nonmember--active" : ""
              } microtext`}
              onClick={() => setLuxeActive(false, luxe.subscriptionPlan, sideCartInfo)}
            >
              {typeof perk.non_member === "boolean" ? (
                <LuxeUncheck
                  stroke={`${!isLuxe ? "#E94B2F" : "#2D2927"}`}
                  width="11px"
                  height="11px"
                />
              ) : perk.non_member === "GUEST" ? (
                <>
                  {!isLuxe ? (
                    <LuxeSelected className="perks_table_luxe_select" width="19px" height="19px" />
                  ) : (
                    <LuxeUnselected
                      className="perks_table_luxe_select"
                      width="19px"
                      height="19px"
                    />
                  )}

                  {perk.non_member}
                </>
              ) : perk.type === "footer" ? (
                <div className="perks_table_nonmember_price">
                  {formatCurrency(sideCartInfo.finalAmount)}
                </div>
              ) : (
                perk.non_member.replace("{0}", maxTieredDiscount)
              )}
            </div>
          </div>
        ))}
      </div>

      {isLuxe && (
        <p className="perks_details_luxe">
          By purchasing, you choose to become an AL Luxe Membership and agree to the{" "}
          <a
            href="/pages/luxe-program-terms/"
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            Luxe Program terms
          </a>
          , including a <b>$39.99</b> monthly payment converted to store credit. Use your credit as
          you wish, anytime. You will pay a <b>$0.95</b> fee for the first trial month.
        </p>
      )}

      <div className="perks_learn_more">
        <p className="perks_learn_more_cta" onClick={() => setPopupVisible(true)}>
          Learn more about AL Luxe membership
        </p>
        <SubscriptionPopUp isOpen={popupVisible} onClose={() => setPopupVisible(false)} />
        {!accountDetails && (
          <div className="perks_learn_more_signin" onClick={showLogin}>
            <IconAccount
              className="perks_learn_more_signin--account"
              height="13.5"
              stroke="#2D2927"
            />{" "}
            Sign In
          </div>
        )}
      </div>
    </div>
  );
}

LuxeSelection.propTypes = {
  sideCartInfo: PropTypes.object.isRequired,
  discountInfo: PropTypes.object.isRequired,
};

export default withALErrorBoundary({
  name: "SideCart",
  priority: "P1",
})(LuxeSelection);
