import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { getCookie, isBrowser } from "./helpers";

const ExponeaContext = createContext({
  isExponeaLoaded: false,
  dataExponea: null,
});

export function useExponea() {
  return useContext(ExponeaContext);
}

const EXPONEA_COOKIE_NAME = "__exponea_etc__"; // Added by Exponea

/*
 * The goal of this hook is to know when Exponea is "fully loaded"
 * Being "fully loaded" means that exponea is either
 * - Not initialized (because blocked by ADBlock or else)
 * - Initialized but without customer data
 * - Initialized with customer data *entirely loaded*
 *
 * The complexity of the current code comes from the impossibility of
 * determining when exponea is still loading customer data from when it has no
 * data on the customer (new customer).
 * We also want to be able to get the result as fast as possible.
 * Especially in the scenarios where the data do not exist/cannot be retrieved.
 *
 * Key points in the scenarios are:
 * ┌──────────────────┐
 * │  Exponea script  ├────┐
 * │     is loaded    │    │
 * └──────────────────┘    │
 *         ▼  yes          │
 * ┌──────────────────┐    │
 * │     Previous     ├────┤
 * │  cookie is found │    │
 * └──────────────────┘    │ no
 *         ▼  yes          │
 * ┌──────────────────┐    │
 * │   Has finished   ├────┤
 * │loading user data │    │
 * └──────────────────┘    │
 *         ▼  yes          │
 * ┌──────────────────┐    │
 * │ LOADING COMPLETE │◄───┘
 * └──────────────────┘
 *
 */
function useExponeaIsLoaded() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasExponea, setHasExponea] = useState(isBrowser && typeof window.exponea !== "undefined");
  const [hasFoundUser, setHasFoundUser] = useState(false);
  const [cookieLoadingTimeout, setCookieLoadingTimeout] = useState(false);

  const checkExponeaDataLoaded = useCallback((attempt = 0) => {
    const key = "identified";
    const timeoutInMS = 5000;

    if (window.exponea?.[key] !== undefined) {
      setIsLoaded(true);
      return;
    }
    const totalWaitedTimeInMS = 50 * attempt;
    if (totalWaitedTimeInMS < timeoutInMS) {
      setTimeout(() => checkExponeaDataLoaded(attempt + 1), 200);
    }
  }, []);

  // Case for when cookie is found but not loaded (extreme side case)
  useEffect(() => {
    if (cookieLoadingTimeout && !hasFoundUser) {
      setIsLoaded(true);
    }
  }, [cookieLoadingTimeout, hasFoundUser]);

  useEffect(() => {
    if (isBrowser && hasExponea) {
      window.exponea.start({
        cookies: {
          retrieve_callback: () => {
            // Once this callback is called, Exponea is in the process to retrieve all data of the customer
            // `checkExponeaDataLoaded()` will run until the data is found or until we reach a timeout of 5s
            setHasFoundUser(true);
            checkExponeaDataLoaded();
          },
        },
      });
      // If no cookie found the above 'retrieve_callback' will not be called so we can directly return
      if (!getCookie(EXPONEA_COOKIE_NAME)) {
        setCookieLoadingTimeout(true);
        return;
      }
      // If a cookie is found 'retrieve_callback' is supposed to be fired but we still make a timeout in case it is not fired
      const timeout = setTimeout(() => {
        setCookieLoadingTimeout(true);
      }, 500);
      // eslint-disable-next-line consistent-return
      return () => clearTimeout(timeout);
    }
    // if Exponea script is not loaded we consider that it finished loading (ex: blocked by ad-block)
    setIsLoaded(true);
  }, [hasExponea, checkExponeaDataLoaded]);

  // Check at every render of the provider if Exponea *script* loaded in case it wasn't
  if (!hasExponea && isBrowser && typeof window.exponea !== "undefined") {
    setHasExponea(true);
  }

  return isLoaded;
}

export default function ExponeaProvider({ children }) {
  const [state, setState] = useState({ isExponeaLoaded: false, dataExponea: {} });
  const isExponeaLoaded = useExponeaIsLoaded();

  useEffect(() => {
    if (isExponeaLoaded) {
      if (isBrowser) {
        setState({
          isExponeaLoaded: true,
          dataExponea: {
            segmentP: window.exponea.segmentP || 0,
            consent_email: window.exponea.consent_email === "true",
            consent_sms: window.exponea.consent_sms === "true",
            identified: window.exponea.identified === "true",
            lastViewed: window.exponea.lastViewed?.length ? window.exponea.lastViewed : [],
            phone: window.exponea.phone !== "None" ? window.exponea.phone : null,
            wishlistItems: window.exponea.wishlistItems?.length ? window.exponea.wishlistItems : [],
          },
        });
      } else {
        setState({
          isExponeaLoaded: true,
        });
      }
    }
  }, [isExponeaLoaded]);

  return <ExponeaContext.Provider value={state}>{children}</ExponeaContext.Provider>;
}
