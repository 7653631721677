import { useEffect } from "react";
import ReactPopup from "reactjs-popup";
import { isBrowser } from "../context/helpers";

export function Popup(props) {
  useEffect(() => {
    if (isBrowser && props.open) {
      const { overflow } = document.documentElement.style;
      document.documentElement.style.overflow = "hidden";
      return () => (document.documentElement.style.overflow = overflow);
    }
  }, [props.open]);
  return <ReactPopup {...props} />;
}
