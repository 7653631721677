// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--5cc9c";
export var bestReviews = "styles-module--best-reviews--1eefe";
export var bestReviews__card = "styles-module--best-reviews__card--e293a";
export var bestReviews__card__body = "styles-module--best-reviews__card__body--973e7";
export var bestReviews__card__customer = "styles-module--best-reviews__card__customer--edcd3";
export var bestReviews__card__customer__badge = "styles-module--best-reviews__card__customer__badge--66fb5";
export var bestReviews__card__customer__name = "styles-module--best-reviews__card__customer__name--27d41";
export var bestReviews__card__stars = "styles-module--best-reviews__card__stars--6db61";
export var bestReviews__container = "styles-module--best-reviews__container--efa4c";
export var bestReviews__title = "styles-module--best-reviews__title--42c75";
export var bold = "styles-module--bold--07d8c";
export var center = "styles-module--center--9c12f";
export var h1 = "styles-module--h1--13665";
export var h1Bold = "styles-module--h1--bold--31489";
export var h1Uc = "styles-module--h1--uc--55df0";
export var h2 = "styles-module--h2--3e69a";
export var h2Bold = "styles-module--h2--bold--6640f";
export var h2Uc = "styles-module--h2--uc--af149";
export var h3 = "styles-module--h3--fe3aa";
export var h3Bold = "styles-module--h3--bold--3d5c9";
export var h3Uc = "styles-module--h3--uc--32119";
export var h4 = "styles-module--h4--c0507";
export var h4Bold = "styles-module--h4--bold--89b38";
export var h4Uc = "styles-module--h4--uc--d3fef";
export var linkUnderline = "styles-module--link--underline--b1a37";
export var microtext = "styles-module--microtext--d8346";
export var microtextBold = "styles-module--microtext--bold--182a5";
export var microtextUc = "styles-module--microtext--uc--afbb9";
export var scrollToReviews = "styles-module--scroll-to-reviews--76949";
export var strikethrough = "styles-module--strikethrough--5aea7";
export var strikethroughLg = "styles-module--strikethrough--lg--8f365";
export var strikethroughSm = "styles-module--strikethrough--sm--6f673";
export var subtext = "styles-module--subtext--3c467";
export var subtextBold = "styles-module--subtext--bold--49a37";
export var subtextUc = "styles-module--subtext--uc--12311";
export var text = "styles-module--text--7bd88";
export var textBold = "styles-module--text--bold--b6df1";
export var textUc = "styles-module--text--uc--3e18e";
export var titleL = "styles-module--title-l--259b7";
export var titleM = "styles-module--title-m--68ae9";
export var titleS = "styles-module--title-s--218f8";
export var titleXl = "styles-module--title-xl--bf302";
export var titleXs = "styles-module--title-xs--c55a7";
export var titleXxs = "styles-module--title-xxs--69e5d";
export var uc = "styles-module--uc--7ae54";
export var underline = "styles-module--underline--8ebf1";