/* eslint no-bitwise: ["error", { "allow": ["~"] }] */
import { useState, useEffect, useContext, createContext, useCallback } from "react";
import { produce } from "immer";
import { useLocation } from "@reach/router";
import StoreContext from "./store";
import { GeoContext } from "./GeoProvider";
import { CartContext } from "./CartProvider";
import { useAddToCartWithLuxe } from "./LuxeProvider";

import {
  createHash,
  getAllUrlParams,
  addURLParam,
  removeURLParam,
  simpleCipher,
  roundNumber,
  isBrowser,
  getCookie,
} from "./helpers";
import { useFillProductsPrices } from "../hooks/usePrices";
import { useTracking } from "./Tracking";
import {
  BYOB_DEFAULT_SIZE,
  BYOB_SIZE_KEY,
  BYOB_ATTR_KEY,
  BYOB_DEFAULT_PERCENTAGE_OFF,
  BYOB_URL_PARAM,
  BYOB_PERCENTAGE_OFF_KEY,
  BYOB_SOURCE_PAGE,
  BYOB_CAN_HAVE_OPTIONAL_ITEM,
} from "../constants/BYOBConfig";

// Interface
const defaultBYOBContext = {
  productList: [],
  fullPrice: 0,
  finalPrice: 0,
  percentageOff: BYOB_DEFAULT_PERCENTAGE_OFF,
  addProductToCustomBundle: () => {},
  removeProductToCustomBundle: () => {},
  addCustomBundleToCart: () => {},
};

export const BYOBContext = createContext(defaultBYOBContext);

export function BYOBProvider({ children }) {
  const { gePriceDetails } = useContext(GeoContext);
  const { setBYOBOptionalItemPopup } = useContext(StoreContext);
  const addToCartWithLuxe = useAddToCartWithLuxe();
  const { cart, removeSingleProductsFromCart } = useContext(CartContext);

  const [isOptionalItemABVariantActive, setIsOptionalItemABVariantActive] = useState(true);
  if (isBrowser) {
    window.setABRunning = setIsOptionalItemABVariantActive;
  }

  const [byobSize, setByobSize] = useState(BYOB_DEFAULT_SIZE);

  // BYOB percentage off (can change based on collection settings)
  const [byobPercentageOff, setBYOBPercentageOff] = useState(BYOB_DEFAULT_PERCENTAGE_OFF);
  // Final percentage of cumulative discounts
  const [finalPercentageOff, setFinalPercentageOff] = useState(BYOB_DEFAULT_PERCENTAGE_OFF);

  const [collectionProducts, setCollectionProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [fullPriceInCents, setFullPriceInCents] = useState(0);
  const [preFinalPriceInCents, setPreFinalPriceInCents] = useState(0);
  const [processingAddBundleToCart, setProcessingAddBundleToCart] = useState(false);
  const [editBundleHash, setEditBundleHash] = useState(null);
  const [optionalItemEnabled, setOptionalItemEnabled] = useState(false);
  const [optionalItemPercentageOff, setOptionalItemPercentageOff] = useState(
    BYOB_DEFAULT_PERCENTAGE_OFF
  );
  const [canHaveOptionalItem, setCanHaveOptionalItem] = useState(false);

  const location = useLocation();
  const {
    trackBundleUpdate,
    trackAddBundleToCart,
    trackAddPreBundledProductToCart,
    trackBundleItems,
  } = useTracking();
  const productListWithPrice = useFillProductsPrices({
    products: productList,
    shouldUseDiscount: false,
  });
  const currencyCode = gePriceDetails?.CurrencyCode;

  function loadEditMode() {
    // Ensure BYOB is in cart if edit
    const allURLParams = getAllUrlParams();
    const editingBundleHash = allURLParams.edit;

    setEditBundleHash(
      !!cart.lines.find((lineItem) =>
        lineItem?.attributes?.find(
          ({ key, value }) => key === BYOB_ATTR_KEY && value === editingBundleHash
        )
      ) && editingBundleHash
    );
  }

  function loadProductsFromURL(products) {
    const allURLParams = getAllUrlParams();
    const productsInURL = allURLParams[BYOB_URL_PARAM] || [];
    const variantIdsToAdd = Array.isArray(productsInURL) ? productsInURL : [productsInURL];
    setProductList(
      produce((draftProductList) => {
        draftProductList.splice(0, Infinity);
        variantIdsToAdd.forEach((variantId) => {
          let productVariantIndex = null;
          const productToAdd = products.find(({ node }) =>
            node.variants.find((variant, idx) => {
              const foundVariantId = variant.id.replace("gid://shopify/ProductVariant/", "");
              if (foundVariantId === variantId) {
                productVariantIndex = idx;
                return true;
              }
              return false;
            })
          );
          if (productToAdd) {
            draftProductList.push({
              ...productToAdd.node,
              variantIndex: productVariantIndex,
            });
          }
        });

        // Ensure list does not go above threshold in case of event accumulation
        draftProductList.splice(byobSize, Infinity);
      })
    );
  }

  // Takes Shopify product data
  function addProductToCustomBundle(productToAdd, productVariantIndex = 0) {
    trackBundleUpdate({
      action: "add_product_bundle",
      handle: productToAdd.handle,
      price: Number(productToAdd.variants[productVariantIndex].price),
      currency: currencyCode,
      productId: productToAdd.id.replace("gid://shopify/Product/", ""),
      step: productList.length + 1,
    });
    addURLParam(
      BYOB_URL_PARAM,
      productToAdd.variants[productVariantIndex].id.replace("gid://shopify/ProductVariant/", "")
    );
    setProductList(
      produce((draftProductList) => {
        draftProductList.push({
          ...productToAdd,
          variantIndex: productVariantIndex,
        });
        // Ensure list does not go above threshold in case of event accumulation
        draftProductList.splice(byobSize, Infinity);
      })
    );
  }

  function removeProductToCustomBundle(variantIdToRemove) {
    const productToRemove = productList.find(
      (productInList) => productInList.variants[productInList.variantIndex].id === variantIdToRemove
    );
    trackBundleUpdate({
      action: "remove_product_bundle",
      handle: productToRemove.handle,
      price: Number(productToRemove.variants[productToRemove.variantIndex].price),
      currency: currencyCode,
      productId: productToRemove.id.replace("gid://shopify/Product/", ""),
    });
    removeURLParam(BYOB_URL_PARAM, variantIdToRemove.replace("gid://shopify/ProductVariant/", ""));
    setProductList(
      produce((draftProductList) => {
        const productIdxToRemove = draftProductList.findIndex(
          (productInList) =>
            productInList.variants[productInList.variantIndex].id === variantIdToRemove
        );
        if (~productIdxToRemove) {
          draftProductList.splice(productIdxToRemove, 1);
        }
      })
    );
  }

  const calculatePrices = useCallback(() => {
    // Value (in $) (excluding shopify discount - compare at price)
    let _fullPriceInCents = 0;
    // Value (in $) (including shopify discount - compare at price)
    let _preFinalPriceInCents = 0;

    productListWithPrice.forEach((product) => {
      const variant = product.variants[product.variantIndex];

      const _compareAtPrice = parseFloat(variant.compareAtPrice);
      const _price = parseFloat(variant.price);

      // Division and multiplication by 100 to keep operation in cents
      _preFinalPriceInCents += _price * 100;
      if (!Number.isNaN(_compareAtPrice)) {
        _fullPriceInCents += _compareAtPrice * 100;
      } else {
        _fullPriceInCents += _price * 100;
      }
    });

    return {
      fullPrice: _fullPriceInCents,
      preFinalPrice: _preFinalPriceInCents,
    };
  }, [byobPercentageOff, productListWithPrice]);

  useEffect(() => {
    const { fullPrice, preFinalPrice } = calculatePrices();

    // Calculate official discount (including discounts from compareAtPrice)
    const allDiscountsFinalPrice = (1 - byobPercentageOff / 100) * preFinalPrice;
    setFinalPercentageOff(roundNumber(100 - (allDiscountsFinalPrice * 100) / fullPrice));

    setFullPriceInCents(fullPrice);
    setPreFinalPriceInCents(preFinalPrice);
  }, [productListWithPrice, byobPercentageOff, calculatePrices]);

  useEffect(() => {
    loadEditMode();
    loadProductsFromURL(collectionProducts);
  }, [cart.lines.length, location, collectionProducts, optionalItemEnabled]);

  // If there are more products in the list than the size permits, slice product list up to byob size
  useEffect(() => {
    if (productList.length > byobSize) {
      setProductList(productList.slice(0, byobSize));
    }
  }, [byobSize, productList]);

  function removeBundleURLParams() {
    removeURLParam(BYOB_URL_PARAM, null, true);
    removeURLParam("edit", null, true);
    removeURLParam("optionalItem", null, true);
  }

  function createBundleHash() {
    const productHandles = productList.map((p) => p.handle);
    productHandles.sort((a, b) => a.localeCompare(b));
    const keyString = productHandles.join("_");
    return `${createHash(keyString)}_${Date.now()}`; // hashing "handleA-handleB-handleC"
  }

  function addCustomBundleToCart() {
    if (!processingAddBundleToCart) {
      processAddBundleToCart();
    }
  }

  async function processAddBundleToCart() {
    if (productListWithPrice.length !== byobSize) return;

    const { fullPrice, preFinalPrice } = calculatePrices();
    const trackingParams = {
      fullPrice: fullPrice / 100,
      finalPrice: (preFinalPrice - (preFinalPrice * byobPercentageOff) / 100) / 100,
      currency: currencyCode,
    };

    setProcessingAddBundleToCart(true);
    const bundleHash = editBundleHash || createBundleHash(); // Used to group product of a bundle

    if (editBundleHash) {
      // On edit we remove the bundle and add the updated one
      const lineItem = cart.lines.find((lineItem) =>
        lineItem?.attributes.find(({ key, value }) => key === BYOB_ATTR_KEY && value === bundleHash)
      );
      await removeSingleProductsFromCart({ lineToRemove: lineItem });
      trackBundleItems({
        numberItems: byobSize,
        rating: byobSize === 3 ? "downgrade" : "upgrade",
        products: productListWithPrice,
        ...trackingParams,
      });
    } else {
      trackAddBundleToCart(
        productListWithPrice.reduce(
          (acc, product) => ({
            ...acc,
            products: [
              ...acc.products,
              {
                handle: product.handle,
                productId: product.id.replace("gid://shopify/Product/", ""),
                price: Number(product.variants[product.variantIndex].price),
              },
            ],
          }),
          {
            products: [],
            ...trackingParams,
          }
        )
      );
      trackBundleItems({
        numberItems: byobSize,
        rating: byobSize === 3 ? "downgrade" : "upgrade",
        products: productListWithPrice,
        ...trackingParams,
      });
    }

    await addToCartWithLuxe({
      dataProductsToAdd: productListWithPrice.map((productInList) => {
        const {
          variants,
          title,
          handle,
          productType,
          images,
          id: productId,
          variantIndex,
        } = productInList;
        const {
          price,
          compareAtPrice,
          sku,
          id: variantId,
          image,
          title: variantTitle,
        } = variants[variantIndex];
        const variantImage = image || images[0];

        let attributes = [
          { key: BYOB_PERCENTAGE_OFF_KEY, value: simpleCipher(byobPercentageOff) },
          { key: BYOB_SIZE_KEY, value: simpleCipher(byobSize) },
          { key: BYOB_ATTR_KEY, value: bundleHash },
          { key: BYOB_SOURCE_PAGE, value: location.pathname },
          { key: BYOB_CAN_HAVE_OPTIONAL_ITEM, value: canHaveOptionalItem },
        ];

        const impactClickId = getCookie("_irclickid");
        if (impactClickId) {
          attributes = [...attributes, { key: "irclickid", value: impactClickId }];
        }

        return {
          quantity: 1,
          attributes,
          variant: {
            id: variantId,
            title: variantTitle,
            price,
            compareAtPrice,
            sku,
            image: {
              altText: variantImage.altText,
              url: variantImage.url || variantImage.url,
            },
            product: {
              title,
              id: productId,
              handle,
              category: productType,
            },
          },
        };
      }),
    });
    setProductList([]);
    removeBundleURLParams();
    setProcessingAddBundleToCart(false);
    setOptionalItemEnabled(false);
  }

  function addPreBundledProductToCart(productsData, preBundledProduct, fullPrice, finalPrice) {
    // Remove products from bundle, if any
    if (productList.length > 0) {
      productList.map((product) =>
        removeProductToCustomBundle(product.variants[product.variantIndex].id)
      );
    }

    trackAddPreBundledProductToCart({
      bundleName: preBundledProduct.bundleName,
      cpPosition: preBundledProduct.cpPosition,
      fullPrice,
      finalPrice,
      products: preBundledProduct.products,
    });

    productsData?.forEach((prod, index) => {
      const { variant } = preBundledProduct.products.find((p) => p.handle === prod.handle);
      let variantIndex = 0;
      variantIndex = prod.shopify.variants.findIndex((v) => v.title === variant);
      variantIndex = variantIndex !== -1 ? variantIndex : 0;
      setTimeout(() => {
        addProductToCustomBundle(prod.shopify, variantIndex);
      }, 300 * (index + 1));
    });
  }

  // Automatically add bundle to cart when bundle completed, except if coming from edit mode
  useEffect(() => {
    if (!editBundleHash && productList.length === byobSize && !processingAddBundleToCart) {
      processAddBundleToCart().then(() => {
        if (
          canHaveOptionalItem &&
          byobSize === BYOB_DEFAULT_SIZE &&
          isOptionalItemABVariantActive
        ) {
          setBYOBOptionalItemPopup(true);
        }
      });
    }
  }, [editBundleHash, productList, processingAddBundleToCart]);

  return (
    <BYOBContext.Provider
      value={{
        productList: productListWithPrice,
        fullPrice: fullPriceInCents / 100,
        finalPrice: (preFinalPriceInCents - (preFinalPriceInCents * byobPercentageOff) / 100) / 100,
        finalPercentageOff,
        byobPercentageOff,
        setBYOBPercentageOff,
        addProductToCustomBundle,
        removeProductToCustomBundle,
        addCustomBundleToCart,
        addPreBundledProductToCart,
        loadBYOBCollection: setCollectionProducts,
        editMode: !!editBundleHash,
        byobSize,
        setByobSize,
        optionalItemEnabled,
        setOptionalItemEnabled,
        optionalItemPercentageOff,
        setOptionalItemPercentageOff,
        setCanHaveOptionalItem,
      }}
    >
      {children}
    </BYOBContext.Provider>
  );
}
