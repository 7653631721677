// extracted by mini-css-extract-plugin
export var actions = "styles-module--actions--c8a5b";
export var actionsQuantity = "styles-module--actionsQuantity--a9b9b";
export var actionsQuantityArrow = "styles-module--actionsQuantityArrow--08b30";
export var awesome = "styles-module--awesome--b249c";
export var bold = "styles-module--bold--63d7b";
export var center = "styles-module--center--7ffe0";
export var h1 = "styles-module--h1--8f776";
export var h1Bold = "styles-module--h1--bold--ca9f2";
export var h1Uc = "styles-module--h1--uc--3744d";
export var h2 = "styles-module--h2--4ea7c";
export var h2Bold = "styles-module--h2--bold--aa8fe";
export var h2Uc = "styles-module--h2--uc--e053c";
export var h3 = "styles-module--h3--ac351";
export var h3Bold = "styles-module--h3--bold--e23a6";
export var h3Uc = "styles-module--h3--uc--32be2";
export var h4 = "styles-module--h4--e4bd6";
export var h4Bold = "styles-module--h4--bold--3988a";
export var h4Uc = "styles-module--h4--uc--0bd07";
export var linkUnderline = "styles-module--link--underline--2c825";
export var microtext = "styles-module--microtext--c2af5";
export var microtextBold = "styles-module--microtext--bold--474d8";
export var microtextUc = "styles-module--microtext--uc--4edd3";
export var remove_button = "styles-module--remove_button--5382f";
export var strikethrough = "styles-module--strikethrough--2577e";
export var strikethroughLg = "styles-module--strikethrough--lg--4848e";
export var strikethroughSm = "styles-module--strikethrough--sm--e3bb4";
export var subtext = "styles-module--subtext--cdecd";
export var subtextBold = "styles-module--subtext--bold--85ef1";
export var subtextUc = "styles-module--subtext--uc--9e5a4";
export var text = "styles-module--text--c8215";
export var textBold = "styles-module--text--bold--a2dd9";
export var textUc = "styles-module--text--uc--4ad27";
export var titleL = "styles-module--title-l--c27ab";
export var titleM = "styles-module--title-m--40a61";
export var titleS = "styles-module--title-s--fe4d4";
export var titleXl = "styles-module--title-xl--68ebe";
export var titleXs = "styles-module--title-xs--d3ea8";
export var titleXxs = "styles-module--title-xxs--ec52d";
export var uc = "styles-module--uc--ea0be";
export var underline = "styles-module--underline--875d3";