// extracted by mini-css-extract-plugin
export var announcementSubtitle = "AnnouncementBar-module--announcement-subtitle--c4ad4";
export var announcementTitle = "AnnouncementBar-module--announcement-title--017cb";
export var awesome = "AnnouncementBar-module--awesome--0747a";
export var bold = "AnnouncementBar-module--bold--727dd";
export var center = "AnnouncementBar-module--center--db777";
export var close_button = "AnnouncementBar-module--close_button--63c18";
export var container = "AnnouncementBar-module--container--e446e";
export var containerHidden = "AnnouncementBar-module--container--hidden--5ee98";
export var content = "AnnouncementBar-module--content--ffce2";
export var disclaimer = "AnnouncementBar-module--disclaimer--37f8d";
export var discountContainer = "AnnouncementBar-module--discount-container--b68d4";
export var discountProgress = "AnnouncementBar-module--discount-progress--9a10b";
export var firstSlide = "AnnouncementBar-module--first-slide--f1444";
export var firstSlideDiscount = "AnnouncementBar-module--first-slide-discount--e71cc";
export var h1 = "AnnouncementBar-module--h1--7d32b";
export var h1Bold = "AnnouncementBar-module--h1--bold--02d9d";
export var h1Uc = "AnnouncementBar-module--h1--uc--b7ad5";
export var h2 = "AnnouncementBar-module--h2--58a62";
export var h2Bold = "AnnouncementBar-module--h2--bold--7af84";
export var h2Uc = "AnnouncementBar-module--h2--uc--dbff7";
export var h3 = "AnnouncementBar-module--h3--35887";
export var h3Bold = "AnnouncementBar-module--h3--bold--00f28";
export var h3Uc = "AnnouncementBar-module--h3--uc--1b12d";
export var h4 = "AnnouncementBar-module--h4--f6072";
export var h4Bold = "AnnouncementBar-module--h4--bold--6691b";
export var h4Uc = "AnnouncementBar-module--h4--uc--cf3e3";
export var link = "AnnouncementBar-module--link--96de4";
export var linkUnderline = "AnnouncementBar-module--link--underline--a6602";
export var luxeActive = "AnnouncementBar-module--luxe-active--4241a";
export var microtext = "AnnouncementBar-module--microtext--84560";
export var microtextBold = "AnnouncementBar-module--microtext--bold--82829";
export var microtextUc = "AnnouncementBar-module--microtext--uc--737ee";
export var mobileHide = "AnnouncementBar-module--mobile-hide--7e8bb";
export var mobile_only_br = "AnnouncementBar-module--mobile_only_br--ff2ee";
export var popup_ab = "AnnouncementBar-module--popup_ab--c0c97";
export var popup_container = "AnnouncementBar-module--popup_container--94d14";
export var popup_container_custom_image = "AnnouncementBar-module--popup_container_custom_image--3fbfa";
export var popup_container_image = "AnnouncementBar-module--popup_container_image--a9dad";
export var popup_container_subtitle = "AnnouncementBar-module--popup_container_subtitle--b9637";
export var popup_shipping_info_desktop = "AnnouncementBar-module--popup_shipping_info_desktop--23a7e";
export var popup_shipping_info_mobile = "AnnouncementBar-module--popup_shipping_info_mobile--83740";
export var popup_shipping_info_mobile_content = "AnnouncementBar-module--popup_shipping_info_mobile_content--aa6ad";
export var popup_shipping_info_mobile_title = "AnnouncementBar-module--popup_shipping_info_mobile_title--64615";
export var popup_title_deadline = "AnnouncementBar-module--popup_title_deadline--87db4";
export var popup_title_deadline_c = "AnnouncementBar-module--popup_title_deadline_c--4f449";
export var popup_title_deadline_content = "AnnouncementBar-module--popup_title_deadline_content--a5925";
export var popup_title_shipping_date = "AnnouncementBar-module--popup_title_shipping_date--a4095";
export var popup_title_shipping_type = "AnnouncementBar-module--popup_title_shipping_type--7a399";
export var progressBarDiscount = "AnnouncementBar-module--progress-bar-discount--2da79";
export var secondSlide = "AnnouncementBar-module--second-slide--c5ac3";
export var secondSlideContainerDiscount = "AnnouncementBar-module--second-slide-container-discount--853d6";
export var shippingSentence = "AnnouncementBar-module--shipping-sentence--1129c";
export var shippingSentenceDiscount = "AnnouncementBar-module--shipping-sentence-discount--31434";
export var slideContainer = "AnnouncementBar-module--slideContainer--f61e0";
export var spinnerContainer = "AnnouncementBar-module--spinner-container--aa502";
export var staticContent = "AnnouncementBar-module--static-content--ad2a2";
export var staticTimerDiscount = "AnnouncementBar-module--static-timer-discount--1579e";
export var sticky = "AnnouncementBar-module--sticky--49000";
export var strikethrough = "AnnouncementBar-module--strikethrough--d29bd";
export var strikethroughLg = "AnnouncementBar-module--strikethrough--lg--26e6b";
export var strikethroughSm = "AnnouncementBar-module--strikethrough--sm--1240a";
export var strong = "AnnouncementBar-module--strong--0579c";
export var subtext = "AnnouncementBar-module--subtext--2c976";
export var subtextBold = "AnnouncementBar-module--subtext--bold--b3a58";
export var subtextUc = "AnnouncementBar-module--subtext--uc--c2b96";
export var swiper = "AnnouncementBar-module--swiper--5243a";
export var swiperContainer = "AnnouncementBar-module--swiper-container--8f9fe";
export var swiperFreeShipping = "AnnouncementBar-module--swiper-free-shipping--aaa5f";
export var swiperSlide = "AnnouncementBar-module--swiper-slide--2c845";
export var swiperTimerDiscount = "AnnouncementBar-module--swiper-timer-discount--8c855";
export var swiperTimerShipping = "AnnouncementBar-module--swiper-timer-shipping--3774a";
export var text = "AnnouncementBar-module--text--d8d7a";
export var textBold = "AnnouncementBar-module--text--bold--0624f";
export var textUc = "AnnouncementBar-module--text--uc--e220b";
export var timer = "AnnouncementBar-module--timer--a7d36";
export var timerSecondSlideDiscount = "AnnouncementBar-module--timer-second-slide-discount--225e3";
export var timerTitle = "AnnouncementBar-module--timer-title--96b92";
export var titleContainer = "AnnouncementBar-module--title-container--6f79f";
export var titleL = "AnnouncementBar-module--title-l--aaa63";
export var titleM = "AnnouncementBar-module--title-m--d765c";
export var titleS = "AnnouncementBar-module--title-s--a2242";
export var titleXl = "AnnouncementBar-module--title-xl--9efba";
export var titleXs = "AnnouncementBar-module--title-xs--840ab";
export var titleXxs = "AnnouncementBar-module--title-xxs--5facd";
export var uc = "AnnouncementBar-module--uc--8d203";
export var underline = "AnnouncementBar-module--underline--8aa6f";