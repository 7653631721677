import { Script } from "gatsby";

export function wrapPageElement({ element }) {
  if (typeof window !== "undefined") {
    const botUserAgentsArray = ["Googlebot", "Storebot-Google", "HeadlessChrome"];
    const { userAgent } = window.navigator;
    if (userAgent) {
      let isBotUserAgent = 0;
      for (let j = 0; j < botUserAgentsArray.length; j++) {
        if (userAgent.toLowerCase().indexOf(botUserAgentsArray[j].toLowerCase()) !== -1) {
          isBotUserAgent = 1;
          break;
        }
      }

      if (isBotUserAgent === 1) {
        return element;
      }
    }
  }

  return (
    <>
      {/* This script needs to load before everything else */}
      <Script id="justuno-id">
        {`
          window.ju_num="8B96A93F-56B1-4279-B898-F4D595B167AF";
          window.asset_host='//cdn.jst.ai/';
          (function(i,s,o,g,r,a,m){i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script',asset_host+'vck.js','juapp');
        `}
      </Script>
      {element}
    </>
  );
}
