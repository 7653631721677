import { useAuth } from "../context/AuthProvider";

import { ALButton } from "./ALComponents";

import "./accountLoggedOut/styles.scss";

function AccountLoggedOut() {
  const { showLogin, showCreateAccount } = useAuth();

  return (
    <div className="container-logout" key="loggedout">
      <p className="membership">MEMBERSHIP ACCOUNT</p>
      <ALButton className="button" onClick={showLogin}>
        Log In
      </ALButton>
      <div className="create-account">
        Don't have an account? &nbsp;
        <button className="join-button" type="button" onClick={showCreateAccount}>
          Join Here
        </button>
      </div>
    </div>
  );
}

export default AccountLoggedOut;
