import { useContext } from "react";
import { DiscountContext } from "../context/DiscountProvider";

const DEFAULT_EXCLUSION_TAGS = ["Final Sale", "EXCLUSION"];

export function useExclusionTags() {
  const { discountInfo } = useContext(DiscountContext);
  const exclusionTags = discountInfo?.exclusionTags ?? DEFAULT_EXCLUSION_TAGS;
  return exclusionTags;
}
