import { useState, createContext, useMemo, useCallback } from "react";

const defaultHiddenElementsContext = {
  shouldHide: false,
  show: () => {},
  hide: () => {},
};

export const HiddenElementsContext = createContext(defaultHiddenElementsContext);

export function HiddenElementsContextProvider({ children }) {
  const [shouldHide, setShouldHide] = useState(0);
  const show = useCallback(() => setShouldHide(false), []);
  const hide = useCallback(() => setShouldHide(true), []);
  const context = useMemo(
    () => ({
      shouldHide,
      show,
      hide,
    }),
    [shouldHide, show, hide]
  );

  return (
    <HiddenElementsContext.Provider value={context}>{children}</HiddenElementsContext.Provider>
  );
}
