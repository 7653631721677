import axios from "axios";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as Styles from "./emergencyDaily/styles.module.scss";

import { useALError } from "../../../helpers/ErrorBoundary/ALErrorBoundary";

function EmergencyDaily({ productHandle, productType, shouldDisplayPreOrderFomo }) {
  const { sendReport } = useALError();
  const [unitSold, setUnitSold] = useState(null);

  const emergencyText = shouldDisplayPreOrderFomo
    ? `High demand style, ${unitSold} preorders in the last 24 hours`
    : `${unitSold} items purchased in the last 24 hours`;

  useEffect(() => {
    if (!unitSold && productHandle && productType) {
      axios
        .get(`/.netlify/functions/unit-sold?handle=${productHandle}&type=${productType}`)
        .then((r) => {
          setUnitSold(r?.data?.units);
        })
        .catch((err) => {
          console.log(err);
          sendReport(err, { name: "EmergencyDaily", priority: "P5" });
        });
    }
  }, [unitSold, productHandle, productType]);

  if (!unitSold) {
    return null;
  }

  return (
    <div className={`pdp_emergency ${Styles.pdp_emergency}`}>
      <div className={Styles.pdp_emergency_dot}>
        <div className={Styles.pdp_emergency_pulse}>&nbsp;</div>
        <div className={Styles.pdp_emergency_circle}>&nbsp;</div>
      </div>
      <p className={Styles.pdp_emergency_text}>{emergencyText}</p>
    </div>
  );
}

EmergencyDaily.propTypes = {
  productHandle: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
};

export default EmergencyDaily;
