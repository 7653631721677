import { isBrowser, decodeProductId, isCrawlerBot } from "../helpers";

function juSendAddToCart({ productId, variantId, sku, title, quantity, price }) {
  if (!isBrowser) return;
  window.juapp("cartItemAdd", {
    productid: productId,
    variationid: variantId,
    sku,
    name: title,
    quantity,
    price,
  });
}

function juViewSideCart(cart, currency) {
  if (!isBrowser || isCrawlerBot()) return;

  const cartItems = cart?.lines.reduce((red, i) => {
    const _productId = decodeProductId(i?.variant?.product?.id).replace(
      "gid://shopify/Product/",
      ""
    );
    const _variantId = decodeProductId(i?.variant?.id).replace("gid://shopify/ProductVariant/", "");

    red.push({
      productid: _productId,
      variationid: _variantId,
      sku: i?.variant?.sku,
      quantity: i?.quantity,
      price: i?.variant?.final_price,
      name: i?.title,
    });
    return red;
  }, []);

  window.juapp("cartItems", cartItems);

  window.juapp("cart", {
    total: cart?.fullAmount,
    subtotal: cart?.finalAmount,
    currency,
  });
}
export { juSendAddToCart, juViewSideCart };
