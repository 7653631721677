export const PaymentOption = {
  AMAZON_PAY: "AmazonPay",
  AMERICAN_EXPRESS: "American Express",
  APPLE_PAY: "ApplePay",
  DINERS: "Diners",
  DISCOVER: "Discover",
  ENETS: "eNETS",
  GIRO_PAY: "GiroPay",
  IDEAL: "iDeal",
  JCB: "JCB",
  KLARNA: "Klarna",
  MASTERCARD: "Mastercard",
  PAYPAL: "Paypal",
  UNION_PAY: "UnionPay",
  VISA: "Visa",
};
