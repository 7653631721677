import { useContext } from "react";
import StoreContext from "../context/store";
import { useCurrentFormatCurrency, useCurrentCountryCode } from "./usePrices";
import { useLocalizedSentenceDict } from "./useSentenceDict";
import { Country } from "../constants/Country";

export function useShippingInfo() {
  const context = useContext(StoreContext);
  return context?.store?.shippingFees;
}

export function useShippingMessage() {
  const shippingInfo = useShippingInfo();
  const countryCode = useCurrentCountryCode();
  const dict = useLocalizedSentenceDict();
  const formatCurrency = useCurrentFormatCurrency();

  if (shippingInfo?.freeShippingThreshold === 0) {
    if (countryCode === Country.US) {
      return dict.get("Free Shipping & Exchanges");
    }
    return dict.get("Free Shipping");
  }

  return dict.get(`Free shipping above {0}`, formatCurrency(shippingInfo?.freeShippingThreshold));
}
