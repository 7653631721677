import { useState } from "react";
import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import IconAdd from "../icons/svgs/add.svg";
import IconMinus from "../icons/svgs/minus.svg";
import IconArrow from "../icons/svgs/arrow.svg";
import { IconType } from "../../constants/IconType";
import "../../layouts/components.scss";

function Accordion(props) {
  const { title, children, titleClassName = "subtext", iconType = "" } = props;
  const [open, setOpen] = useState(props.isOpen || false);

  const toggleAccordion = () => {
    setOpen(!open);
  };

  const generateIcon = (isOpen) => {
    switch (iconType) {
      case IconType.ARROW:
        return <IconArrow width="15" height="15" className="al_accordion__icon_arrow" />;
      default:
        return isOpen ? (
          <div className="al_accordion__close">
            <IconMinus width="30" stroke="#2d2927" />
          </div>
        ) : (
          <div className="al_accordion__open">
            <IconAdd width="30" fill="#2d2927" />
          </div>
        );
    }
  };

  return (
    <div className={`al_accordion--section ${open ? "al_accordion_open" : ""}`}>
      <button className="al_accordion" onClick={toggleAccordion} type="button">
        <p className={`${titleClassName} ${open ? "bold" : ""}`}>{title}</p>
        {generateIcon(open)}
      </button>
      {open && <div className="al_accordion--content">{children}</div>}
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  titleClassName: PropTypes.string,
  iconType: PropTypes.string,
};

export default withALErrorBoundary({
  name: "Accordion",
  priority: "P2",
})(Accordion);
