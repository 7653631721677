import { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

import { GeoContext } from "../../context/GeoProvider";
import { edgesToArray } from "../../context/helpers";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import ErrorBoundary from "../ErrorBoundary";
import Menu from "./desktopMenu/Menu";

import "./desktopMenu/styles.scss";

function DesktopMenu({ onShowMenu }) {
  const { gePriceDetails } = useContext(GeoContext);

  const { allContentfulMenuWrapper } = useStaticQuery(graphql`
    query Menu {
      allContentfulMenuWrapper {
        edges {
          node {
            id
            node_locale
            countries {
              code
            }
            desktopMenuItems {
              id
              title
              node_locale
              type
              textColor
              contentful_id
              menuContainer {
                id
                title
                menuCategories: menuCategory {
                  id
                  title
                  titleLink
                  fontColor
                  links {
                    id
                    title
                    link {
                      link
                    }
                    setting: link___setting {
                      fontColor
                      fontWeight
                      fontStyle
                    }
                  }
                }
                imageLinks: menuLinkWithImage {
                  id
                  title
                  link {
                    title
                    link {
                      link
                    }
                  }
                  image {
                    altText
                    desktopImage {
                      gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  let menus = null;
  if (allContentfulMenuWrapper?.edges && allContentfulMenuWrapper?.edges.length > 0) {
    const allMenuArray = edgesToArray(allContentfulMenuWrapper);
    const defaultMenu = allMenuArray.find((n) => n?.countries?.some((c) => c.code === "RoW"));

    const localeSpecificMenu = allMenuArray.filter((n) =>
      n?.countries?.some((c) => c.code === gePriceDetails?.CountryCode)
    );
    const localeSpecificMenuRoot = localeSpecificMenu.find(
      (b) => b.node_locale === gePriceDetails.Culture
    );
    menus = localeSpecificMenuRoot?.desktopMenuItems ?? defaultMenu.desktopMenuItems;
  }

  const showMenu = (title) => {
    if (typeof onShowMenu === "function") {
      onShowMenu(title);
    }
  };

  return (
    <ErrorBoundary>
      <div className="header_bottom_menu navMenu__menus">
        {menus.map((menu) => (
          <Menu key={menu.id} tabIndex="0" menu={menu} showMenu={showMenu} />
        ))}
      </div>
    </ErrorBoundary>
  );
}

DesktopMenu.propTypes = {
  onShowMenu: PropTypes.func,
};

export default withALErrorBoundary({
  name: "NavMenu",
  priority: "P2",
})(DesktopMenu);
