import PropTypes from "prop-types";

import { isOutOfStock } from "../../context/helpers";
import { useCurrentCountryCode } from "../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useCheckInventory from "../../hooks/useCheckInventory";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { ComponentType } from "../../constants/ComponentType";
import { Country } from "../../constants/Country";
import { HandleType } from "../../constants/HandleType";

import "./alTag/styles.scss";

/**
 * Tag order:
 * 1. Preorder
 * 2. Out of Stock
 * 3. Custom tag
 * 4. Solid Gold
 * 5. Final Sale
 * 6. Low In Stock
 * 7. Selling Fast (UTM)
 * 8. Gift Set
 * 9. Engravable
 * 10. New
 */

function ALTag({ product, componentType, priceInfo, shouldDisplayPreOrder }) {
  const dict = useLocalizedSentenceDict();
  const countryCode = useCurrentCountryCode();
  const productInventory = useCheckInventory(product.contentful?.node?.handle);

  const isSoldOut =
    isOutOfStock(product.node, countryCode) &&
    product.contentful?.node?.handle !== HandleType.GIFT_CARD;
  const isGiftCard = product.node.handle === "gift-card";

  const customTag = product?.node?.tags?.find((e) => e.includes("Tag:"));
  const hasDiscount = priceInfo?.formattedDiscount && priceInfo?.discountAmount > 0;

  let tag = null;
  let type = null;

  // do not show tags on byo pages
  if (componentType === ComponentType.BYOB) {
    return null;
  }

  const currentDate = new Date();
  const releaseDate = new Date(product?.contentful?.node?.releaseDate);
  const timeDifference = currentDate - releaseDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // 1. Preorder
  if (product.contentful?.node?.isPreOrder && countryCode === Country.US && shouldDisplayPreOrder) {
    tag = "Preorder";
    type = "preorder";
  }

  // 2. Out of Stock
  else if (isSoldOut) {
    tag = "Out of stock";
    type = "outofstock";
  }

  // 3. Custom tag
  else if (customTag) {
    [, tag] = customTag.split(":");
    type = "default";
  }

  // 4. Solid Gold
  else if (
    product.node?.tags?.includes("Components:Solid Gold") ||
    product.contentful?.node?.productDetails?.components?.includes("Components:Solid Gold") ||
    product.contentful?.node?.productDetails?.componentsFilter?.includes("Components:Solid Gold")
  ) {
    tag = "Solid Gold";
    type = "solidgold";
  }

  // 5. Final Sale
  else if (product.node?.tags?.includes("Final Sale")) {
    tag = "Final Sale";
    type = "finalsale";
  }

  // 6. Low In Stock
  else if (productInventory.isLowInStock && !isGiftCard) {
    tag = "Low In Stock";
    type = "lowinstock";
  }

  // 7. Selling Fast (UTM)
  else if (product?.isUtm) {
    tag = "Selling Fast";
    type = "sellingfast";
  }

  // 8. Gift Set
  else if (product?.node?.productType === "Jewelry Sets") {
    tag = "Gift Set";
    type = "default";
  }

  // 9. Engravable
  else if (product?.contentful?.node?.engravable?.isEngravable) {
    tag = "Engravable";
    type = "default";
  }

  // 10. New
  else if (daysDifference <= 45 && daysDifference >= 0) {
    tag = "New";
    type = "new";
  }

  // Discount amount tag
  if (componentType === ComponentType.PDP && hasDiscount) {
    tag = `${priceInfo?.formattedDiscount} OFF`;
    type = "sale";
  }

  if (tag === null) {
    return null;
  }

  return (
    <div
      className={`al_tag al_tag_${type} al_tag_component_${componentType.toLowerCase()} microtext microtext--bold microtext--uc`}
      data-testid="al-tag"
    >
      {dict.get(tag)}
    </div>
  );
}

ALTag.propTypes = {
  product: PropTypes.shape({
    node: PropTypes.shape({
      handle: PropTypes.string,
      createdAt: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      productType: PropTypes.string,
      variants: PropTypes.arrayOf(
        PropTypes.shape({
          availableForSale: PropTypes.bool,
          inventoryPolicy: PropTypes.string,
        })
      ),
    }),
    contentful: PropTypes.shape({
      node: PropTypes.shape({
        handle: PropTypes.string,
        releaseDate: PropTypes.string,
        productDetails: PropTypes.shape({
          components: PropTypes.arrayOf(PropTypes.string),
          componentsFilter: PropTypes.arrayOf(PropTypes.string),
        }),
        engravable: PropTypes.shape({
          isEngravable: PropTypes.bool,
        }),
        isPreOrder: PropTypes.bool,
      }),
    }),
    isUtm: PropTypes.bool,
  }),
  componentType: PropTypes.string,
  priceInfo: PropTypes.shape({
    formattedDiscount: PropTypes.string,
    discountAmount: PropTypes.number,
  }),
  shouldDisplayPreOrder: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "ALTag",
  priority: "P3",
})(ALTag);
