import { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import ReactPopup from "reactjs-popup";
import Panzoom from "@panzoom/panzoom";
import { Swiper, SwiperSlide } from "swiper/react";

import { resizeShopifyImage } from "../../../context/helpers";
import CloseIcon from "../../icons/svgs/close.svg";

import * as Styles from "./mobileGalleryPopup/styles.module.scss";

export function getTransformFunction(panzoomInstance) {
  return (e, { scale, x, y }) => {
    // Normalize scale and x, y (when scale is default - 1)
    // to prevent image from moving out of the container

    let normalizedScale = scale ** 2; // Add fluidity to zoom
    normalizedScale = normalizedScale > 1 ? normalizedScale : 1;
    normalizedScale = normalizedScale > 4 ? 4 : normalizedScale;
    const normalizedX = normalizedScale === 1 ? 0 : x;
    const normalizedY = normalizedScale === 1 ? 0 : y;

    panzoomInstance.setStyle(
      "transform",
      `scale(${normalizedScale}) translate(${normalizedX}px, ${normalizedY}px)`
    );
  };
}

function MobileGalleryPopup({ images, activeImage, setActiveImage, closePopup }) {
  const panzoomInstanceRef = useRef(null);
  const swiperInstanceRef = useRef(null);

  // When image changes, if the popup is open and the image container exists, reinitialize panzoom
  useEffect(() => {
    const elem = document.getElementById(`image-${activeImage.url}`);

    if (elem) {
      const panzoomInstance = Panzoom(elem);
      panzoomInstance.setOptions({
        maxScale: 4,
        startScale: 1,
        pinchAndPan: true,
        contain: "outside",
        setTransform: getTransformFunction(panzoomInstance),
      });
      panzoomInstanceRef.current = panzoomInstance;
      elem.addEventListener("wheel", panzoomInstance.zoomWithWheel);
      return () => {
        elem?.removeEventListener("wheel", panzoomInstance.zoomWithWheel);
        panzoomInstance.destroy();
      };
    }
  }, [activeImage]);

  // Prevent scrolling the page when popup is open
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  // Reset pan zoom instance when popup is closed
  const onClosePopup = () => {
    panzoomInstanceRef?.current?.destroy();
    closePopup();
  };

  const activeImageIndex = useMemo(
    () => images.findIndex((image) => image.url === activeImage.url) || 0,
    [activeImage, images]
  );

  return (
    <ReactPopup open onClose={onClosePopup} contentStyle={{ height: "100vh", width: "100%" }}>
      <div className={Styles.popupContainer}>
        <div className={Styles.topBar}>
          <button type="button" onClick={onClosePopup} aria-label="Close Popup">
            <CloseIcon height="30" width="30" />
          </button>
        </div>

        <div className={Styles.imageContainer}>
          <Swiper
            initialSlide={activeImageIndex}
            spaceBetween={0}
            slidesPerView={1}
            onSwiper={(swiper) => {
              swiperInstanceRef.current = swiper;
            }}
            onActiveIndexChange={(swiper) => {
              setActiveImage(images[swiper.realIndex]);
            }}
          >
            {images.map((img) => (
              <SwiperSlide key={img.url}>
                <div id={`image-${img.url}`}>
                  <img src={img.url} alt={img.altText} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className={Styles.thumbsContainer}>
          <div className={Styles.thumbs}>
            {images.map((img, idx) => (
              <button
                key={`mobile-popup-${img.url}`}
                onClick={() => {
                  swiperInstanceRef.current?.slideTo(idx);
                  setActiveImage(img);
                }}
                style={{ backgroundImage: `url(${resizeShopifyImage(img.url, "x125")})` }}
                type="button"
                aria-label={`navigation image ${idx}`}
              >
                &nbsp;
              </button>
            ))}
          </div>
        </div>
      </div>
    </ReactPopup>
  );
}

MobileGalleryPopup.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      altText: PropTypes.string,
    })
  ),
  activeImage: PropTypes.shape({
    url: PropTypes.string,
    altText: PropTypes.string,
  }),
  closePopup: PropTypes.func,
  setActiveImage: PropTypes.func,
};

export default MobileGalleryPopup;
