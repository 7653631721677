const fetch = require("node-fetch");
/* eslint-disable import/no-extraneous-dependencies */
const cookie = require("cookie");
const crypto = require("crypto");
const axios = require("axios");
const { createHash } = require("crypto");
const { getAllStoreConfigs } = require("../locale-shopifies");

function getShopifyHeader(headers, key) {
  const topic = headers[key] ?? headers[key];
  return topic;
}

function hashEmailServer(email) {
  if (email) {
    return Buffer.from(createHash("sha256").update(email).digest("hex")).toString("base64"); // OWNjYTA3MD...
  }
  return email;
}

function decryptData(text) {
  const AL_SHA_ACC = process.env.GATSBY_AL_SHA_ACC; // Must be 256 bits (32 characters)
  const textParts = text.split(":");
  const iv = Buffer.from(textParts.shift(), "hex");
  const encryptedText = Buffer.from(textParts.join(":"), "hex");
  const decipher = crypto.createDecipheriv("aes-256-cbc", Buffer.from(AL_SHA_ACC), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}

function decryptCartLines(lines) {
  return lines.map(({ attributes, ...line }) => ({
    ...line,
    attributes: attributes.map(({ key, value, type }) =>
      type === "enc" ? { key: decryptData(key), value: decryptData(value) } : { key, value }
    ),
  }));
}

function idle(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function waitUntilBulkOpComplete(storeName, adminPassword) {
  const MAX_RETRIES = 1000;
  let bulkOpResult = null;
  let retries = 0;
  while (retries < MAX_RETRIES) {
    bulkOpResult = await runShopifyAdminQuery(
      storeName,
      adminPassword,
      `
  query {
    currentBulkOperation {
      id
      status
      errorCode
      createdAt
      completedAt
      objectCount
      fileSize
      url
      partialDataUrl
      query
    }
  }
  `,
      "currentBulkOperation"
    );
    if (bulkOpResult === null || bulkOpResult.status === "COMPLETED") {
      break;
    }
    retries++;
    await idle(retries * 2 * 100);
  }
  return bulkOpResult;
}

async function runShopifyAdminQuery(storeName, adminPassword, query, dataKey) {
  const res = await fetch(`https://${storeName}.myshopify.com/admin/api/2022-10/graphql.json`, {
    method: "POST",
    headers: {
      "X-Shopify-Access-Token": adminPassword,
      Accept: "application/json",
      "Content-Type": "application/graphql",
    },
    body: query,
  });
  if (res.status !== 202 && res.status !== 200) {
    throw new Error(`Failed to queue bulk operation, status=${res.status} query=${query}`);
  }
  const json = await res.json();
  const data = json.data[dataKey];
  if (data?.userErrors?.length > 0) {
    throw new Error(`Failed to queue bulk operation: ${JSON.stringify(data.userErrors)}`);
  }
  return data;
}

async function runShopifyBulkQuery(storeName, adminPassword, query) {
  const mutation = `
    mutation {
      bulkOperationRunQuery(
       query: """
         ${query}
        """
      ) {
        bulkOperation {
          id
          status
        }
        userErrors {
          field
          message
        }
      }
    }
    `;
  console.log("Shopify: Waiting for the completion of in-progress bulk operation...");
  await waitUntilBulkOpComplete(storeName, adminPassword);
  console.log("Shopify: Creating bulk query...");
  await runShopifyAdminQuery(storeName, adminPassword, mutation, "bulkOperationRunQuery");
  console.log("Shopify: Waiting for the completion of the bulk operation...");
  const { url, errorCode, status } = await waitUntilBulkOpComplete(storeName, adminPassword);
  if (status !== "COMPLETED" || url === null) {
    throw new Error(`Failed to run bulk operation: status=${status},errorCode=${errorCode}`);
  } else {
    return url;
  }
}

async function runShopifyMutation({ store, query, dataKey, clientIp = null }) {
  let result = null;
  let body = null;
  try {
    result = await fetch(`https://${store.name}.myshopify.com/api/2022-10/graphql.json`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/graphql",
        "X-Shopify-Storefront-Access-Token": store.storefrontAccessToken,
        ...(clientIp ? { "Shopify-Storefront-Buyer-IP": clientIp } : {}),
      },
      body: query,
    });
    body = await result.text();
    console.log("text body:", body);
    body = JSON.parse(body);
    console.log("json body:", body);
    // body = await result.json();
    if (result.status !== 200) {
      throw new Error("Shopify returned status != 200");
    }
    return { status: "ok", body: body?.data?.[dataKey] };
  } catch (e) {
    console.error(e);
    const returnValue = { status: "error" };
    if (result) {
      returnValue.statusCode = result.status;
      returnValue.statusText = result.statusText;
    }
    if (body) {
      returnValue.body = body;
    } else {
      try {
        returnValue.textBody = await result.text();
      } catch (err) {
        console.error("No request body could be retrieved");
      }
    }

    return returnValue;
  }
}

function returnShopifyMutationResponse({ store, result, returnAdditionalHeaders = {} }) {
  if (result.status === "error" && !result.statusCode) {
    return {
      statusCode: 500,
      body: "Internal server error",
    };
  }
  if (result.status === "error" && result.statusCode !== 200) {
    return {
      statusCode: 400,
      body: "Error occurred while calling Shopify",
    };
  }
  if (result.body?.errors?.length) {
    // Adding this code as legacy — TODO: ensure this situation can happen
    if (result.body.errors.some((error) => error?.extensions?.code === "ACCESS_DENIED")) {
      return {
        statusCode: 401,
        body: "Authentication expired",
      };
    }
    return {
      statusCode: 400,
      body: result.body.errors[0].message,
    };
  }
  if (result.body?.customerUserErrors?.length) {
    return {
      statusCode: 500,
      body: result.body.customerUserErrors[0].message,
    };
  }
  if (result.body?.userErrors?.length) {
    return {
      statusCode: 500,
      body: result.body.userErrors[0].message,
    };
  }

  let authCookie = null;
  const token = result.body?.customerAccessToken?.accessToken;
  if (token) {
    authCookie = cookie.serialize(`${store.name}-accessToken`, token, {
      secure: true,
      httpOnly: false,
      path: "/",
      expires: new Date(result.body.customerAccessToken.expiresAt),
    });
  }
  if (result.body?.deletedAccessToken) {
    authCookie = cookie.serialize(`${store.name}-accessToken`, "", {
      secure: true,
      httpOnly: false,
      path: "/",
      maxAge: 0,
    });
  }

  const responseHeaders = {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    ...(authCookie ? { "Set-Cookie": authCookie } : {}),
    ...returnAdditionalHeaders,
  };

  return {
    statusCode: 200,
    headers: responseHeaders,
    body: JSON.stringify(result.body),
  };
}

async function convertToFunctionV2Response(v1ResponseObject) {
  const { body, headers, statusCode: status } = v1ResponseObject;
  return new Response(body, { headers, status });
}

async function queryShopifyProducts(handles, queryFields) {
  const shopifyQuery = `
  query {
    ${handles
      .map(
        (handle, idx) => `
    product${idx}: productByHandle(handle: "${handle}") {
      ${queryFields} 
    }`
      )
      .join("\n")}
  }
`;

  const res = await fetch("https://analuisaparis.myshopify.com/api/2022-10/graphql.json", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/graphql",
      "X-Shopify-Storefront-Access-Token": process.env.SHOPIFY_ACCESS_TOKEN,
    },
    body: shopifyQuery,
  });
  if (res.status !== 200) {
    console.error(res.status, res.statusText);
    throw new Error("Failed to fetch product data from shopify");
  }
  const json = await res.json();

  if (json.data) {
    const keys = Object.keys(json.data);
    return keys.reduce((acc, key) => {
      const product = json.data[key];

      if (product) {
        const { handle } = product;
        acc[handle] = product;
      }
      return acc;
    }, {});
  }
  console.error(json);
  throw new Error("Failed to fetch product data from shopify");
}

async function updateShopifyProduct(id, data, storeName) {
  if (!id && !data) return false;

  const res = await fetch(
    `https://${storeName}.myshopify.com/admin/api/2022-10/products/${id}.json`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Access-Token": "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",
      },
      body: JSON.stringify(data),
    }
  );

  if (res.status != 200) {
    return false;
  }
  return true;
}

async function queryContentfulProducts(handles, queryFields, locale = "en-US") {
  try {
    const handlesFilter = `[${handles.map((h) => `"${h}"`).join(",")}]`;
    const contentfulQuery = `
      query {
        products: productPageCollection(where: { handle_in: ${handlesFilter} }, locale: "${locale}") {
          items {
            ${queryFields}
          }
        }
      }
    `;

    // GET (have less limitation from contentful API)
    const res = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/environments/${process.env.CONTENTFUL_ENVIRONMENT_ID}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: contentfulQuery }),
      }
    );
    if (res.status !== 200) {
      const body = await res.json();
      console.error(res.status, res.statusText, JSON.stringify(body, null, 2));
      throw new Error("Failed to fetch product data from contentful");
    }
    const json = await res.json();
    if (json.data) {
      return json.data.products.items;
    }
    console.error(json);
    throw new Error("Failed to fetch product data from Contentful");
  } catch (error) {
    console.error(error);
  }
}

async function getAllProductHandles(skip = 0) {
  const baseUrl = `https://${process.env.CONTENTFUL_HOST || "cdn.contentful.com"}`;
  const environmentId = process.env.CONTENTFUL_ENVIRONMENT_ID;
  const contentfulLimit = 10;
  const url = `${baseUrl}/spaces/${process.env.CONTENTFUL_SPACE_ID}/environments/${environmentId}/entries?access_token=${process.env.CONTENTFUL_ACCESS_TOKEN}&content_type=productPage&select=fields.handle&skip=${skip}&limit=${contentfulLimit}`;
  const res = await fetch(url, { method: "GET" });
  if (res.status !== 200) {
    const body = await res.json();
    console.error(res.status, res.statusText, JSON.stringify(body, null, 2));
    throw new Error("Failed to fetch product data from contentful");
  }
  const json = await res.json();
  return {
    total: json.total,
    skip: json.skip,
    limit: json.limit,
    handles: json.items.map((p) => p.fields.handle),
  };
}

// Returns headers necessary to allow the checkout page to query the endpoint
// Ensure strict validation rules based on domain and requested headers/method
function checkoutCORS(headers) {
  const { origin } = headers;
  const allShopifyDomains = getAllStoreConfigs().map(
    (store) => `https://${store.shopify.name}.myshopify.com`
  );
  const allowedDomain = [...allShopifyDomains, "https://checkout.analuisa.com"].find(
    (shopifyDomain) => origin === shopifyDomain
  );
  return {
    "Access-Control-Allow-Origin": allowedDomain || "https://www.analuisa.com",
    "Access-Control-Allow-Headers": headers["access-control-request-headers"]
      ? `${headers["access-control-request-headers"]}`
      : "content-type",
    "Access-Control-Allow-Methods": headers["access-control-request-method"]
      ? `${headers["access-control-request-method"]}, OPTION`
      : "OPTION", // Always allow OPTION for preflight
  };
}

function formatSuccessResponse(rvalues = {}, headers = [], { withCheckoutCORS = false } = {}) {
  const returnObject = {
    statusCode: 200,
    headers: withCheckoutCORS && checkoutCORS(headers),
  };
  if (rvalues.json) {
    returnObject.body = JSON.stringify(rvalues.json);
    returnObject.headers = { ...(returnObject.headers || {}), "Content-Type": "application/json" };
    delete rvalues.json;
  }
  if (rvalues.headers) {
    returnObject.headers = { ...(returnObject.headers || {}), ...rvalues.headers };
    delete rvalues.headers;
  }
  return { ...returnObject, ...rvalues };
}

const headersExponea = {
  Accept: "application/json",
  authorization: `${process.env.EXPONEA_AUTH}`,
  "Content-type": "application/json",
  "content-type": "application/json",
};

async function triggerExponeaEvent(eventName, eventData, userEmail) {
  try {
    const dataEvent = {
      customer_ids: {
        email_id: userEmail,
      },
      event_type: eventName,
      timestamp: Math.floor(new Date().getTime() / 1000),
      properties: eventData,
    };
    const url = `${process.env.EXPONEA_URL}/track/v2/projects/${process.env.PROJECT_TOKEN_EXPONEA}/customers/events`;
    await axios.post(url, dataEvent, { headers: headersExponea });
  } catch (error) {
    console.log("triggerExponeaEvent");
    console.error(error?.response);
  }
}

async function triggerExponeaProperties(properties, userEmail) {
  try {
    const dataEvent = {
      customer_ids: {
        registered: userEmail,
        email_id: userEmail,
      },
      update_timestamp: Math.floor(new Date().getTime() / 1000),
      properties,
    };
    const url = `${process.env.EXPONEA_URL}/track/v2/projects/${process.env.PROJECT_TOKEN_EXPONEA}/customers`;
    await axios.post(url, dataEvent, { headers: headersExponea });
  } catch (error) {
    console.log("ERR: triggerExponeaProperties");
    console.log(error);
    console.error(error?.response);
  }
}

function encodeShopifyId(id) {
  return id.startsWith("gid://") ? Buffer.from(id).toString("base64") : id;
}

function decodeShopifyId(id) {
  return id.startsWith("gid://") ? id : Buffer.from(id, "base64").toString();
}

function getShopifyId(id) {
  const decodedId = decodeShopifyId(id);
  return decodedId.split("/").pop();
}

async function getMetafieldsShopify(customerId, retry = 0) {
  const res = await fetch(
    `https://${process.env.GATSBY_SHOPIFY1_STORE_NAME}.myshopify.com/admin/api/2022-10/customers/${customerId}/metafields.json`,
    {
      method: "GET",
      headers: {
        "X-Shopify-Access-Token": process.env.SHOPIFY1_ADMIN_PASSWORD,
      },
    }
  );

  if (res.status !== 200) {
    if (retry < 6) {
      // wait function...
      console.log(
        `Retry GET metafields, store=${process.env.GATSBY_SHOPIFY1_STORE_NAME}, retry=${retry}/5`
      );
      await new Promise((r) => setTimeout(r, parseInt(retry) * 150));
      return getMetafieldsShopify(customerId, (retry += 1));
    }
    throw new Error(
      `Failed to query list of metafields store=${process.env.GATSBY_SHOPIFY1_STORE_NAME}, status=${res.status}`
    );
  }
  const data = await res.json();
  const { metafields } = data;

  if (metafields) {
    return metafields;
  }
  return null;
}

function edgesToArray(root) {
  return root.edges.map((e) => e.node);
}

exports.runShopifyMutation = runShopifyMutation;
exports.returnShopifyMutationResponse = returnShopifyMutationResponse;
exports.decryptData = decryptData;
exports.queryShopifyProducts = queryShopifyProducts;
exports.queryContentfulProducts = queryContentfulProducts;
exports.getAllProductHandles = getAllProductHandles;
exports.runShopifyBulkQuery = runShopifyBulkQuery;
exports.getShopifyHeader = getShopifyHeader;
exports.updateShopifyProduct = updateShopifyProduct;
exports.formatSuccessResponse = formatSuccessResponse;
exports.triggerExponeaEvent = triggerExponeaEvent;
exports.triggerExponeaProperties = triggerExponeaProperties;
exports.encodeShopifyId = encodeShopifyId;
exports.decodeShopifyId = decodeShopifyId;
exports.getShopifyId = getShopifyId;
exports.hashEmailServer = hashEmailServer;
exports.decryptCartLines = decryptCartLines;
exports.convertToFunctionV2Response = convertToFunctionV2Response;
exports.checkoutCORS = checkoutCORS;
exports.getMetafieldsShopify = getMetafieldsShopify;
exports.runShopifyAdminQuery = runShopifyAdminQuery;
exports.edgesToArray = edgesToArray;
