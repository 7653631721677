import { memo } from "react";
import { Link as GLink } from "gatsby";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

function Link(props) {
  return <GLink {...props} />;
}

const WrappedLink = withALErrorBoundary({
  name: "Link",
  priority: "P1",
})(memo(Link));

export { WrappedLink as Link };
