/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { forwardRef, useCallback } from "react";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/gatsby";
import hoistNonReactStatics from "hoist-non-react-statics";

import { useAuth } from "../../context/AuthProvider";
import { isBrowser } from "../../context/helpers";

const PRIORITIES = ["P1", "P2", "P3", "P4", "P5"];

// Abstraction of error reporting
function alReportError(error, name, priority, email = "", componentStack = null) {
  // for non production envs, log errors in the browser console, and not Sentry
  if (process.env.NODE_ENV !== "production" || !isBrowser) {
    console.error(error);
    return;
  }

  Sentry.withScope((scope) => {
    scope.setTag("component-name", name);
    scope.setTag("priority", priority);
    scope.setTag("full-url", window.location.href);
    if (email) {
      // From Sentry doc:
      // "If the user's ip_address is set to "{{auto}}", Sentry will infer the IP address
      // from the connection between your app and Sentry's server."
      scope.setUser({ email, ip_address: "{{auto}}" });
    }
    if (componentStack) {
      // Imitate the behavior of Sentry Error Boundary
      // See: https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/errorboundary.tsx#L138
      Sentry.captureException(error, { contexts: { react: { componentStack } } });
    } else {
      Sentry.captureException(error);
    }
  });
}

/**
 * Hook to use for reporting errors and/or triggering the error boundary
 *
 * sendReport(error, { name, priority }): Send error report with the given name and priority
 * throwReport(error): Send error report AND make the component fallback to the nearest ALErrorBoundary
 */
function useALError() {
  const { showBoundary, resetBoundary } = useErrorBoundary();
  const { accountDetails } = useAuth();

  const sendReport = useCallback(
    (error, { name, priority }) => {
      const customerEmail = accountDetails?.email || (isBrowser && window.exponea?.email_id) || "";
      alReportError(error, name, priority, customerEmail);
    },
    [accountDetails?.email]
  );

  return { throwReport: showBoundary, sendReport, resetBoundary };
}

// Error boundary abstraction
function ALErrorBoundary({ children, fallback, name, priority }) {
  const { accountDetails } = useAuth();

  // Ensure required properties
  if (!priority || typeof priority !== "string" || !PRIORITIES.includes(priority))
    throw new Error(
      `ALErrorBoundary require one of the following 'priority' to be setup: ${PRIORITIES.join(
        ", "
      )}`
    );
  if (!name) throw new Error("ALErrorBoundary require the component 'name' to be setup");

  const onError = (error, { componentStack }) => {
    const customerEmail = accountDetails?.email || (isBrowser && window.exponea?.email_id) || "";
    alReportError(error, name, priority, customerEmail, componentStack);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <ErrorBoundary fallback={fallback || <></>} onError={onError}>
      {children}
    </ErrorBoundary>
  );
}

ALErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  fallback: PropTypes.element,
  name: PropTypes.string.isRequired,
  priority: PropTypes.oneOf(PRIORITIES).isRequired,
};

/**
 * HOC, see: https://legacy.reactjs.org/docs/higher-order-components.html
 * Prototype Component => Component
 * @param {Object} errorBoundaryOptions Settings of error report
 * @param {string} errorBoundaryOptions.name Name of the component
 * @param {"P1"|"P2"|"P3"|"P4"|"P5"} errorBoundaryOptions.priority
 * @param {Component} errorBoundaryOptions.fallback Fallback component
 * @example <caption>Usage:</caption>
 * // returns MyComponent with ErrorBoundary
 * withALErrorBoundary({name: "MyComponent", priority: "P2"})(MyComponent);
 */
function withALErrorBoundary(errorBoundaryOptions) {
  return (Component) => {
    const Wrapped = forwardRef((props, ref) => (
      <ALErrorBoundary {...errorBoundaryOptions}>
        <Component ref={ref} {...props} />
      </ALErrorBoundary>
    ));
    const componentDisplayName =
      Component.displayName || Component.name || errorBoundaryOptions.name || "Unknown";

    // Convention used to show wrapping in DevTools
    Wrapped.displayName = `withALErrorBoundary(${componentDisplayName})`;

    // Copy over static method
    // See: https://legacy.reactjs.org/docs/higher-order-components.html#static-methods-must-be-copied-over
    hoistNonReactStatics(Wrapped, Component);

    return Wrapped;
  };
}

export { ALErrorBoundary, withALErrorBoundary, useALError, alReportError };
