import { useState, memo } from "react";
import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import ALLoading from "./ALLoading";

import "./alButton/styles.scss";

function ALButton({
  className = "",
  size = "medium",
  variant = "primary",
  isLoading = false,
  type = "button",
  pillButton = true,
  fullWidth = false,
  buttonBackgroundColor = "",
  buttonTextColor = "",
  onClick = () => {},
  children,
  ...rest // anything else
}) {
  const [buttonDynamicStyles, setButtonDynamicStyles] = useState({
    backgroundColor: buttonBackgroundColor,
    borderColor: buttonBackgroundColor,
    color: buttonTextColor,
  });

  const handleFocus = () => {
    setButtonDynamicStyles({
      backgroundColor: buttonTextColor,
      borderColor: buttonBackgroundColor,
      color: buttonBackgroundColor,
    });
  };

  const handleMouseOver = () => {
    setButtonDynamicStyles({
      backgroundColor: buttonTextColor,
      borderColor: buttonBackgroundColor,
      color: buttonBackgroundColor,
    });
  };

  const handleMouseLeave = () => {
    setButtonDynamicStyles({
      backgroundColor: buttonBackgroundColor,
      borderColor: buttonBackgroundColor,
      color: buttonTextColor,
    });
  };

  return (
    <button
      style={{ ...buttonDynamicStyles }}
      className={`button button--${size} button--${variant} ${
        isLoading ? "al_button--loading" : ""
      } ${fullWidth ? "button--fullWidth" : ""} ${
        !pillButton ? "button-sm-radius" : ""
      } ${className}`}
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onFocus={handleFocus}
      onMouseLeave={handleMouseLeave}
      type={type}
      {...rest}
    >
      {isLoading ? <ALLoading /> : children}
    </button>
  );
}

ALButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "black", "white", "plain"]),
  pillButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  buttonBackgroundColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  children: PropTypes.node,
};

export default withALErrorBoundary({
  name: "ALButton",
  priority: "P1",
})(memo(ALButton));
