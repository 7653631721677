import PropTypes from "prop-types";
import { useContext } from "react";

import { LuxeContext } from "../../../../context/LuxeProvider";
import { GeoContext } from "../../../../context/GeoProvider";
import { getCookie } from "../../../../context/helpers";

import useDeviceDetect from "../../../../hooks/useDeviceDetect";
import { formatPriceAmount } from "../../../../hooks/usePrices";

import LuxeIcon from "../../../icons/svgs/luxe.svg";

import * as Styles from "./optionalProductsTotalPrice/styles.module.scss";

function OptionalProductsTotalPrice({ totalPrice, optionalProductsConfig }) {
  const { luxe, applyLuxeDiscountToProductPrice } = useContext(LuxeContext);
  const { gePriceDetails } = useContext(GeoContext);
  const { isMobile } = useDeviceDetect();
  const luxeTotalPrice = applyLuxeDiscountToProductPrice(totalPrice);
  const disableSubscriptionCookie = getCookie("subscription_disabled");
  const shouldDisableLuxe = !!disableSubscriptionCookie;

  const { optionalProductsType } = optionalProductsConfig;
  const showLuxePrices = luxe.isLuxeEnabled && !shouldDisableLuxe;

  return (
    <div className={Styles.price} data-testid="optional-products-price">
      <p className={Styles.price__label}>{`Total ${optionalProductsType}`}</p>
      <div className={Styles.price__value}>
        {showLuxePrices && (
          <div className={Styles.price__value__luxe}>
            <LuxeIcon
              {...(isMobile ? { width: "14" } : { height: "16" })}
              className={Styles.price__value__luxe__icon}
              data-testid="luxe-icon"
            />
            <p className={Styles.price__value__luxe__price}>{luxeTotalPrice}</p>
          </div>
        )}
        <p>
          {formatPriceAmount(totalPrice, gePriceDetails?.CurrencyCode, gePriceDetails?.CountryCode)}
        </p>
      </div>
    </div>
  );
}

OptionalProductsTotalPrice.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  optionalProductsConfig: PropTypes.shape({
    optionalProductsType: PropTypes.string,
  }).isRequired,
};

export default OptionalProductsTotalPrice;
