import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";

const CACHE_AVAILABILITY = 5 * 60 * 1000; // 5 minutes

/**
 * Do one query for all handles but caches data at "handle level"
 * lambda functions have to return a list of object with the key 'handle'
 * This hook returns results with the same handles order
 * @param {*} key lambda function to call
 * @param {*} params additional parameter passed to the lambda function except 'handles'
 * @param {*} handles ["product-handle", ...]
 * @param {*} canRetrieve bool representing when the hook starts retrieving data
 * @returns [{ handle: "product-handle", isLoading: false, data: {...} }, ...]
 */
export default function useDataByHandles(key, params = {}, handles = [], canRetrieve = true) {
  const { sendReport } = useALError();
  const queryClient = useQueryClient();
  const queryResult = useQuery({
    queryKey: [key, params, ...handles],
    queryFn: async () => {
      const uncachedHandles = handles.filter(
        (handle) => !queryClient.getQueryData([key, handle, params])
      );
      if (!uncachedHandles.length) {
        // If all data is already cached => return only cached data
        return handles.map((handle) => queryClient.getQueryData([key, handle, params]));
      }
      // Query un-cached handles
      const res = await axios.get(`/.netlify/functions/get-data`, {
        params: { ...params, key, handles: uncachedHandles },
      });
      if (res.status !== 200 || res.data?.key !== key) {
        console.error("Error on get-data status:", res.status);
        throw new Error("Error on get-data");
      }
      const uncachedHandlesData = res.data.result;
      // cache retrieved data
      uncachedHandlesData.forEach((data) => {
        queryClient.setQueryData([key, data.handle, params], data);
      });

      return handles.map((handle) => queryClient.getQueryData([key, handle, params]));
    },
    staleTime: CACHE_AVAILABILITY,
    enabled: canRetrieve,
    meta: { name: "useDataByHandles", priority: "P1", sendReport },
  });

  return queryResult;
}
