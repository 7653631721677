// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--61869";
export var bold = "styles-module--bold--64ecb";
export var center = "styles-module--center--ca8ce";
export var close = "styles-module--close--20dd0";
export var h1 = "styles-module--h1--f4083";
export var h1Bold = "styles-module--h1--bold--2425e";
export var h1Uc = "styles-module--h1--uc--4b36f";
export var h2 = "styles-module--h2--d289f";
export var h2Bold = "styles-module--h2--bold--da185";
export var h2Uc = "styles-module--h2--uc--9f0e6";
export var h3 = "styles-module--h3--b1706";
export var h3Bold = "styles-module--h3--bold--4b465";
export var h3Uc = "styles-module--h3--uc--249ee";
export var h4 = "styles-module--h4--f8884";
export var h4Bold = "styles-module--h4--bold--f74b0";
export var h4Uc = "styles-module--h4--uc--1b3b0";
export var linkUnderline = "styles-module--link--underline--9aa11";
export var microtext = "styles-module--microtext--0e8be";
export var microtextBold = "styles-module--microtext--bold--1c747";
export var microtextUc = "styles-module--microtext--uc--3c2e8";
export var product_title = "styles-module--product_title--5bc5d";
export var selected_product = "styles-module--selected_product--8e9ba";
export var selected_product_container = "styles-module--selected_product_container--e9292";
export var strikethrough = "styles-module--strikethrough--4ae0a";
export var strikethroughLg = "styles-module--strikethrough--lg--cb9b3";
export var strikethroughSm = "styles-module--strikethrough--sm--c7bf3";
export var subtext = "styles-module--subtext--c46b1";
export var subtextBold = "styles-module--subtext--bold--8b893";
export var subtextUc = "styles-module--subtext--uc--482be";
export var text = "styles-module--text--186ec";
export var textBold = "styles-module--text--bold--5508e";
export var textUc = "styles-module--text--uc--b692c";
export var titleL = "styles-module--title-l--5ffab";
export var titleM = "styles-module--title-m--d6ee0";
export var titleS = "styles-module--title-s--f7cfd";
export var titleXl = "styles-module--title-xl--7700d";
export var titleXs = "styles-module--title-xs--49dc1";
export var titleXxs = "styles-module--title-xxs--a350e";
export var uc = "styles-module--uc--dfe6f";
export var underline = "styles-module--underline--1c945";