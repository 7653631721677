import { connectStats } from "react-instantsearch-dom";
import { PropTypes } from "prop-types";

function CustomHitCount({ nbHits }) {
  return <span>{nbHits}</span>;
}

CustomHitCount.propTypes = {
  nbHits: PropTypes.number,
};
export default connectStats(CustomHitCount);
