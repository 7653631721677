import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { ProgressBar } from "../ALComponents";
import { useCurrentFormatCurrency } from "../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { roundNumber } from "../../context/helpers";

import GiftIcon from "../icons/svgs/gift.svg";
import ShippingIcon from "../icons/svgs/shipping.svg";

import * as Styles from "./giftWithPurchaseProgressBar/styles.module.scss";

function GiftWithPurchaseProgressBar({ value, giftData, shippingThreshold }) {
  const formatCurrency = useCurrentFormatCurrency();
  const dict = useLocalizedSentenceDict();

  const steps = [
    { value: 0, label: formatCurrency(0) },
    {
      value: shippingThreshold,
      label: formatCurrency(shippingThreshold),
      icon: <ShippingIcon fill="#000" width="15" />,
    },
    {
      value: giftData.minAmount,
      label: formatCurrency(giftData.minAmount),
      icon: <GiftIcon width="15" height="17" fill="none" stroke="#2D2927" />,
    },
  ];

  // Do not show the "Spend **{0}** more for **FREE earrings!**" message right after reaching
  // the free shipping threshold, but set an intermediate threshold so it does not feel pushy
  const freeShippingMessageThreshold =
    shippingThreshold + (giftData.minAmount - shippingThreshold) / 4;

  // Initial state with an empty cart
  let title = (
    <>
      {dict.get("FREE shipping on orders of {0}+", formatCurrency(shippingThreshold))}
      <br />
      <div className="al_subtext--bold">
        {dict.get(`FREE Earrings with orders of {0}+`, formatCurrency(giftData.minAmount))}
      </div>
    </>
  );

  // State with the free gift enabled
  if (value >= giftData.minAmount) {
    title = <>{dict.get("Yay! You earned **FREE earrings!**")}</>;
  }

  // State for free shipping enabled, and under the gift threshold
  else if (value >= freeShippingMessageThreshold) {
    title = (
      <>
        {dict.get(
          "Spend **{0}** more for **FREE earrings!**",
          formatCurrency(roundNumber(giftData.minAmount - value))
        )}
      </>
    );
  }

  // State for when free shipping is enabled, and under the "pushy" message margin
  else if (value >= shippingThreshold) {
    title = <>{dict.get("Yay! You unlocked **FREE shipping!**")}</>;
  }

  // State before enabling free shipping
  else if (value > 0 && value < shippingThreshold) {
    title = (
      <>
        {dict.get(
          `Spend **{0}** more for **FREE shipping!**`,
          formatCurrency(roundNumber(shippingThreshold - value))
        )}
      </>
    );
  }

  return (
    <div className={Styles.container}>
      <div className="al_subtext">{title}</div>
      {value > 0 && <ProgressBar value={value} maxValue={giftData.minAmount} steps={steps} />}
    </div>
  );
}

GiftWithPurchaseProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  giftData: PropTypes.object,
  shippingThreshold: PropTypes.number,
};

export default withALErrorBoundary({
  name: "GiftWithPurchaseProgressBar",
  priority: "P2",
})(GiftWithPurchaseProgressBar);
