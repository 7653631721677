const DISCOUNT_TYPES = {
  SITEWIDE_PERCENT: "site_wide_percent_off",
  QUANTITY_PERCENT: "quantity_based_percent_off",
  BOGO: "buy_one_get_one",
  AMOUNT_PERCENT: "amount_based_percent_off",
  AMOUNT_VALUE: "amount_based_dollar_off",
  NO_DISCOUNT: "no_discount",
};

export default DISCOUNT_TYPES;
