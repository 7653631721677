import { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import ALButton from "./ALButton";
import IconCircleRound from "../icons/svgs/arrow-circle-lg.svg";
import IconArrow from "../icons/svgs/arrow.svg";

import "./alRadioNavigation/styles.scss";

function ALRadioNavigation({ elements, onClick, selectedKey, isExpandable, className }) {
  const { isMobile } = useDeviceDetect();
  const [alignedTo, setAlignedTo] = useState(null);
  const elListRef = useRef(null);

  const onNavigationClick = useCallback(
    (key) => {
      if (isExpandable && selectedKey === key) {
        onClick(null);
      } else {
        onClick(key);
      }
    },
    [onClick, isExpandable, selectedKey]
  );

  useEffect(() => {
    if (elListRef.current && !isMobile) {
      const parentWidth = elListRef.current.parentNode.clientWidth || 0;
      const elWidth = elListRef.current.scrollWidth || 0;

      if (elWidth > parentWidth) {
        setAlignedTo("left");
      }
    }
  }, [isMobile]);

  const toggleTabs = () => {
    if (elListRef.current) {
      if (alignedTo === "left") {
        elListRef.current.scrollTo({
          left: elListRef.current.scrollWidth - elListRef.current.clientWidth,
          behavior: "smooth",
        });
        setAlignedTo("right");
      } else {
        elListRef.current.scrollTo({
          left: 0,
          behavior: "smooth",
        });
        setAlignedTo("left");
      }
    }
  };

  if (!elements || elements.length === 0) {
    return null;
  }

  return (
    <div className={`al-radio-navigation ${className || ""}`}>
      {alignedTo === "right" && (
        <IconCircleRound
          stroke="#0b0f0e"
          className="al-radio-navigation-back"
          onClick={toggleTabs}
          data-testid="back-navigation"
        />
      )}
      <div className="al-radio-navigation" ref={elListRef}>
        {elements.map((element) => {
          const isSelected = selectedKey === element.key;
          return (
            <ALButton
              variant={isSelected ? "black" : "white"}
              onClick={() => onNavigationClick(element.key)}
              key={element.key}
            >
              <span>{element.content}</span>
              {isExpandable && (
                <IconArrow
                  className={`al-radio-navigation-arrow ${
                    isSelected ? "al-radio-navigation-arrow--active" : ""
                  }`}
                  fill="#2D2927"
                  height="13"
                />
              )}
            </ALButton>
          );
        })}
      </div>
      {alignedTo === "left" && (
        <IconCircleRound
          stroke="#0b0f0e"
          className="al-radio-navigation-forward"
          onClick={toggleTabs}
          data-testid="forward-navigation"
        />
      )}
    </div>
  );
}

ALRadioNavigation.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      content: PropTypes.node,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedKey: PropTypes.string,
  isExpandable: PropTypes.bool,
};

export default ALRadioNavigation;
