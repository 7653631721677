import { useEffect, useState } from "react";
import { isBrowser } from "../context/helpers";
import useIsHydrationComplete from "./useIsHydrationComplete";

const isMediaQuerySupported = isBrowser && window.matchMedia;

const useMediaQuery = (mediaQuery) => {
  const [matched, setMatched] = useState(
    isMediaQuerySupported && window.matchMedia(mediaQuery).matches
  );

  useEffect(() => {
    if (isMediaQuerySupported) {
      const mediaQueryList = window.matchMedia(mediaQuery);
      const updateMatched = (e) => setMatched(e.matches);

      try {
        mediaQueryList.addEventListener("change", updateMatched);
      } catch (e) {
        // Safari doesn't support mediaQueryList.addEventListener
        mediaQueryList.addListener(updateMatched);
      }

      setMatched(mediaQueryList.matches);
      return () => {
        try {
          mediaQueryList.removeEventListener("change", updateMatched);
        } catch (e) {
          // Safari doesn't support mediaQueryList.removeEventListener
          mediaQueryList.removeListener(updateMatched);
        }
      };
    }
  }, [mediaQuery]);

  return matched;
};

export default function useDeviceDetect() {
  const isMobile = useMediaQuery("only screen and (max-width: 768px)");
  const isHydrationDone = useIsHydrationComplete();
  return { isMobile: !isHydrationDone || isMobile };
}
