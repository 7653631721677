// extracted by mini-css-extract-plugin
export var awesome = "TierDiscountBottomBanner-module--awesome--e2be0";
export var bold = "TierDiscountBottomBanner-module--bold--676df";
export var center = "TierDiscountBottomBanner-module--center--6c027";
export var freeShipping = "TierDiscountBottomBanner-module--free-shipping--b78b7";
export var h1 = "TierDiscountBottomBanner-module--h1--c5a54";
export var h1Bold = "TierDiscountBottomBanner-module--h1--bold--2771a";
export var h1Uc = "TierDiscountBottomBanner-module--h1--uc--d68ce";
export var h2 = "TierDiscountBottomBanner-module--h2--18582";
export var h2Bold = "TierDiscountBottomBanner-module--h2--bold--f0def";
export var h2Uc = "TierDiscountBottomBanner-module--h2--uc--775e6";
export var h3 = "TierDiscountBottomBanner-module--h3--e71fe";
export var h3Bold = "TierDiscountBottomBanner-module--h3--bold--00dd0";
export var h3Uc = "TierDiscountBottomBanner-module--h3--uc--8771e";
export var h4 = "TierDiscountBottomBanner-module--h4--f72fd";
export var h4Bold = "TierDiscountBottomBanner-module--h4--bold--c9f6c";
export var h4Uc = "TierDiscountBottomBanner-module--h4--uc--b494e";
export var linkUnderline = "TierDiscountBottomBanner-module--link--underline--32a56";
export var luxeActive = "TierDiscountBottomBanner-module--luxe-active--403b4";
export var microtext = "TierDiscountBottomBanner-module--microtext--c2bf2";
export var microtextBold = "TierDiscountBottomBanner-module--microtext--bold--936ff";
export var microtextUc = "TierDiscountBottomBanner-module--microtext--uc--e579a";
export var strikethrough = "TierDiscountBottomBanner-module--strikethrough--ce72f";
export var strikethroughLg = "TierDiscountBottomBanner-module--strikethrough--lg--fb6cd";
export var strikethroughSm = "TierDiscountBottomBanner-module--strikethrough--sm--52366";
export var subtext = "TierDiscountBottomBanner-module--subtext--a7cc5";
export var subtextBold = "TierDiscountBottomBanner-module--subtext--bold--24b06";
export var subtextUc = "TierDiscountBottomBanner-module--subtext--uc--d4498";
export var text = "TierDiscountBottomBanner-module--text--d6292";
export var textBold = "TierDiscountBottomBanner-module--text--bold--49de3";
export var textUc = "TierDiscountBottomBanner-module--text--uc--3d1dd";
export var tierDiscountBottomBanner = "TierDiscountBottomBanner-module--tier-discount-bottom-banner--fbc8c";
export var tierDiscountBottomBannerHidden = "TierDiscountBottomBanner-module--tier-discount-bottom-banner--hidden--68fd5";
export var tierDiscountBottomBanner__content = "TierDiscountBottomBanner-module--tier-discount-bottom-banner__content--a3ccc";
export var tierDiscountBottomBanner__content__fakeFiltersColumn = "TierDiscountBottomBanner-module--tier-discount-bottom-banner__content__fake-filters-column--3402f";
export var tierDiscountBottomBanner__content__wrapper = "TierDiscountBottomBanner-module--tier-discount-bottom-banner__content__wrapper--4e41d";
export var timerDiscount = "TierDiscountBottomBanner-module--timer-discount--0ed31";
export var timerShipping = "TierDiscountBottomBanner-module--timer-shipping--04b7b";
export var timerTitle = "TierDiscountBottomBanner-module--timer-title--95596";
export var titleL = "TierDiscountBottomBanner-module--title-l--d8f4a";
export var titleM = "TierDiscountBottomBanner-module--title-m--1ec57";
export var titleS = "TierDiscountBottomBanner-module--title-s--95c21";
export var titleXl = "TierDiscountBottomBanner-module--title-xl--36bdb";
export var titleXs = "TierDiscountBottomBanner-module--title-xs--8e5c5";
export var titleXxs = "TierDiscountBottomBanner-module--title-xxs--fef5e";
export var uc = "TierDiscountBottomBanner-module--uc--8eba0";
export var underline = "TierDiscountBottomBanner-module--underline--1aab3";