import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import { ALButton } from "../ALComponents";

import IconClose from "../icons/svgs/close-round.svg";
import "./accountPopup.scss";

function Confirmation({ text, actionLabel, onAction = () => {}, onClose }) {
  return (
    <>
      <button type="button" className="close-icon" onClick={onClose}>
        <IconClose width="30" fill="none" stroke="#2D2927" />
      </button>
      <h1 className="confirmation-title">{text}</h1>
      {actionLabel && (
        <ALButton
          variant="primary"
          size="large"
          fullWidth
          onClick={onAction}
          className="confirmation-btn"
        >
          {actionLabel}
        </ALButton>
      )}
    </>
  );
}

Confirmation.propTypes = {
  text: PropTypes.string,
  actionLabel: PropTypes.string,
  onAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default withALErrorBoundary({
  name: "Confirmation",
  priority: "P1",
})(Confirmation);
