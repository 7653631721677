import PropTypes from "prop-types";
import PaymentOptionImage from "../PaymentOptionImage";
import { PaymentOption } from "../../constants/PaymentOption";
import data from "../../data/localized_payment_options.json";

function ALPaymentMethod({ locale, className }) {
  const hasOwnPaymentOptions = data.data.hasOwnProperty(locale);
  let paymentOptions;
  if (hasOwnPaymentOptions) {
    const currentLocalePaymentOptions = data.data[locale];
    paymentOptions = currentLocalePaymentOptions.map((paymentOption, index) => (
      <PaymentOptionImage payment={paymentOption} key={index} />
    ));
  }
  return hasOwnPaymentOptions ? (
    <div className={`${className || ""} al_paymentmethod_container`}>{paymentOptions}</div>
  ) : (
    <div className={`${className || ""} al_paymentmethod_container`}>
      <PaymentOptionImage payment={PaymentOption.VISA} />
      <PaymentOptionImage payment={PaymentOption.AMAZON_PAY} />
      <PaymentOptionImage payment={PaymentOption.PAYPAL} />
      <PaymentOptionImage payment={PaymentOption.MASTERCARD} />
      <PaymentOptionImage payment={PaymentOption.DISCOVER} />
      <PaymentOptionImage payment={PaymentOption.AMERICAN_EXPRESS} />
    </div>
  );
}

ALPaymentMethod.propTypes = {
  locale: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ALPaymentMethod;
