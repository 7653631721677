import { useState } from "react";
import { isBrowser, validateEmail } from "../../context/helpers";
import { useALError } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import IconArrowRound from "../icons/svgs/arrow-circle-lg.svg";

function CaptureEmail() {
  const { sendReport } = useALError();
  const [userEmail, updateUserEmail] = useState(null);
  const [errorEmail, updateErrorEmail] = useState(null);
  const [hasSubscribe, updateSubscribe] = useState(false);
  const dict = useLocalizedSentenceDict();

  const addCustomer = () => {
    if (isBrowser) {
      if (userEmail !== null) {
        const isValid = validateEmail(userEmail);
        if (isValid) {
          try {
            window.exponea.identify(
              {
                email_id: userEmail,
              },
              {
                Email: userEmail,
              }
            );

            try {
              window.edgetag("user", "email", userEmail);
              window.edgetag("tag", "Lead", {
                name: "Subscribe Footer",
              });
            } catch (error) {
              sendReport(error, { name: "CaptureEmail", priority: "P2" });
            }

            /* eslint-disable no-undef */
            exponea.track("subscribe_footer");

            updateSubscribe(true);
          } catch (error) {
            console.log("exponea_footer");
            console.log(error);
          }
        } else {
          updateErrorEmail(dict.get("Please enter a valid email address."));
        }
      } else {
        updateErrorEmail(dict.get("Please enter an email address"));
      }
    }
  };

  return (
    <div className="signup">
      <p className="text text--uc text--bold mb-15">{dict.get("Be the first to know")}!</p>
      <p className="text">
        {dict.get("Sign up to receive insider information on exclusive offers and new arrivals")}.
      </p>
      <div className="signup_form">
        {!hasSubscribe ? (
          <>
            <input
              className="signup_form_input subtext"
              type="text"
              placeholder={dict.get("Enter email")}
              aria-label={dict.get(
                "Enter email to receive insider information on exclusive offers and new arrivals"
              )}
              onChange={(e) => {
                if (errorEmail !== null) {
                  updateErrorEmail(null);
                }
                updateUserEmail(e.target.value);
              }}
            />
            <button
              type="submit"
              aria-label="Submit"
              className="signup_form_button"
              onClick={addCustomer}
            >
              <IconArrowRound fill="#FFF" width="45" stroke="#2D2927" />
            </button>
            {errorEmail ? <p className="signup_form_error al_p">{errorEmail}</p> : null}
          </>
        ) : (
          <p className="al_p">{dict.get("Thank you!")}</p>
        )}
      </div>
    </div>
  );
}

export default CaptureEmail;
