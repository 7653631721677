import { useContext, useEffect, useState } from "react";
import { useAnnouncementBar } from "./useAnnouncementBar";
import useDeviceDetect from "./useDeviceDetect";
import StoreContext from "../context/store";

export function useSticky(isHeader = false, topOffset = "0px") {
  const isMobile = useDeviceDetect();
  const { isSticky } = useAnnouncementBar();
  const [offset, setOffset] = useState();
  const [abHeight, setAbHeight] = useState();
  const [headerHeight, setHeaderHeight] = useState();
  const { displayAB } = useContext(StoreContext);

  useEffect(() => {
    setAbHeight(displayAB ? document?.querySelector(".ab_container")?.offsetHeight || 0 : 0);
    setHeaderHeight(document?.querySelector("header")?.offsetHeight);
  }, [isMobile, displayAB]);
  useEffect(() => {
    let style = { position: "sticky" };
    if (isSticky) {
      if (isHeader) {
        style = { ...style, top: `${abHeight}px` };
      } else {
        style = {
          ...style,
          top: `calc(${headerHeight}px + ${abHeight}px + ${topOffset})`,
        };
      }
    } else if (isHeader) {
      style = { ...style, top: `0px` };
    } else {
      style = { ...style, top: `calc(${headerHeight}px + ${topOffset}` };
    }
    setOffset(style);
  }, [abHeight, headerHeight]);
  return offset;
}
