import { useCallback, useState } from "react";
import { useLocalizedSentenceDict } from "../../../../hooks/useSentenceDict";
import QualityPopup from "./contentQuality/QualityPopup";

export default function ContentQuality() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dict = useLocalizedSentenceDict();

  const handlePopupClose = useCallback(() => setIsPopupOpen(false), []);
  const handlePopupOpen = useCallback(() => setIsPopupOpen(true), []);

  return (
    <>
      <p className="subtext subtext--bold">
        {dict.get("Ana Luisa offers a 2-Year Warranty on all products.")}
      </p>
      <br />
      <p className="subtext">
        {dict.get(
          "Every single piece of jewelry is rigorously tested and guaranteed to be long lasting, hypoallergenic and water resistant."
        )}
      </p>
      <br />
      <p className="subtext">
        {dict.get(
          "If for any reason you are not satisfied with your purchase, our 2 year warranty has you covered. No risk, all rewards."
        )}
      </p>
      <button
        type="button"
        className="subtext subtext--bold al_button_link"
        onClick={handlePopupOpen}
      >
        {dict.get("Read more")}
      </button>

      {isPopupOpen && <QualityPopup onClose={handlePopupClose} />}
    </>
  );
}
