// extracted by mini-css-extract-plugin
export var awesome = "countryConfirmationPopup-module--awesome--a07d7";
export var bold = "countryConfirmationPopup-module--bold--c5bf8";
export var buttonClose = "countryConfirmationPopup-module--button-close--9efa3";
export var center = "countryConfirmationPopup-module--center--73a30";
export var description = "countryConfirmationPopup-module--description--5aaf9";
export var h1 = "countryConfirmationPopup-module--h1--eb312";
export var h1Bold = "countryConfirmationPopup-module--h1--bold--95e20";
export var h1Uc = "countryConfirmationPopup-module--h1--uc--a3856";
export var h2 = "countryConfirmationPopup-module--h2--89e45";
export var h2Bold = "countryConfirmationPopup-module--h2--bold--cfc23";
export var h2Uc = "countryConfirmationPopup-module--h2--uc--603cb";
export var h3 = "countryConfirmationPopup-module--h3--1ce96";
export var h3Bold = "countryConfirmationPopup-module--h3--bold--c3bbb";
export var h3Uc = "countryConfirmationPopup-module--h3--uc--b251c";
export var h4 = "countryConfirmationPopup-module--h4--9aedc";
export var h4Bold = "countryConfirmationPopup-module--h4--bold--4b7f7";
export var h4Uc = "countryConfirmationPopup-module--h4--uc--2fbb6";
export var linkUnderline = "countryConfirmationPopup-module--link--underline--dbfba";
export var microtext = "countryConfirmationPopup-module--microtext--a43d1";
export var microtextBold = "countryConfirmationPopup-module--microtext--bold--f5264";
export var microtextUc = "countryConfirmationPopup-module--microtext--uc--5474f";
export var selectCountryForm = "countryConfirmationPopup-module--select-country-form--26cc0";
export var selectCountryForm__label = "countryConfirmationPopup-module--select-country-form__label--4a047";
export var selectCountryForm__select = "countryConfirmationPopup-module--select-country-form__select--56003";
export var selectCountryForm__selectArrow = "countryConfirmationPopup-module--select-country-form__select-arrow--66a70";
export var selectCountryForm__selectContainer = "countryConfirmationPopup-module--select-country-form__select-container--6144c";
export var selectCountryForm__submit = "countryConfirmationPopup-module--select-country-form__submit--ff80d";
export var strikethrough = "countryConfirmationPopup-module--strikethrough--11ffd";
export var strikethroughLg = "countryConfirmationPopup-module--strikethrough--lg--be511";
export var strikethroughSm = "countryConfirmationPopup-module--strikethrough--sm--4f040";
export var subtext = "countryConfirmationPopup-module--subtext--521b1";
export var subtextBold = "countryConfirmationPopup-module--subtext--bold--304c4";
export var subtextUc = "countryConfirmationPopup-module--subtext--uc--db21b";
export var text = "countryConfirmationPopup-module--text--c4cd8";
export var textBold = "countryConfirmationPopup-module--text--bold--080e0";
export var textUc = "countryConfirmationPopup-module--text--uc--7b687";
export var title = "countryConfirmationPopup-module--title--20bba";
export var titleL = "countryConfirmationPopup-module--title-l--40bb7";
export var titleM = "countryConfirmationPopup-module--title-m--28f10";
export var titleS = "countryConfirmationPopup-module--title-s--4ef74";
export var titleXl = "countryConfirmationPopup-module--title-xl--25efa";
export var titleXs = "countryConfirmationPopup-module--title-xs--5e62a";
export var titleXxs = "countryConfirmationPopup-module--title-xxs--169ac";
export var uc = "countryConfirmationPopup-module--uc--58f3a";
export var underline = "countryConfirmationPopup-module--underline--fc4c9";