import { useContext, useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import { BYOBContext } from "../../context/BYOBundle";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import {
  fRound,
  resizeShopifyImage,
  getItemCipheredAttributeNumberValue,
} from "../../context/helpers";
import { splitMultipleQuantityItem } from "../../context/cartHelpers";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { Link } from "../al_components/ALLink";
import ItemPrice from "./ItemPrice";
import IconTrash from "../icons/svgs/trash.svg";
import ALButton from "../al_components/ALButton";

import {
  BYOB_DEFAULT_SIZE,
  BYOB_DEFAULT_PERCENTAGE_OFF,
  BYOB_PERCENTAGE_OFF_KEY,
  BYOB_CAN_HAVE_OPTIONAL_ITEM,
} from "../../constants/BYOBConfig";

import "./byobItems/styles.scss";

const OPTIONAL_ITEM_EXTRA_PERCENT_OFF = 5;

function BYOBItems({ productList, onRemove, onEdit, onOptionalItemAdd }) {
  const [products, setProducts] = useState(productList);

  const [fullPriceInCents, setFullPriceInCents] = useState(0);
  const [finalPriceInCents, setFinalPriceInCents] = useState(0);
  const context = useContext(BYOBContext);
  const { byobSize } = context;
  const dict = useLocalizedSentenceDict();

  useEffect(() => {
    let productsCopy = productList;
    if (productsCopy.length < byobSize) {
      productsCopy = splitMultipleQuantityItem(productsCopy);
    }

    setFullPriceInCents(
      productsCopy.reduce((acc, product) => {
        const price = product.variant.full_price;
        return fRound(acc + price * 100);
      }, 0)
    );
    setFinalPriceInCents(
      productsCopy.reduce((acc, product) => {
        const price = product.variant.final_price;
        return fRound(acc + price * 100);
      }, 0)
    );

    setProducts(productsCopy);
  }, [byobSize, productList]);

  const isFinalSale = (product) => {
    if (product.attributes) {
      // Might want to revert back + add final_sale to custom attribute
      // return !!product.customAttributes.find(
      //   (atr) => atr.key === "final_sale" && atr.value === "true"
      // );
      return !!product?.variant?.product?.tags.find((t) => t === "Final Sale");
    }

    return false;
  };

  const byobPercentageOff = getItemCipheredAttributeNumberValue(
    productList[0],
    BYOB_PERCENTAGE_OFF_KEY,
    BYOB_DEFAULT_PERCENTAGE_OFF
  );

  const canHaveOptionalItem = productList[0].attributes.find(
    ({ key: attrKey }) => attrKey === BYOB_CAN_HAVE_OPTIONAL_ITEM
  )?.value;

  const hasVariants = (product) =>
    product.variant !== null &&
    !("selectedOptions" in product.variant) &&
    product.variant.title !== "Default Title";

  const isDiscounted = (product) => !!product.bogoApplied;

  return (
    <div className="byob-items">
      {products.map((product, index) => (
        <div
          className={`cart_item byob-item ${index === products.length - 1 ? "last_product" : ""}`}
          key={`byob-${product.id}-${index}`}
        >
          <div className="cart_item_image">
            <Link to={`/products/${product.variant.product.handle}`}>
              <img
                src={resizeShopifyImage(product.variant.image.url, "x200")}
                alt={product.variant.image.altText}
              />
            </Link>
          </div>
          <div className="cart_item_content byob-item__content">
            <div className="cart_item_content_infos">
              <div className="cart_item_content_title">
                <p className="cart_item_title">{product.variant.product.title.split("-")[1]}</p>
                <p className="cart_item_bundle_index">
                  {dict.get("Bundle item")} {`${index + 1}/${products.length}`}
                </p>

                {isDiscounted(product) && (
                  <div className="cart_item_finalsale">{dict.get("Discount applied")}</div>
                )}

                {isFinalSale(product) && (
                  <p className="cart_item_finalsale">{dict.get("Final sale")}</p>
                )}

                {hasVariants(product) && (
                  <p className="cart_item_subtitle">
                    {dict.get("Size {0}", product.variant.title)}
                  </p>
                )}
              </div>
              {index + 1 === 1 && (
                <ItemPrice
                  variant={{
                    final_price: finalPriceInCents / 100,
                    full_price: fullPriceInCents / 100,
                  }}
                />
              )}
            </div>
            {index + 1 === products.length && (
              <div className="byob-items__buttons">
                <button type="button" onClick={() => onEdit(products)}>
                  {dict.get("Edit")}
                </button>
                <button type="button" onClick={onRemove}>
                  <IconTrash width="15" height="21" fill="none" stroke="#2D2927" />
                </button>
              </div>
            )}
          </div>
        </div>
      ))}

      {canHaveOptionalItem === "true" && products.length === BYOB_DEFAULT_SIZE && (
        <ALButton variant="secondary" className="byob-items__add_item" onClick={onOptionalItemAdd}>
          <span>
            {dict.get(
              `Add a 4th item for ${
                byobPercentageOff + OPTIONAL_ITEM_EXTRA_PERCENT_OFF
              }% off your bundle`
            )}
          </span>
        </ALButton>
      )}
    </div>
  );
}

BYOBItems.propTypes = {
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onOptionalItemAdd: PropTypes.func,
  productList: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    })
  ),
};

export default withALErrorBoundary({
  name: "BYOBItems",
  priority: "P3",
})(BYOBItems);
