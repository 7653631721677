import { useLocalizedSentenceDict } from "../../../../hooks/useSentenceDict";

export default function HowItWorks() {
  const dict = useLocalizedSentenceDict();

  return (
    <>
      <p className="subtext">
        {dict.get(
          "Minimum of two mystery items or addition of another item required for checkout."
        )}
      </p>
      <br />
      <p className="subtext">
        {dict.get(
          "Products pictured are representative of mystery item(s) and may not be what you receive."
        )}
      </p>
      <br />
      <p className="subtext">
        {dict.get(
          "Duplicates of the same mystery item may occur when ordering more than one of the same product type. We are unable to accommodate requests for specific designs."
        )}
      </p>
      <br />
      <p className="subtext">{dict.get("Final sale and excluded from sitewide promotions.")}</p>
    </>
  );
}
