module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-source-multi-shopify-al/gatsby-browser.js'),
      options: {"plugins":[],"stores":[{"name":"analuisaparis","adminPassword":"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","accessToken":"eef57704fddc2b6fd6b8088973de2c2f"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-shopify-theme","short_name":"gatsby-shopify","start_url":"/","background_color":"#333","theme_color":"#333","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"179e38a4425b8c666368529cc9ac62b5"},
    },{
      plugin: require('../plugins/gatsby-plugin-kustomer-al/gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-hotjar-al/gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-justuno-al/gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-pinterest-al/gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W4FGB3X","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
