import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import localeFlag from "../commons/LocaleFlag";
import { ALFlags } from "../ALComponents";
import "./localePicker.scss";

function LocalePickerContent({ locale, handleClick }) {
  return (
    <div className="locale_picker__content">
      <button
        className="locale_picker__content-first-line"
        onClick={() => handleClick(false, locale.countryName, locale.locale)}
        type="button"
      >
        {localeFlag[locale.country] && <ALFlags countryISO={locale.country} />}
        <p className="locale_picker__content-name al_p">{locale.countryName} | English</p>
      </button>
      <p className="locale_picker__content-currency al_p">{locale.currency}</p>
    </div>
  );
}

LocalePickerContent.propTypes = {
  locale: PropTypes.shape({
    country: PropTypes.string,
    countryName: PropTypes.string,
    currency: PropTypes.string,
    locale: PropTypes.string,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default withALErrorBoundary({
  name: "LocalePickerContent",
  priority: "P3",
})(LocalePickerContent);
