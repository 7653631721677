import { isBrowser } from "../helpers";

export function bingViewCart({ cart, currency }) {
  if (isBrowser) {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "view_cart", {
      revenue_value: cart?.finalAmount.toString(),
      currency,
    });
  }
}

export function bingSendAddToCart({ productId, currency, price, quantity }) {
  if (isBrowser) {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "add_to_cart", {
      ecomm_prodid: [productId],
      ecomm_pagetype: "product",
      ecomm_totalvalue: parseFloat(price),
      revenue_value: price,
      currency,
      items: [
        {
          id: productId,
          quantity,
          price,
        },
      ],
    });
  }
}

export function bingViewProduct({ product }) {
  if (isBrowser) {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "pdp_view", {
      ecomm_prodid: [product?.id.replace("gid://shopify/Product/", "")],
      ecomm_pagetype: "product",
    });
  }
}

export function bingViewCollection({ collectionId, products }) {
  if (isBrowser) {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "plp_view", {
      ecomm_category: collectionId,
      ecomm_prodid: products.map((p) => p.id.replace("gid://shopify/Product/", "")),
      ecomm_pagetype: "category",
    });
  }
}
