import { Script } from "gatsby";

const kustomerScriptTwo = () => ({
  __html: `
  var script = document.createElement('script');
  script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
  script.setAttribute('data-kustomer-api-key', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMzdlMGIyMTAzNjFjMDA4ZWQ1Mzk0ZCIsInVzZXIiOiI2MTM3ZTBiMjBiMDAyYjAwMWJlYWYxYzciLCJvcmciOiI2MDU4ZTg2MDhmNjViOGZhY2VhMDg1YjMiLCJvcmdOYW1lIjoiYW5hbHVpc2EiLCJ1c2VyVHlwZSI6Im1hY2hpbmUiLCJwb2QiOiJwcm9kMSIsInJvbGVzIjpbIm9yZy50cmFja2luZyJdLCJhdWQiOiJ1cm46Y29uc3VtZXIiLCJpc3MiOiJ1cm46YXBpIiwic3ViIjoiNjEzN2UwYjIwYjAwMmIwMDFiZWFmMWM3In0.TTQHcPanB9YLk1Mmir6ARQm4g1qgzpNsF1-wwWVn3Eo');
  window.document.body.appendChild(script);
  `,
});

export function wrapPageElement({ element }) {
  return (
    <>
      {element}
      <Script id="kustomer-id" dangerouslySetInnerHTML={kustomerScriptTwo()} strategy="idle" />
    </>
  );
}
