// Partial strings checked with `includes()` also known as token
const ROBOTS_USER_AGENTS = [
  // Google
  "Googlebot",
  "Google Favicon",
  "Storebot-Google",
  "Google-InspectionTool",
  "GoogleOther",
  "APIs-Google",
  "AdsBot-Google",
  "Mediapartners-Google",
  "HeadlessChrome",
];

export default ROBOTS_USER_AGENTS;
