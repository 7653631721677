import { useCallback, useState, createContext } from "react";

import { useTracking } from "./Tracking";
import IconClose from "../components/icons/svgs/close.svg";
import ProductDetailsContent from "../components/ProductDetailsContent";
import { Popup } from "../components/Popup";
import { ComponentType } from "../constants/ComponentType";

const defaultBYOBProductDetailPopupContext = {
  product: {},
  turnOnPopup: () => {},
  turnOffPopup: () => {},
};

export const BYOBProductDetailPopupContext = createContext(defaultBYOBProductDetailPopupContext);

export function BYOBProductDetailPopupProvider({ children }) {
  const { trackViewProductDetailsOnByob } = useTracking();
  const [popupProductHandle, setPopupProductHandle] = useState("");
  const turnOnPopup = useCallback(
    (product) => {
      setPopupProductHandle(product.handle);
      trackViewProductDetailsOnByob({
        handle: product.handle,
        id: product.id,
        title: product.title,
        price: product.finalPrice,
        category: product.productType,
      });
    },
    [setPopupProductHandle]
  );
  const turnOffPopup = useCallback(() => {
    setPopupProductHandle("");
  }, [setPopupProductHandle]);
  return (
    <BYOBProductDetailPopupContext.Provider
      value={{ turnOnPopup, turnOffPopup, handle: popupProductHandle }}
    >
      <Popup
        open={!!popupProductHandle}
        closeOnDocumentClick
        onClose={turnOffPopup}
        className="byob_pdp_popup"
        modal
        nested
      >
        {!!popupProductHandle && (
          <ProductDetailsContent
            productHandle={popupProductHandle}
            componentType={ComponentType.BYOB_PDP}
          />
        )}
        <button className="close_popup" onClick={turnOffPopup}>
          <IconClose width="30" fill="#2D2927" />
        </button>
      </Popup>
      {children}
    </BYOBProductDetailPopupContext.Provider>
  );
}
