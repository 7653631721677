import { PropTypes } from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ALLink as Link } from "../../../ALComponents";

function MobileCard({ card, closeMenu }) {
  if (!card) {
    return null;
  }

  const hasLink = card?.image?.link?.title !== "empty";

  return hasLink ? (
    <Link
      to={card.image.link.link.link}
      onClick={closeMenu}
      className="mobile_menu_container_link mobile_menu_container_link_card"
    >
      <GatsbyImage
        image={getImage(card.image.mobileImage.gatsbyImageData)}
        alt={card.image.altText}
      />
    </Link>
  ) : (
    <GatsbyImage
      image={getImage(card.image.mobileImage.gatsbyImageData)}
      alt={card.image.altText}
      className="mobile_menu_container_link mobile_menu_container_link_card"
    />
  );
}

MobileCard.propTypes = {
  card: PropTypes.shape({
    image: PropTypes.shape({
      altText: PropTypes.string,
      mobileImage: PropTypes.shape({
        gatsbyImageData: PropTypes.any,
      }),
      link: PropTypes.shape({
        title: PropTypes.string,
        link: PropTypes.shape({
          link: PropTypes.string,
        }),
      }),
    }),
  }),
  closeMenu: PropTypes.func,
};

export default MobileCard;
