import { useContext } from "react";

import { GeoContext } from "../context/GeoProvider";
import useIgPrices from "../hooks/useIgPrices";

import { formatPriceAmount } from "../hooks/usePrices";
import { roundNumber } from "../context/helpers";

export function applyIgPrices(productId, selectedVariant, prices) {
  const { gePriceDetails } = useContext(GeoContext);

  const igPrices = useIgPrices({
    productId,
    variantId: selectedVariant?.id,
    originalPrice: prices?.final_price || prices?.full_price,
    originalCompareAtPrice: prices?.compareAtPrice,
    currencyCode: gePriceDetails?.CurrencyCode,
  });

  // Use intelligems prices if they are available, recalculate discount if so
  // otherwise use the prices usePriceInfo prices
  if (
    igPrices &&
    igPrices.isIgPrice &&
    igPrices.isReady &&
    igPrices.igCompareAtPrice &&
    igPrices.igPrice
  ) {
    const fullPrice = igPrices.igCompareAtPrice.value || igPrices.igPrice.value;
    const finalPrice = igPrices.igPrice.value;
    return {
      isIgPrice: true,
      compareAtPrice: igPrices.igCompareAtPrice.value,
      fullPrice,
      finalPrice,
      formattedDiscount: formatPriceAmount(
        fullPrice - finalPrice,
        igPrices.igPrice.currencyCode,
        gePriceDetails?.CountryCode
      ),
      discountAmount: fullPrice - finalPrice,
      hasDiscount: igPrices.igCompareAtPrice.value - igPrices.igPrice.value > 0,
      discountGiven: igPrices.igCompareAtPrice.value
        ? roundNumber(
            ((igPrices.igCompareAtPrice.value - igPrices.igPrice.value) /
              igPrices.igCompareAtPrice.value) *
              100
          )
        : 0,
    };
  }

  return {
    compareAtPrice: prices?.compareAtPrice,
    hasDiscount: (prices?.full_price || 0) - (prices?.final_price || 0) > 0,
    discountGiven: prices?.discount_given,
    finalPrice: prices?.finalPrice,
    fullPrice: prices?.fullPrice,
    formattedDiscount: prices?.formattedDiscount,
    discountAmount: prices?.discountAmount,
  };
}
