import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

function ALFlags({ countryISO, handleClick = () => {} }) {
  if (!countryISO) {
    return null;
  }

  function renderFlag({ countryCode }) {
    switch (countryCode) {
      case "AD":
        return <StaticImage src="../icons/flags/AD.svg" alt="Country Flag AD" loading="lazy" />;
      case "AE":
        return <StaticImage src="../icons/flags/AE.svg" alt="Country Flag AE" loading="lazy" />;
      case "AG":
        return <StaticImage src="../icons/flags/AG.svg" alt="Country Flag AG" loading="lazy" />;
      case "AI":
        return <StaticImage src="../icons/flags/AI.svg" alt="Country Flag AI" loading="lazy" />;
      case "AL":
        return <StaticImage src="../icons/flags/AL.svg" alt="Country Flag AL" loading="lazy" />;
      case "AM":
        return <StaticImage src="../icons/flags/AM.svg" alt="Country Flag AM" loading="lazy" />;
      case "AR":
        return <StaticImage src="../icons/flags/AR.svg" alt="Country Flag AR" loading="lazy" />;
      case "AT":
        return <StaticImage src="../icons/flags/AT.svg" alt="Country Flag AT" loading="lazy" />;
      case "AU":
        return <StaticImage src="../icons/flags/AU.svg" alt="Country Flag AU" loading="lazy" />;
      case "AW":
        return <StaticImage src="../icons/flags/AW.svg" alt="Country Flag AW" loading="lazy" />;
      case "AZ":
        return <StaticImage src="../icons/flags/AZ.svg" alt="Country Flag AZ" loading="lazy" />;
      case "BA":
        return <StaticImage src="../icons/flags/BA.svg" alt="Country Flag BA" loading="lazy" />;
      case "BB":
        return <StaticImage src="../icons/flags/BB.svg" alt="Country Flag BB" loading="lazy" />;
      case "BE":
        return <StaticImage src="../icons/flags/BE.svg" alt="Country Flag BE" loading="lazy" />;
      case "BG":
        return <StaticImage src="../icons/flags/BG.svg" alt="Country Flag BG" loading="lazy" />;
      case "BJ":
        return <StaticImage src="../icons/flags/BJ.svg" alt="Country Flag BJ" loading="lazy" />;
      case "BM":
        return <StaticImage src="../icons/flags/BM.svg" alt="Country Flag BM" loading="lazy" />;
      case "BN":
        return <StaticImage src="../icons/flags/BN.svg" alt="Country Flag BN" loading="lazy" />;
      case "BO":
        return <StaticImage src="../icons/flags/BO.svg" alt="Country Flag BO" loading="lazy" />;
      case "BR":
        return <StaticImage src="../icons/flags/BR.svg" alt="Country Flag BR" loading="lazy" />;
      case "BS":
        return <StaticImage src="../icons/flags/BS.svg" alt="Country Flag BS" loading="lazy" />;
      case "BW":
        return <StaticImage src="../icons/flags/BW.svg" alt="Country Flag BW" loading="lazy" />;
      case "BZ":
        return <StaticImage src="../icons/flags/BZ.svg" alt="Country Flag BZ" loading="lazy" />;
      case "CA":
        return <StaticImage src="../icons/flags/CA.svg" alt="Country Flag CA" loading="lazy" />;
      case "CH":
        return <StaticImage src="../icons/flags/CH.svg" alt="Country Flag CH" loading="lazy" />;
      case "CL":
        return <StaticImage src="../icons/flags/CL.svg" alt="Country Flag CL" loading="lazy" />;
      case "CN":
        return <StaticImage src="../icons/flags/CN.svg" alt="Country Flag CN" loading="lazy" />;
      case "CO":
        return <StaticImage src="../icons/flags/CO.svg" alt="Country Flag CO" loading="lazy" />;
      case "CR":
        return <StaticImage src="../icons/flags/CR.svg" alt="Country Flag CR" loading="lazy" />;
      case "CV":
        return <StaticImage src="../icons/flags/CV.svg" alt="Country Flag CV" loading="lazy" />;
      case "CY":
        return <StaticImage src="../icons/flags/CY.svg" alt="Country Flag CY" loading="lazy" />;
      case "CZ":
        return <StaticImage src="../icons/flags/CZ.svg" alt="Country Flag CZ" loading="lazy" />;
      case "DE":
        return <StaticImage src="../icons/flags/DE.svg" alt="Country Flag DE" loading="lazy" />;
      case "DJ":
        return <StaticImage src="../icons/flags/DJ.svg" alt="Country Flag DJ" loading="lazy" />;
      case "DK":
        return <StaticImage src="../icons/flags/DK.svg" alt="Country Flag DK" loading="lazy" />;
      case "DM":
        return <StaticImage src="../icons/flags/DM.svg" alt="Country Flag DM" loading="lazy" />;
      case "DO":
        return <StaticImage src="../icons/flags/DO.svg" alt="Country Flag DO" loading="lazy" />;
      case "DZ":
        return <StaticImage src="../icons/flags/DZ.svg" alt="Country Flag DZ" loading="lazy" />;
      case "EC":
        return <StaticImage src="../icons/flags/EC.svg" alt="Country Flag EC" loading="lazy" />;
      case "EE":
        return <StaticImage src="../icons/flags/EE.svg" alt="Country Flag EE" loading="lazy" />;
      case "EG":
        return <StaticImage src="../icons/flags/EG.svg" alt="Country Flag EG" loading="lazy" />;
      case "ES":
        return <StaticImage src="../icons/flags/ES.svg" alt="Country Flag ES" loading="lazy" />;
      case "ET":
        return <StaticImage src="../icons/flags/ET.svg" alt="Country Flag ET" loading="lazy" />;
      case "FI":
        return <StaticImage src="../icons/flags/FI.svg" alt="Country Flag FI" loading="lazy" />;
      case "FK":
        return <StaticImage src="../icons/flags/FK.svg" alt="Country Flag FK" loading="lazy" />;
      case "FO":
        return <StaticImage src="../icons/flags/FO.svg" alt="Country Flag FO" loading="lazy" />;
      case "FR":
        return <StaticImage src="../icons/flags/FR.svg" alt="Country Flag FR" loading="lazy" />;
      case "GB":
        return <StaticImage src="../icons/flags/GB.svg" alt="Country Flag GB" loading="lazy" />;
      case "GD":
        return <StaticImage src="../icons/flags/GD.svg" alt="Country Flag GD" loading="lazy" />;
      case "GE":
        return <StaticImage src="../icons/flags/GE.svg" alt="Country Flag GE" loading="lazy" />;
      case "GF":
        return <StaticImage src="../icons/flags/FR.svg" alt="Country Flag FR" loading="lazy" />;
      case "GG":
        return <StaticImage src="../icons/flags/GG.svg" alt="Country Flag GG" loading="lazy" />;
      case "GI":
        return <StaticImage src="../icons/flags/GI.svg" alt="Country Flag GI" loading="lazy" />;
      case "GL":
        return <StaticImage src="../icons/flags/GL.svg" alt="Country Flag GL" loading="lazy" />;
      case "GN":
        return <StaticImage src="../icons/flags/GN.svg" alt="Country Flag GN" loading="lazy" />;
      case "GP":
        return <StaticImage src="../icons/flags/FR.svg" alt="Country Flag FR" loading="lazy" />;
      case "GR":
        return <StaticImage src="../icons/flags/GR.svg" alt="Country Flag GR" loading="lazy" />;
      case "GT":
        return <StaticImage src="../icons/flags/GT.svg" alt="Country Flag GT" loading="lazy" />;
      case "GW":
        return <StaticImage src="../icons/flags/GW.svg" alt="Country Flag GW" loading="lazy" />;
      case "GY":
        return <StaticImage src="../icons/flags/GY.svg" alt="Country Flag GY" loading="lazy" />;
      case "HK":
        return <StaticImage src="../icons/flags/HK.svg" alt="Country Flag HK" loading="lazy" />;
      case "HN":
        return <StaticImage src="../icons/flags/HN.svg" alt="Country Flag HN" loading="lazy" />;
      case "HR":
        return <StaticImage src="../icons/flags/HR.svg" alt="Country Flag HR" loading="lazy" />;
      case "HT":
        return <StaticImage src="../icons/flags/HT.svg" alt="Country Flag HT" loading="lazy" />;
      case "HU":
        return <StaticImage src="../icons/flags/HU.svg" alt="Country Flag HU" loading="lazy" />;
      case "ID":
        return <StaticImage src="../icons/flags/ID.svg" alt="Country Flag ID" loading="lazy" />;
      case "IE":
        return <StaticImage src="../icons/flags/IE.svg" alt="Country Flag IE" loading="lazy" />;
      case "IL":
        return <StaticImage src="../icons/flags/IL.svg" alt="Country Flag IL" loading="lazy" />;
      case "IS":
        return <StaticImage src="../icons/flags/IS.svg" alt="Country Flag IS" loading="lazy" />;
      case "IT":
        return <StaticImage src="../icons/flags/IT.svg" alt="Country Flag IT" loading="lazy" />;
      case "JM":
        return <StaticImage src="../icons/flags/JM.svg" alt="Country Flag JM" loading="lazy" />;
      case "JP":
        return <StaticImage src="../icons/flags/JP.svg" alt="Country Flag JP" loading="lazy" />;
      case "KE":
        return <StaticImage src="../icons/flags/KE.svg" alt="Country Flag KE" loading="lazy" />;
      case "KG":
        return <StaticImage src="../icons/flags/KG.svg" alt="Country Flag KG" loading="lazy" />;
      case "KH":
        return <StaticImage src="../icons/flags/KH.svg" alt="Country Flag KH" loading="lazy" />;
      case "KM":
        return <StaticImage src="../icons/flags/KM.svg" alt="Country Flag KM" loading="lazy" />;
      case "KN":
        return <StaticImage src="../icons/flags/KN.svg" alt="Country Flag KN" loading="lazy" />;
      case "KR":
        return <StaticImage src="../icons/flags/KR.svg" alt="Country Flag KR" loading="lazy" />;
      case "KY":
        return <StaticImage src="../icons/flags/KY.svg" alt="Country Flag KY" loading="lazy" />;
      case "KZ":
        return <StaticImage src="../icons/flags/KZ.svg" alt="Country Flag KZ" loading="lazy" />;
      case "LA":
        return <StaticImage src="../icons/flags/LA.svg" alt="Country Flag LA" loading="lazy" />;
      case "LB":
        return <StaticImage src="../icons/flags/LB.svg" alt="Country Flag LB" loading="lazy" />;
      case "LC":
        return <StaticImage src="../icons/flags/LC.svg" alt="Country Flag LC" loading="lazy" />;
      case "LI":
        return <StaticImage src="../icons/flags/LI.svg" alt="Country Flag LI" loading="lazy" />;
      case "LK":
        return <StaticImage src="../icons/flags/LK.svg" alt="Country Flag LK" loading="lazy" />;
      case "LS":
        return <StaticImage src="../icons/flags/LS.svg" alt="Country Flag LS" loading="lazy" />;
      case "LT":
        return <StaticImage src="../icons/flags/LT.svg" alt="Country Flag LT" loading="lazy" />;
      case "LU":
        return <StaticImage src="../icons/flags/LU.svg" alt="Country Flag LU" loading="lazy" />;
      case "LV":
        return <StaticImage src="../icons/flags/LV.svg" alt="Country Flag LV" loading="lazy" />;
      case "MA":
        return <StaticImage src="../icons/flags/MA.svg" alt="Country Flag MA" loading="lazy" />;
      case "MD":
        return <StaticImage src="../icons/flags/MD.svg" alt="Country Flag MD" loading="lazy" />;
      case "ME":
        return <StaticImage src="../icons/flags/ME.svg" alt="Country Flag ME" loading="lazy" />;
      case "MK":
        return <StaticImage src="../icons/flags/MK.svg" alt="Country Flag MK" loading="lazy" />;
      case "MN":
        return <StaticImage src="../icons/flags/MN.svg" alt="Country Flag MN" loading="lazy" />;
      case "MO":
        return <StaticImage src="../icons/flags/MO.svg" alt="Country Flag MO" loading="lazy" />;
      case "MQ":
        return <StaticImage src="../icons/flags/MQ.svg" alt="Country Flag MQ" loading="lazy" />;
      case "MS":
        return <StaticImage src="../icons/flags/MS.svg" alt="Country Flag MS" loading="lazy" />;
      case "MT":
        return <StaticImage src="../icons/flags/MT.svg" alt="Country Flag MT" loading="lazy" />;
      case "MU":
        return <StaticImage src="../icons/flags/MU.svg" alt="Country Flag MU" loading="lazy" />;
      case "MV":
        return <StaticImage src="../icons/flags/MV.svg" alt="Country Flag MV" loading="lazy" />;
      case "MW":
        return <StaticImage src="../icons/flags/MW.svg" alt="Country Flag MW" loading="lazy" />;
      case "MX":
        return <StaticImage src="../icons/flags/MX.svg" alt="Country Flag MX" loading="lazy" />;
      case "MY":
        return <StaticImage src="../icons/flags/MY.svg" alt="Country Flag MY" loading="lazy" />;
      case "MZ":
        return <StaticImage src="../icons/flags/MZ.svg" alt="Country Flag MZ" loading="lazy" />;
      case "NA":
        return <StaticImage src="../icons/flags/NA.svg" alt="Country Flag NA" loading="lazy" />;
      case "NC":
        return <StaticImage src="../icons/flags/FR.svg" alt="Country Flag FR" loading="lazy" />;
      case "NG":
        return <StaticImage src="../icons/flags/NG.svg" alt="Country Flag NG" loading="lazy" />;
      case "NI":
        return <StaticImage src="../icons/flags/NI.svg" alt="Country Flag NI" loading="lazy" />;
      case "NL":
        return <StaticImage src="../icons/flags/NL.svg" alt="Country Flag NL" loading="lazy" />;
      case "NO":
        return <StaticImage src="../icons/flags/NO.svg" alt="Country Flag NO" loading="lazy" />;
      case "NP":
        return <StaticImage src="../icons/flags/NP.svg" alt="Country Flag NP" loading="lazy" />;
      case "NU":
        return <StaticImage src="../icons/flags/NU.svg" alt="Country Flag NU" loading="lazy" />;
      case "NZ":
        return <StaticImage src="../icons/flags/NZ.svg" alt="Country Flag NZ" loading="lazy" />;
      case "PA":
        return <StaticImage src="../icons/flags/PA.svg" alt="Country Flag PA" loading="lazy" />;
      case "PE":
        return <StaticImage src="../icons/flags/PE.svg" alt="Country Flag PE" loading="lazy" />;
      case "PH":
        return <StaticImage src="../icons/flags/PH.svg" alt="Country Flag PH" loading="lazy" />;
      case "PK":
        return <StaticImage src="../icons/flags/PK.svg" alt="Country Flag PK" loading="lazy" />;
      case "PL":
        return <StaticImage src="../icons/flags/PL.svg" alt="Country Flag PL" loading="lazy" />;
      case "PT":
        return <StaticImage src="../icons/flags/PT.svg" alt="Country Flag PT" loading="lazy" />;
      case "PY":
        return <StaticImage src="../icons/flags/PY.svg" alt="Country Flag PY" loading="lazy" />;
      case "QA":
        return <StaticImage src="../icons/flags/QA.svg" alt="Country Flag QA" loading="lazy" />;
      case "RE":
        return <StaticImage src="../icons/flags/FR.svg" alt="Country Flag FR" loading="lazy" />;
      case "RO":
        return <StaticImage src="../icons/flags/RO.svg" alt="Country Flag RO" loading="lazy" />;
      case "RS":
        return <StaticImage src="../icons/flags/RS.svg" alt="Country Flag RS" loading="lazy" />;
      case "SA":
        return <StaticImage src="../icons/flags/SA.svg" alt="Country Flag SA" loading="lazy" />;
      case "SC":
        return <StaticImage src="../icons/flags/SC.svg" alt="Country Flag SC" loading="lazy" />;
      case "SE":
        return <StaticImage src="../icons/flags/SE.svg" alt="Country Flag SE" loading="lazy" />;
      case "SG":
        return <StaticImage src="../icons/flags/SG.svg" alt="Country Flag SG" loading="lazy" />;
      case "SI":
        return <StaticImage src="../icons/flags/SI.svg" alt="Country Flag SI" loading="lazy" />;
      case "SK":
        return <StaticImage src="../icons/flags/SK.svg" alt="Country Flag SK" loading="lazy" />;
      case "SM":
        return <StaticImage src="../icons/flags/SM.svg" alt="Country Flag SM" loading="lazy" />;
      case "SR":
        return <StaticImage src="../icons/flags/SR.svg" alt="Country Flag SR" loading="lazy" />;
      case "ST":
        return <StaticImage src="../icons/flags/ST.svg" alt="Country Flag ST" loading="lazy" />;
      case "SV":
        return <StaticImage src="../icons/flags/SV.svg" alt="Country Flag SV" loading="lazy" />;
      case "SZ":
        return <StaticImage src="../icons/flags/SZ.svg" alt="Country Flag SZ" loading="lazy" />;
      case "TC":
        return <StaticImage src="../icons/flags/TC.svg" alt="Country Flag TC" loading="lazy" />;
      case "TH":
        return <StaticImage src="../icons/flags/TH.svg" alt="Country Flag TH" loading="lazy" />;
      case "TO":
        return <StaticImage src="../icons/flags/TO.svg" alt="Country Flag TO" loading="lazy" />;
      case "TR":
        return <StaticImage src="../icons/flags/TR.svg" alt="Country Flag TR" loading="lazy" />;
      case "TV":
        return <StaticImage src="../icons/flags/TV.svg" alt="Country Flag TV" loading="lazy" />;
      case "TW":
        return <StaticImage src="../icons/flags/TW.svg" alt="Country Flag TW" loading="lazy" />;
      case "TZ":
        return <StaticImage src="../icons/flags/TZ.svg" alt="Country Flag TZ" loading="lazy" />;
      case "US":
        return <StaticImage src="../icons/flags/US.svg" alt="Country Flag US" loading="lazy" />;
      case "UY":
        return <StaticImage src="../icons/flags/UY.svg" alt="Country Flag UY" loading="lazy" />;
      case "UZ":
        return <StaticImage src="../icons/flags/UZ.svg" alt="Country Flag UZ" loading="lazy" />;
      case "VE":
        return <StaticImage src="../icons/flags/VE.svg" alt="Country Flag VE" loading="lazy" />;
      case "VG":
        return <StaticImage src="../icons/flags/VG.svg" alt="Country Flag VG" loading="lazy" />;
      case "VU":
        return <StaticImage src="../icons/flags/VU.svg" alt="Country Flag VU" loading="lazy" />;
      case "ZA":
        return <StaticImage src="../icons/flags/ZA.svg" alt="Country Flag ZA" loading="lazy" />;
      case "ZM":
        return <StaticImage src="../icons/flags/ZM.svg" alt="Country Flag ZM" loading="lazy" />;
      case "ZW":
        return <StaticImage src="../icons/flags/ZW.svg" alt="Country Flag ZW" loading="lazy" />;
      default:
        return null;
    }
  }

  // no flags for "BD", "BL", "BY", "CK", "FJ", "MC", "NR", "PF", "PG", "PR", "SB", "TP", "TT", "UA", "VA", "WS"

  return (
    <div className="al_flag_container" onClick={handleClick}>
      {renderFlag({ countryCode: countryISO })}
    </div>
  );
}

ALFlags.propTypes = {
  countryISO: PropTypes.string,
  handleClick: PropTypes.func,
};

export default ALFlags;
