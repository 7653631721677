import PropTypes from "prop-types";
import * as Styles from "./item/styles.module.scss";

function Item({ icon, content }) {
  return (
    <div className={Styles.iconContainer}>
      <span className={Styles.iconContainer__icon}>{icon}</span>
      <p className={`subtext ${Styles.iconContainer__text}`}>{content}</p>
    </div>
  );
}

Item.propTypes = {
  icon: PropTypes.node.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default Item;
