import { useEffect, useState } from "react";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import LocalePickerAccordion from "./LocalePickerAccordion";
import { Region } from "../../constants/Region";
import { locales } from "../../../locales";

const LocalePicker = ({ handleClick }) => {
  const [localesToRegion, setLocaleToRegion] = useState({});

  const isRestOfTheWorld = (locale) => {
    const region = locale.timeZone.split("/")[0];
    return (
      region !== "America" &&
      region !== "Europe" &&
      region !== "Asia" &&
      region !== "Australia" &&
      region !== "Pacific"
    );
  };

  const belongsTo = (region, locale) => locale.timeZone.split("/")[0] === region;

  useEffect(() => {
    const auxLocalesToRegion = {
      [Region.AMERICA]: [],
      [Region.EUROPE]: [],
      [Region.ASIA]: [],
      [Region.Australia_NewZealand]: [],
      [Region.REST]: [],
    };
    const localesCopy = [...locales];

    localesCopy.sort((a, b) =>
      a.countryName.localeCompare(b.countryName, "en", { sensitivity: "base" })
    );
    localesCopy.forEach((locale) => {
      if (belongsTo("America", locale)) {
        auxLocalesToRegion[Region.AMERICA].push(locale);
      }
      if (belongsTo("Europe", locale)) {
        auxLocalesToRegion[Region.EUROPE].push(locale);
      }
      if (belongsTo("Asia", locale)) {
        auxLocalesToRegion[Region.ASIA].push(locale);
      }
      if (belongsTo("Australia", locale) || belongsTo("Pacific", locale)) {
        auxLocalesToRegion[Region.Australia_NewZealand].push(locale);
      }
      if (isRestOfTheWorld(locale)) {
        auxLocalesToRegion[Region.REST].push(locale);
      }
    });

    setLocaleToRegion(auxLocalesToRegion);
  }, []);

  const regions = Object.keys(localesToRegion);

  return regions.map((region) => (
    <LocalePickerAccordion
      title={region}
      locales={localesToRegion[region]}
      handleClick={handleClick}
      key={`locale_picker_${region}`}
    />
  ));
};

export default withALErrorBoundary({
  name: "LocalePicker",
  priority: "P3",
})(LocalePicker);
