import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connectInfiniteHits } from "react-instantsearch-dom";
import { PropTypes } from "prop-types";
import isEqual from "lodash/isEqual";

import { useTracking } from "../../../../context/Tracking";
import { useProductData2 } from "../../../../context/products";
import { useLocalizedSentenceDict } from "../../../../hooks/useSentenceDict";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import useDataByHandles from "../../../../hooks/useDataByHandles";

import ProductCollection from "../../../CollectionPage/ProductCollection";
import { ComponentType } from "../../../../constants/ComponentType";

import * as Styles from "./customInfiniteHits/styles.module.scss";

const convertHitToProduct = (p, hts) =>
  // turn it into the shape that ProductCollection expects
  ({
    node: { ...p.shopify, shopifyId: p.shopify.id },
    contentful: {
      node: {
        ...p.contentful,
        hts,
      },
    },
  });

function HitToProductCollection({
  hit,
  position,
  indexName,
  queryId,
  productCardClick,
  hts,
  alternativesData = [],
}) {
  const product = useMemo(() => convertHitToProduct(hit, hts), [hit, hts]);
  const alternativesDataFiltered = alternativesData.filter((p) => p);
  // Retrieve product data for each alternative
  const alternativesProducts = useProductData2(
    alternativesDataFiltered.map(({ handle }) => handle),
    "long"
  );
  // Enrich product with alternatives and products data of each alternative in a similar manner as in PLPs
  const productWithAlternatives = useMemo(() => {
    if (!alternativesDataFiltered?.length || !alternativesProducts.data) return product;
    const alternativesProductsByHandle = alternativesProducts.data.reduce(
      (acc, alternativesProduct) => {
        acc[alternativesProduct.handle] = alternativesProduct;
        return acc;
      },
      {}
    );
    const productAlternatives = {
      alternatives: alternativesDataFiltered.map((altData) => ({
        ...altData,
        ...(alternativesProductsByHandle[altData.handle] || {}),
      })),
    };
    productAlternatives.alternatives.forEach((alt) => {
      if (alt.contentful?.node) {
        alt.contentful.node.productAlternatives = productAlternatives;
      }
    });
    return {
      ...product,
      contentful: {
        node: {
          ...product.contentful.node,
          productAlternatives,
        },
      },
    };
  }, [product, alternativesDataFiltered, alternativesProducts.data]);

  // searchObject is used to track ATC on PDP after a search
  const searchObject = useMemo(
    () => ({
      isSearch: true,
      index: indexName,
      queryID: queryId,
      objectID: hit.objectID,
    }),
    [indexName, queryId, hit.objectID]
  );

  return (
    <div key={`search-product-${hit.objectID}`} className={Styles.searchResults__product}>
      <ProductCollection
        product={productWithAlternatives}
        className={`product_collection product_search_${product.handle}`}
        onClick={() => productCardClick(hit.objectID, position + 1)}
        searchObject={searchObject}
        componentType={ComponentType.SEARCH}
        cpPosition={position + 1}
      />
    </div>
  );
}

function CustomInfiniteHits({ hits, hasMore, refineNext, indexName, queryId }) {
  const loadMoreDetectorRef = useRef(null);
  const dict = useLocalizedSentenceDict();
  const { trackProductClickAfterSearch } = useTracking();
  const [hitHandles, setHitHandles] = useState((hits || []).map((item) => item.handle));

  // Whenever hits change, update hitHandles
  // hits change very often, even though it has the same value
  // so only set hitHandles when the value is different
  useEffect(() => {
    const newHitHandles = hits.map((item) => item.handle);
    if (!isEqual(newHitHandles.sort(), hitHandles.sort())) {
      setHitHandles(newHitHandles);
    }
  }, [hits, hitHandles]);

  const htsData = useDataByHandles("product-hts", {}, hitHandles);
  const alternativesData = useDataByHandles("product-alternatives", {}, hitHandles);

  useIntersectionObserver({
    target: loadMoreDetectorRef,
    onIntersect: () => {
      refineNext();
    },
    enabled: hasMore,
  });

  const productCardClick = useCallback(
    (objectID, position) => {
      trackProductClickAfterSearch(indexName, queryId, objectID, position);
    },
    [indexName, queryId, trackProductClickAfterSearch]
  );

  return (
    <div className={Styles.searchResults}>
      {hits.map((hit, position) => (
        <HitToProductCollection
          key={hit.objectID}
          hit={hit}
          position={position}
          indexName={indexName}
          queryId={queryId}
          productCardClick={productCardClick}
          hts={htsData?.data?.find((item) => item?.handle && item.handle === hit.handle)?.hts}
          alternativesData={
            alternativesData?.data?.find(
              (altData) => altData?.handle && altData.handle === hit.handle
            )?.productAlternatives
          }
        />
      ))}

      <div className={Styles.searchResults__end} ref={loadMoreDetectorRef}>
        {!hasMore && hits.length > 0 && <div>{dict.get("No more results")}</div>}
      </div>
    </div>
  );
}

CustomInfiniteHits.propTypes = {
  hits: PropTypes.array,
  hasMore: PropTypes.bool,
  refineNext: PropTypes.func,
  indexName: PropTypes.string,
  queryId: PropTypes.string,
};

export default connectInfiniteHits(CustomInfiniteHits);
