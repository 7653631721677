export function separateLineItems(items, attributeValue) {
  return items.reduce(
    (prev, current) => {
      const attributeObject = current?.attributes?.find(
        (attribute) => attribute.key === attributeValue
      );
      if (attributeObject) {
        prev.customItems.push({
          ...current,
          [attributeValue]: attributeObject.value,
        });
      } else {
        prev.regularItems.push(current);
      }

      return prev;
    },
    { customItems: [], regularItems: [] }
  );
}

export function mapAttributeToProductList(lineItems, attributeValue) {
  return lineItems.reduce((prev, current) => {
    const key = current[attributeValue];
    const prevCopy = prev;
    if (prevCopy[key] !== undefined) {
      prevCopy[key].push(current);
    } else {
      prevCopy[key] = [current];
    }

    return { ...prevCopy };
  }, {});
}

export function splitMultipleQuantityItem(products) {
  return products.reduce((prev, current) => {
    for (let i = 0; i < current.quantity; i += 1) {
      prev.push({
        ...current,
        variant: {
          ...current.variant,
          final_price:
            current.variant.final_price && current.variant.final_price / current.quantity,
          full_price: current.variant.full_price && current.variant.full_price / current.quantity,
        },
      });
    }
    return prev;
  }, []);
}
