import { useEffect, useState } from "react";
import { useAnnouncementBar } from "../../hooks/useAnnouncementBar";
import { countdown } from "../../hooks/useTimer";

function DateTimer({ onExpirationTime }) {
  const [timer, setTimer] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [timerZero, setTimerZero] = useState(false);
  const { dateTimerStart, dateTimerEnd } = useAnnouncementBar();
  useEffect(() => {
    setInterval(() => {
      setTimer(countdown(dateTimerStart, dateTimerEnd));
    }, 1000);
  }, []);

  useEffect(() => {
    setTimerZero(timer.days + timer.hours + timer.minutes + timer.seconds === 0);
    if (timerZero) {
      onExpirationTime();
    }
  }, [timerZero, timer]);

  const addZero = (obj) =>
    obj.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

  return (
    <>
      {timer.days > 0 && <>{addZero(timer.days)}d : </>}
      {addZero(timer.hours)}h : {addZero(timer.minutes)}m
      {timer.days < 1 && <> : {addZero(timer.seconds)}s</>}
    </>
  );
}

export default DateTimer;
