/* eslint-disable no-underscore-dangle */
import axios from "axios";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import {
  createCookie,
  createRootDomainCookie,
  getCookie,
  isBrowser,
  isCrawlerBot,
  removeURLParam,
} from "./helpers";
import { locales } from "../../locales";

const defaultLocale = {
  locale: "en-US",
  value: "English",
  countryName: "United States",
  country: "US", // ISO 3166 Alpha-2
  countryCode: "USA", // country code iso-3
  currency: "USD",
  currencySymbol: "$",
  iso3: "USA", // country code iso-3
  timeZone: "America/New_York",
};

const convertLocaleToGEFormat = (localeData) => ({
  CountryCode: localeData.country,
  CountryCode3: localeData.iso3,
  CountryName: localeData.countryName,
  CurrencyCode: localeData.currency,
  Culture: localeData.locale,
  CurrencySymbol: localeData.currencySymbol,
  Timezone: localeData.timeZone,
});

const defaultGeoContext = {
  clientIp: "",
  isLoadingCountry: false,
  isLoadingGE: false,
  gePriceDetails: convertLocaleToGEFormat(defaultLocale),
  countryDiffersPopupProps: null,
};

export const GeoContext = createContext(defaultGeoContext);

const fixLocaleCultureCode = (geData) => {
  geData.Culture = "en-US";
  return geData;
};

const getLocaleDataFromFile = (country) =>
  fixLocaleCultureCode(
    convertLocaleToGEFormat(locales.find((l) => l.country === country) || defaultLocale)
  );

const getCountryAtEdge = async () => {
  const urlGE = "/.netlify/edge-functions/geo-data-at-edge";

  const params = {};
  let returnLocale;
  try {
    const response = await axios.get(urlGE, { params });
    returnLocale = {
      ...response.data,
      geData:
        response?.data?.country === "US" ? null : getLocaleDataFromFile(response?.data?.country),
    };
  } catch (error) {
    console.log("Error fetching country at edge: ", error);
    returnLocale = { ...defaultLocale, geData: convertLocaleToGEFormat(defaultLocale) };
  }
  return returnLocale;
};

const setCookieFromGEData = (geData, isCustom = false) => {
  const geCookieData = {
    countryISO: geData.CountryCode,
    currencyCode: geData.CurrencyCode,
    cultureCode: geData.Culture,
    isCustom,
  };

  // Create Cookie for Global-E
  createCookie("GlobalE_Data", encodeURIComponent(JSON.stringify(geCookieData)), 365);
  createRootDomainCookie("GlobalE_Data", encodeURIComponent(JSON.stringify(geCookieData)), 365);
};

export default function GeoProvider({ children }) {
  const [state, setState] = useState({
    ...defaultGeoContext,
    isLoadingCountry: isBrowser,
    isLoadingGE: isBrowser,
  });
  const [countryDiffersPopupProps, setCountryDiffersPopupProps] = useState(null);
  const closeCountryDiffersPopup = useCallback(() => setCountryDiffersPopupProps(null), []);
  const updateCountry = useCallback(
    (countryCode) => {
      if (!countryCode || countryCode === state.gePriceDetails.CountryCode) return;
      const localeData = getLocaleDataFromFile(countryCode);
      setCookieFromGEData(localeData, true);
      removeURLParam("glCountry", null, true);
      removeURLParam("glCurrency", null, true);
      window.location.reload();
    },
    [state.gePriceDetails.CountryCode]
  );

  const geoData = useMemo(() => {
    const _countryAlpha2URLParam =
      isBrowser && new URLSearchParams(window.location.search).get("glCountry");
    const customRawCookie = getCookie("GlobalE_Data");
    const { countryISO: _countryAlpha2Cookie, isCustom: _isCustomCountryCookie } =
      (customRawCookie && JSON.parse(decodeURIComponent(customRawCookie))) || {};
    const localeData =
      (_countryAlpha2URLParam && getLocaleDataFromFile(_countryAlpha2URLParam)) ||
      (customRawCookie && getLocaleDataFromFile(_countryAlpha2Cookie));
    // If GE data is still loading we return an incomplete object populated with the locale data
    const gePriceDetails =
      (state.isLoadingGE && localeData) || (!state.isLoadingGE && state.gePriceDetails) || null;
    if (gePriceDetails && gePriceDetails.CountryCode !== _countryAlpha2Cookie) {
      setCookieFromGEData(gePriceDetails);
    }

    return {
      gePriceDetails: gePriceDetails || state.gePriceDetails,
      isLoadingGE: state.isLoadingGE && localeData?.CountryCode !== "US", // We do not load GE Data for US
      isLoadingCountry: !localeData && state.isLoadingGE,
      countryDiffersPopupProps,
      closeCountryDiffersPopup,
      clientIp: state.clientIp,
      updateCountry,

      // Only for internal GeoProvider usage
      _countryAlpha2URLParam,
      _countryAlpha2Cookie,
      _isCustomCountryCookie,
    };
  }, [
    state.isLoadingGE,
    state.gePriceDetails,
    state.clientIp,
    countryDiffersPopupProps,
    closeCountryDiffersPopup,
    updateCountry,
  ]);

  async function getGeoData() {
    /**
     * Country and IP retrieval process explanation:
     *
     * COOKIE:
     * if country is US ==> take from locale file ==> isLoadingCountry(false) ==> call backend ===> retrieve IP
     * if country RoW ===> call backend ===> call global-e ==> retrieve IP + country data
     *
     *
     * NO COOKIE:
     * call backend ===>
     *      if country is US ==> IP + retrieve 2 digit 'country' ===> take from locale file
     *      if country RoW ==> call global-e ==> retrieve IP + country data
     *
     */
    let countryLocaleData = null;
    if (geoData._countryAlpha2Cookie) {
      // COOKIE FOUND
      if (geoData._countryAlpha2Cookie === "US") {
        // US
        // (Country already set by cookie)
        const { clientIp, country, userAgent } = await getCountryAtEdge();
        if (document.body) {
          document.body.dataset.windowUserAgent = window?.navigator?.userAgent;
          document.body.dataset.edgeUserAgent = userAgent;
        }
        if (
          country !== geoData._countryAlpha2Cookie &&
          !geoData._isCustomCountryCookie &&
          !geoData._countryAlpha2URLParam &&
          !isCrawlerBot()
        ) {
          // Visitor visit the website with another country than previous (not selected manually)
          setCountryDiffersPopupProps(getLocaleDataFromFile(country));
        }
        setState({ ...state, clientIp });
        return;
      }
      // RoW
      const { clientIp, geData, country, userAgent } = await getCountryAtEdge(
        geoData._countryAlpha2Cookie
      );
      countryLocaleData = fixLocaleCultureCode(geData);
      if (document.body) {
        document.body.dataset.windowUserAgent = window?.navigator?.userAgent;
        document.body.dataset.edgeUserAgent = userAgent;
      }
      if (
        country !== geoData._countryAlpha2Cookie &&
        !geoData._isCustomCountryCookie &&
        !geoData._countryAlpha2URLParam &&
        !isCrawlerBot()
      ) {
        setCountryDiffersPopupProps(getLocaleDataFromFile(country));
      }
      setState({
        ...state,
        gePriceDetails: countryLocaleData,
        clientIp,
        isLoadingGE: false,
      });
      return;
    }
    // NO COOKIE
    // geData will be filled if RoW and EU *but not if US!*
    const { clientIp, geData, userAgent } = await getCountryAtEdge(geoData._countryAlpha2URLParam);
    if (document.body) {
      document.body.dataset.windowUserAgent = window?.navigator?.userAgent;
      document.body.dataset.edgeUserAgent = userAgent;
    }
    if (geData && !geoData._countryAlpha2URLParam && !isCrawlerBot()) {
      setCountryDiffersPopupProps(geData);
    }
    setState({
      ...state,
      clientIp,
      isLoadingGE: false,
      gePriceDetails: geoData._countryAlpha2URLParam && geData ? geData : state.gePriceDetails,
    });
  }

  useEffect(() => {
    getGeoData();
  }, []);

  // Update and store clientIp and country code in window to be used by blotoutwallet
  useEffect(() => {
    if (state.clientIp) {
      window.__clientIp = state.clientIp;
      window.__countryCode = state.gePriceDetails?.CountryCode || "US";
    }
  }, [state.clientIp, state.gePriceDetails?.CountryCode]);

  return <GeoContext.Provider value={geoData}>{children}</GeoContext.Provider>;
}
