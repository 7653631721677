import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "../../../../al_components/ALLink";
import { useTracking } from "../../../../../context/Tracking";
import { withALErrorBoundary } from "../../../../../helpers/ErrorBoundary/ALErrorBoundary";

function MenuLinkWithImage({ link, title, image, menuTitle, closeMenu }) {
  const { trackMenuClick } = useTracking();
  const isInternal = /^\/(?!\/)/.test(link); // check doesn't start with "/"

  const onMenuImageClick = () => {
    trackMenuClick({
      destinationUrl: link,
      menuTitle,
      categoryTitle: title,
      sourceType: "image",
    });
    closeMenu();
  };

  return (
    <li className="menu_content_elements_image">
      {isInternal ? (
        <Link to={link} onClick={onMenuImageClick}>
          <GatsbyImage
            className="menu_content_elements_image_img"
            image={getImage(image.desktopImage.gatsbyImageData)}
            alt={image.altText}
          />
          <p className="text text--bold mt-10">{title}</p>
        </Link>
      ) : (
        <a href={link} onClick={onMenuImageClick}>
          <GatsbyImage
            className="menu_content_elements_image_img"
            image={getImage(image.desktopImage.gatsbyImageData)}
            alt={image.altText}
          />
          <p className="text text--bold mt-10">{title}</p>
        </a>
      )}
    </li>
  );
}

MenuLinkWithImage.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    altText: PropTypes.string.isRequired,
    desktopImage: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  menuTitle: PropTypes.string.isRequired,
  closeMenu: PropTypes.func,
};

export default withALErrorBoundary({
  name: "MenuLinkWithImage",
  priority: "P2",
})(MenuLinkWithImage);
