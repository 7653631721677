import { ComponentType } from "../../constants/ComponentType";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { InventoryPolicies } from "../../constants/InventoryPolicies";
import "./radioVariants/styles.scss";
import { Country } from "../../constants/Country";

function RadioVariants({
  variant,
  handleOnChangeSize,
  product,
  productSize,
  componentType = "",
  country,
}) {
  let isSelected = false;
  if (variant && variant?.selectedOptions[0].value === productSize) {
    isSelected = true;
  }

  const isInventoryPolicyContinue = variant?.inventoryPolicy === InventoryPolicies.CONTINUE;
  let isVariantDisable = variant?.availableForSale === false;
  if (variant?.availableForSale && isInventoryPolicyContinue && country !== Country.US) {
    isVariantDisable = true;
  }
  return (
    <div
      className={`variant_selector ${
        componentType ? `variant_selector_${componentType.toLowerCase()}` : ""
      } ${isVariantDisable ? "variant_selector_disable" : ""} ${
        isSelected ? "variant_selector_selected" : ""
      }`}
    >
      <input
        className="variant_selector_input"
        onClick={() => handleOnChangeSize(variant)}
        onChange={() => handleOnChangeSize(variant)}
        type="radio"
        name={`${variant.selectedOptions[0].name}-${product.id}`}
        value={variant.title}
        disabled={isVariantDisable}
        checked={productSize === variant.title && true}
        id={`${variant.selectedOptions[0].name}-${variant.title}-${product.id}`}
      />
      <label
        className={
          componentType === ComponentType.CP || componentType === ComponentType.BYOB
            ? "subtext"
            : "h4"
        }
        htmlFor={`${variant.selectedOptions[0].name}-${variant.title}-${product.id}`}
      >
        {variant.title}
      </label>
    </div>
  );
}

export default withALErrorBoundary({
  name: "RadioVariants",
  priority: "P1",
})(RadioVariants);
