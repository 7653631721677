import PropTypes from "prop-types";
import { Link } from "gatsby";
import { formatISO, parse } from "date-fns";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { resizeShopifyImage } from "../../context/helpers";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useIsBannedProduct from "../../hooks/useIsBannedProduct";

import Actions from "./lineItem/Actions";
import ItemPrice from "./ItemPrice";
import { ALTag } from "../ALComponents";
import { ComponentType } from "../../constants/ComponentType";
import { getShipByDate } from "../../helpers/product";

function getItemSubtitle(lineItem) {
  const shouldDisplayLetter =
    /^[A-Za-z\s]*$/.test(lineItem?.variant?.title) && lineItem.variant.title !== "Default Title";
  const shouldDisplayNumber =
    /[Nn]{1}umber/.test(lineItem.title) && lineItem.variant.title !== "Default Title";
  const shouldDisplaySize = /^\d+$/.test(lineItem?.variant?.title) && !shouldDisplayNumber;
  const shouldDisplaySign = /[Zz]{1}odiac/.test(lineItem.variant.product.title);
  const shouldDisplayBirthstone = /[Bb]{1}irthstone/.test(lineItem.variant.product.title);

  if (shouldDisplaySign) {
    return ["Sign {0}", lineItem.variant.title];
  }

  if (shouldDisplayBirthstone) {
    return ["Birthstone {0}", lineItem.variant.title];
  }

  if (shouldDisplayLetter) {
    return ["Letter {0}", lineItem.variant.title];
  }

  if (shouldDisplayNumber) {
    return ["Number {0}", lineItem.variant.title];
  }

  if (shouldDisplaySize) {
    return ["Size {0}", lineItem.variant.title];
  }

  return null;
}

function LineItem({ lineItem, onRemove, toggleCart, isFreeGift, isLuxe }) {
  const dict = useLocalizedSentenceDict();

  const isBanned = useIsBannedProduct(lineItem.variant.product, isLuxe);
  const isSoldOut = !!lineItem?.variant?.available;
  const isDiscontinued = lineItem.variant === null;
  let isFinalSale = false;
  if (lineItem?.attributes) {
    isFinalSale = !!lineItem.attributes.find(
      (atr) => atr.key === "_final_sale" && atr.value === "true"
    );
  }

  const { isPreOrder, preorderShipsByDate } = (lineItem.attributes || []).reduce(
    (acc, { key, value }) => {
      if (key === "_ships_by_pre_order" && !!value) {
        const shipsByDateAttr = parse(value, "MMM d, yyyy", new Date());
        if (!shipsByDateAttr) return acc;
        acc.preorderShipsByDate = getShipByDate(
          formatISO(shipsByDateAttr, { representation: "date" })
        );
      } else if (key === "_pre_order") {
        acc.isPreOrder = value === "true";
      }
      return acc;
    },
    { isPreOrder: false, preorderShipsByDate: null }
  );

  const isSolidGold = lineItem.variant.product.tags.includes("Components:Solid Gold");
  const showTag = isSolidGold;
  const itemSubtitleParams = getItemSubtitle(lineItem);

  return (
    <div className="cart_item">
      <div className="cart_item_image_wrapper">
        {showTag && (
          <ALTag
            product={{
              node: { ...lineItem.variant.product, variants: [lineItem.variant] },
              contentful: null,
            }}
            componentType={ComponentType.SIDE_CART}
          />
        )}
        {/* IMAGE */}
        <div
          className={`cart_item_image ${showTag ? "cart_item_image--tag" : ""}`}
          onClick={toggleCart}
        >
          {isDiscontinued ? (
            <p className="cart_item_title">{dict.get("Discontinued")}</p>
          ) : (
            <Link to={`/products/${lineItem.variant.product.handle}`}>
              <img
                src={resizeShopifyImage(lineItem.variant.image.url, "x200")}
                alt={lineItem.variant.image.altText}
              />
            </Link>
          )}
        </div>
      </div>
      <div className="cart_item_content">
        <div className="cart_item_upper_section">
          <div className="cart_item_content_infos">
            <div className="cart_item_content_title">
              <p className="cart_item_title">{lineItem.variant.product.title.split(" - ")[1]}</p>

              {itemSubtitleParams && (
                <p className="cart_item_subtitle">{dict.get(...itemSubtitleParams)}</p>
              )}

              {isFreeGift && <div className="cart_item_finalsale">{dict.get("FREE GIFT")}</div>}

              {lineItem.bogoApplied && (
                <div className="cart_item_finalsale">{dict.get("Discount applied")}</div>
              )}

              {isFinalSale && <p className="cart_item_finalsale">{dict.get("Final Sale")}</p>}

              {isPreOrder && (
                <p className="cart_item_finalsale">
                  {dict.get("Ships by: {0}", preorderShipsByDate)}
                </p>
              )}

              {lineItem?.attributes?.find((e) => e.key === "_engravable_text") && (
                <p className="cart_item_subtitle">
                  {dict.get("Text: ")}
                  {lineItem.attributes.find((e) => e.key === "_engravable_text").value}
                </p>
              )}
              {isBanned && <p className="cart_item_excluded">{dict.get("Excluded from Sales")}</p>}
            </div>

            <ItemPrice variant={lineItem.variant} isFreeGift={isFreeGift} isLuxe={isLuxe} />
          </div>
        </div>

        {isSoldOut && <p className="cart_item_title">{dict.get("Out of stock")}</p>}

        <Actions lineItem={lineItem} isFreeGift={isFreeGift} onRemove={onRemove} />
      </div>
    </div>
  );
}

LineItem.propTypes = {
  lineItem: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  toggleCart: PropTypes.func.isRequired,
  isFreeGift: PropTypes.bool,
  isLuxe: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "LineItem",
  priority: "P1",
})(LineItem);
