import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import * as styles from "./ABPopupFreeGift.module.scss";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { useCurrentFormatCurrency } from "../../hooks/usePrices";

function ABPopupFreeGift({ discount, textColor, data }) {
  const formatCurrency = useCurrentFormatCurrency();
  const dict = useLocalizedSentenceDict();
  const { isMobile } = useDeviceDetect();
  return data ? (
    <div
      className={`${styles.popup_freegift}`}
      style={{ color: textColor }}
      data-testid="ab-popup-free-gift"
    >
      <p className={`al_h4 ${styles.popup_freegift_title}`}>{dict.get(data.title)}</p>
      <p className={`al_h2 ${styles.popup_freegift_subtitle}`}>
        {dict.get(data.subtitle, formatCurrency(discount?.freegift?.minAmount))}
      </p>
      <div className={`${styles.popup_freegift_container}`}>
        <LazyLoad className={`${styles.popup_freegift_container_image}`}>
          <div className={`${styles.popup_freegift_container_image_block}`}>
            <img
              className={`${styles.popup_freegift_container_img}`}
              src={discount?.freegift?.data?.node?.images[0]?.url}
              alt={discount?.freegift?.data?.node?.images[0]?.altText}
            />
          </div>
        </LazyLoad>
        <div className={`${styles.popup_freegift_container_details}`}>
          <p className={`al_subtext al_subtext--bold ${styles.popup_freegift_container_title}`}>
            {discount?.freegift?.data?.node?.title.split(" - ")[1]}
          </p>
          <p className={`al_subtext al_subtext--bold ${styles.popup_freegift_container_price}`}>
            {formatCurrency(discount?.freegift?.data?.node?.variants[0]?.price)} {dict.get("value")}
          </p>
        </div>
      </div>
      <p className={`${isMobile ? "al_subtext" : "al_p"} ${styles.popup_freegift_condition}`}>
        {dict.get(data.conditions)}
      </p>
    </div>
  ) : null;
}

ABPopupFreeGift.propTypes = {
  discount: PropTypes.object.isRequired,
  textColor: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default ABPopupFreeGift;
