import useDeviceDetect from "./useDeviceDetect";
import useIsHydrationComplete from "./useIsHydrationComplete";

// https://www.joshwcomeau.com/react/the-perils-of-rehydration/#the-solution

export default function useResponsiveWithHydrationFix(mobileElement, desktopElement) {
  const { isMobile } = useDeviceDetect();
  const hydrationDone = useIsHydrationComplete();
  if (!hydrationDone) {
    return mobileElement;
  }
  return isMobile ? mobileElement : desktopElement;
}
