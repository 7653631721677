import PropTypes from "prop-types";
import Details, { detailsPropTypes } from "../Details";

function ContentDetails({ detailsList = [], componentType }) {
  return detailsList.map(
    (product) =>
      product &&
      product?.productDetails && (
        <Details
          details={product.productDetails}
          key={`product_detail_item_${product.handle || ""}`}
          title={product.title}
          componentType={componentType}
        />
      )
  );
}

ContentDetails.propTypes = {
  detailsList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      handle: PropTypes.string,
      productDetails: PropTypes.shape(detailsPropTypes).isRequired,
    })
  ).isRequired,
  componentType: PropTypes.string.isRequired,
};

export default ContentDetails;
