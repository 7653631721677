import { useContext, useEffect, useRef } from "react";

import StoreContext from "../context/store";
import { LoopReturnContext, useAmountRefunded } from "../context/LoopReturnProvider";
import { HiddenElementsContext } from "../context/HiddenElements";
import { CartContext } from "../context/CartProvider";
import { DiscountContext } from "../context/DiscountProvider";
import { gaCustomEvent, roundNumber } from "../context/helpers";

import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import { useCurrentFormatCurrency } from "../hooks/usePrices";
import { applyCartDiscounts } from "./cartDiscounts";

import UpSellsRecommendation from "./sideCart/UpSellsRecommendation";
import SideCartItems from "./sideCart/SideCartItems";
import { ALButton, ALLink, ALLoading } from "./ALComponents";
import IconClose from "./icons/svgs/close-round.svg";
import LockIcon from "./icons/svgs/lock.svg";

import "./sideCart/styles.scss";

function LoopReturnSideCart() {
  const context = useContext(StoreContext);
  const { trackCartProduct } = context.store;
  const {
    initiateCheckout,
    isLoading: loopReturnLoading,
    base,
    credit,
  } = useContext(LoopReturnContext);
  const { cart, isLoadingCart, toggleCart, isSideCartOpen } = useContext(CartContext);

  const sideCartHeaderRef = useRef(null);
  const sideCartCheckoutRef = useRef(null);

  const dict = useLocalizedSentenceDict();
  const formatCurrency = useCurrentFormatCurrency();
  const { discountInfo } = useContext(DiscountContext);
  const { hide, show } = useContext(HiddenElementsContext);
  const sideCartInfo = applyCartDiscounts(cart.lines, discountInfo);
  const amountRefunded = useAmountRefunded(sideCartInfo.fullAmount);
  const { lines } = sideCartInfo;
  const productCount = lines.reduce((c, li) => c + li.quantity, 0);

  useEffect(() => {
    if (isSideCartOpen) {
      hide();
    } else {
      show();
    }
  }, [isSideCartOpen, hide, show]);

  return (
    <div className="lp-sidecart al_sidecart">
      <div
        onClick={toggleCart}
        className={`sidecart_container ${
          isSideCartOpen ? "sidecart_container--isOpen" : "sidecart_container--isClose"
        }`}
      />
      <div className={`sidecart ${isSideCartOpen ? "sidecart--isOpen" : "sidecart--isClose"}`}>
        {isSideCartOpen && (isLoadingCart || loopReturnLoading) && (
          <div className="sidecart_loader">
            <ALLoading />
          </div>
        )}
        <div className="sidecart_header" ref={sideCartHeaderRef}>
          <span className="sidecart_header_title">{dict.get("Your bag")}</span>
          {productCount !== 0 && <span className="sidecart_qty">{`(${productCount})`}</span>}
          <div className="sidecart_close" onClick={toggleCart}>
            <IconClose fill="none" stroke="#2D2927" />
          </div>
        </div>

        {productCount === 0 && !isLoadingCart ? (
          <div className="sidecart_bag_empty">
            <p className="h2">{dict.get("Your bag is empty")}.</p>
            <div className="sidecart_bag_empty_links">
              <ALLink to="/collections/bestsellers" className="sidecart_bag_empty_links__link">
                <ALButton variant="secondary" onClick={toggleCart} fullWidth>
                  {dict.get("Best sellers")}
                </ALButton>
              </ALLink>
              <ALLink to="/collections/new" className="sidecart_bag_empty_links__link">
                <ALButton variant="secondary" onClick={toggleCart} fullWidth>
                  {dict.get("New Arrivals")}
                </ALButton>
              </ALLink>
            </div>
          </div>
        ) : (
          <div
            className="sidecart_content"
            style={{
              paddingBottom: `${sideCartCheckoutRef?.current?.clientHeight}px`,
            }}
          >
            <SideCartItems
              lineItems={lines}
              context={context}
              toggleCart={toggleCart}
              freegiftLineItemId={sideCartInfo.freegiftLineItemId}
            />

            <UpSellsRecommendation lines={lines} />

            <div className="sidecart_total">
              <p className="sidecart_total_content">
                <span>{dict.get("Estimated total")}</span>
                <span>{formatCurrency(roundNumber(sideCartInfo.fullAmount))}</span>
              </p>
              <p className="sidecart_total_content">
                <span>{dict.get("Return credit")}</span>
                <span>({formatCurrency(roundNumber(base / 100))})</span>
              </p>
              <p className="sidecart_total_content">
                <span>{dict.get("Bonus credit")}</span>
                <span>({formatCurrency(roundNumber(credit / 100))})</span>
              </p>
            </div>

            {/* Bottom items */}
            <div className="checkout_cta" ref={sideCartCheckoutRef}>
              <p className="sidecart_total_content">
                <span>{dict.get(amountRefunded < 0 ? "Amount Owed" : "Amount refunded")}:</span>
                {/* The key below is used to prevent a Safari-only rendering issue https://stackoverflow.com/a/55050203 */}
                <span
                  key={`classic_${sideCartInfo.finalAmount}`}
                  className="sidecart_total_content--no-discounts"
                >
                  {formatCurrency(roundNumber(Math.abs(amountRefunded)))}
                </span>
              </p>

              <div
                onClick={() => {
                  trackCartProduct.forEach((productTracked) => {
                    if (productTracked.origin.startsWith("recommended-")) {
                      const readableFormatOrigin = productTracked.origin
                        .replace("recommended-", "")
                        .split("-")
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(" ");
                      gaCustomEvent(
                        `Recommended Product - ${readableFormatOrigin}`,
                        "go-to-checkout",
                        productTracked.productTitle
                      );
                    }
                  });
                }}
              >
                <ALButton
                  size="large"
                  fullWidth
                  className="checkout__button"
                  onClick={() => initiateCheckout(lines)}
                >
                  {dict.get("SECURE CHECKOUT")}
                  <LockIcon width="17" height="21" fill="none" stroke="white" className="ml-20" />
                </ALButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoopReturnSideCart;
