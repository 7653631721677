import { useContext } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { GeoContext } from "../context/GeoProvider";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";

const CACHE_AVAILABILITY = 5 * 60 * 1000; // 5 minutes

function useCheckInventory(handle) {
  const { sendReport } = useALError();

  const { gePriceDetails, isLoadingCountry } = useContext(GeoContext);
  const countryCode = gePriceDetails?.CountryCode;

  const { data, isLoading } = useQuery({
    queryKey: ["product-availability", { handle, countryCode }],
    queryFn: () =>
      axios.get(`/.netlify/functions/check-inventory?handle=${handle}&countryCode=${countryCode}`),
    staleTime: CACHE_AVAILABILITY,
    enabled: !!handle && !!countryCode && !isLoadingCountry,
    meta: { name: "useCheckInventory", priority: "P1", sendReport },
  });

  return {
    availableForSale: !!data?.data?.availableForSale,
    variants: data?.data?.variants || [],
    isLowInStock: data?.data?.isLowInStock,
    isLoading,
    isFound: !!data?.data?.isFound,
  };
}

export default useCheckInventory;
