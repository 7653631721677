import { useMemo } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";

function convertProductToContextProduct(data, formatType) {
  if (formatType === "short") {
    const short = {
      handle: data.contentful.node.handle,
      contentful: data.contentful.node,
      shopify: data.node,
    };
    return short;
  }
  if (formatType === "long") {
    return data;
  }
  return data;
}

export async function queryProductData(handles, queryType, countryCode, lang = "en-US") {
  if (handles?.length > 0 && handles?.filter((h) => h !== "null")?.join() !== "") {
    const res = await axios.get(
      `/.netlify/functions/get-product?handles=${handles.join()}&length=${queryType}&countryCode=${countryCode}&lang=${lang}`
    );
    if (res.status === 200) {
      return res.data.products.map((p) => {
        const converted = convertProductToContextProduct(p, queryType);
        return {
          node: converted.shopify,
          ...p,
          ...converted,
          contentful: {
            node: converted.contentful,
            ...converted.contentful,
          },
        };
      });
    }
    throw new Error(`Failed to fetch products ${handles}, country=${countryCode}, lang=${lang}`);
  } else {
    return [];
  }
}

export function useProductData2(handles, type = "short", countryCode = "US") {
  const ONE_DAY = 24 * 60 * 60 * 1000;
  const lang = "en-US";
  const { sendReport } = useALError();

  const result = useQuery({
    queryKey: ["products", { handles, countryCode, lang }],
    queryFn: async () => queryProductData(handles, type, countryCode, lang),
    staleTime: ONE_DAY,
    meta: { name: "useProductData2", priority: "P5", sendReport },
  });
  return useMemo(() => ({ ...result, data: result.data ?? [] }), [result]);
}
