import { useCurrentFormatCurrency } from "../../hooks/usePrices";

import "./sideCartTotalTieredDiscount/styles.scss";

function SideCartTotalTieredDiscount({ estimatedTotal, fullAmount }) {
  const formatCurrency = useCurrentFormatCurrency();

  return (
    <p className="sidecart_total_container_tiered_discount text">
      {/* The key below is used to prevent a Safari-only rendering issue https://stackoverflow.com/a/55050203 */}
      {estimatedTotal < fullAmount ? (
        <span key={`sidecart_total_${estimatedTotal}`}>
          <span className="sidecart_total_content_tiered_discount--withLineThrough">
            <span className="strikethrough strikethrough--sm">{formatCurrency(fullAmount)}</span>
            <span className="sidecart_total_content_tiered_discount h4 h4--bold h4--uc">
              {formatCurrency(estimatedTotal)}
            </span>
          </span>
          <span className="sidecart_total_content_tiered_discount--withoutLineThrough">
            <span>{formatCurrency(estimatedTotal)}</span>
          </span>
        </span>
      ) : (
        <span key={`classic_${fullAmount}`} className="text text--uc text--bold">
          {formatCurrency(estimatedTotal)}
        </span>
      )}
    </p>
  );
}

export default SideCartTotalTieredDiscount;
