import Accordion from "../al_components/ALAccordion";
import LocalePickerContent from "./LocalePickerContent";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

function LocalePickerAccordion({ title, locales, handleClick }) {
  return (
    <Accordion title={title} titleClassName="locale_picker__title">
      <div className="locale_picker__container">
        {locales.map((locale) => (
          <LocalePickerContent
            locale={locale}
            handleClick={handleClick}
            key={`locale_picker_accordion_${locale.country}`}
          />
        ))}
      </div>
    </Accordion>
  );
}

export default withALErrorBoundary({
  name: "LocalePickerAccordion",
  priority: "P3",
})(LocalePickerAccordion);
