import { useState } from "react";
import { useLocation } from "@reach/router";

import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import { useCurrentCountryCode } from "../../../hooks/usePrices";
import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { gaCustomEvent } from "../../../context/helpers";
import { ComponentType } from "../../../constants/ComponentType";

import { ALButton } from "../../ALComponents";
import RadioVariants from "../../AddToCart/RadioVariants";

import IconCart from "../../icons/svgs/cart.svg";
import IconClose from "../../icons/svgs/close.svg";
import "./quickAddToCart/styles.scss";

function QuickAddToCart({ onQuickAddToCart, product, collectionTitle }) {
  const countryCode = useCurrentCountryCode();
  const dict = useLocalizedSentenceDict();
  const [showVariants, setShowVariants] = useState(false);
  const [variantSelected, setVariantSelected] = useState(0);

  const location = useLocation();

  /**
   * Show/Hide Variants
   */
  const handleToggleVariants = () => {
    setShowVariants(!showVariants);
  };

  /**
   * Change position object find
   * @param {*} arr
   * @param {*} old_index
   * @param {*} new_index
   * @returns
   */
  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  const addToCart = () => {
    gaCustomEvent("Quick add to cart", "Button click", `${collectionTitle} - ${product.title}`);
    onQuickAddToCart();
  };

  const handleAddToCartClick = () => {
    if (product.variants.length > 1) {
      handleToggleVariants();
    } else {
      addToCart(product);
    }
  };

  /**
   * Change Variant
   * @param {*} variant
   */
  const handleOnChangeSize = (variant) => {
    setVariantSelected(variant);
    const variantIndex = product.variants.findIndex((x) => x.id === variant.id);

    if (variantIndex > -1) {
      array_move(product.variants, variantIndex, 0);
      addToCart();
    }

    handleToggleVariants();
  };

  return (
    location.pathname !== "/pages/wishlist" && (
      <div className="quick-add-to-cart">
        {showVariants ? (
          <div className="quick-add-to-cart__variants-content">
            <button type="button" onClick={handleToggleVariants}>
              <IconClose width="20" height="20" fill="black" />
            </button>
            <div className="quick-add-to-cart__variants">
              {product?.variants.map((variant, index) => (
                <RadioVariants
                  key={index}
                  variant={variant}
                  product={{ id: product?.shopifyId }}
                  productSize={variantSelected.title}
                  handleOnChangeSize={handleOnChangeSize}
                  componentType={ComponentType.CP}
                  countryCode={countryCode}
                />
              ))}
            </div>
          </div>
        ) : (
          <ALButton
            type="button"
            variant="secondary"
            size="xsmall"
            className="quick-add-to-cart__button"
            pillButton={false}
            onClick={handleAddToCartClick}
          >
            <IconCart width="15" height="15" stroke="#e94b2f" />
            <span className="subtext">{dict.get("Add to cart")}</span>
          </ALButton>
        )}
      </div>
    )
  );
}

export default withALErrorBoundary({
  name: "QuickAddToCart",
  priority: "P1",
})(QuickAddToCart);
