import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { useTracking } from "../../../../../context/Tracking";
import { withALErrorBoundary } from "../../../../../helpers/ErrorBoundary/ALErrorBoundary";
import { Link } from "../../../../al_components/ALLink";

function MenuCategory({ category, menuTitle, closeMenu }) {
  const { fontColor: color, title, titleLink, links } = category;
  const menuCategoryRef = useRef(null);
  const { trackMenuClick } = useTracking();
  const [heightElement, updateHeightContainer] = useState(null);

  useEffect(() => {
    if (menuCategoryRef.current) {
      updateHeightContainer(menuCategoryRef.current.offsetHeight);
    }
  }, []);

  const onMenuLinkClick = ({ destinationUrl, subCategoryTitle }) => {
    trackMenuClick({
      destinationUrl,
      menuTitle,
      categoryTitle: title,
      subCategoryTitle: subCategoryTitle || "",
      sourceType: "text",
    });
    closeMenu();
  };

  const isSubItemsEmpty = category?.links[0]?.title === "empty";

  return (
    <div
      className={`menu_content_elements_links ${
        isSubItemsEmpty ? "menu_content_elements_links_empty" : ""
      }`}
      ref={menuCategoryRef}
      style={heightElement ? { height: `${heightElement}px` } : {}}
    >
      {titleLink.trim() === "#" ? (
        <h3 className="text text--bold text--uc mb-25 mt-0" style={{ color }}>
          {title}
        </h3>
      ) : (
        <Link
          to={titleLink}
          onClick={() => onMenuLinkClick({ destinationUrl: titleLink })}
          className="text text--bold text--uc menu_content_elements_links_title"
          style={{ color }}
        >
          {title}
        </Link>
      )}
      <ul className="menu_content_elements_links_container">
        {links?.map((link) => {
          if (link.title !== "empty") {
            return (
              <li className="subtext" key={link.id}>
                <Link
                  onClick={() =>
                    onMenuLinkClick({
                      destinationUrl: link.link.link,
                      subCategoryTitle: link.title,
                    })
                  }
                  to={link.link.link}
                  style={{
                    color: link?.setting?.[0]?.fontColor,
                    fontWeight: link?.setting?.[0]?.fontWeight,
                    textDecoration: link?.setting?.[0]?.fontStyle,
                  }}
                >
                  {link.title}
                </Link>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
}

MenuCategory.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fontColor: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        link: PropTypes.shape({
          link: PropTypes.string,
        }),
        title: PropTypes.string,
      })
    ),
    title: PropTypes.string.isRequired,
    titleLink: PropTypes.string.isRequired,
  }),
  menuTitle: PropTypes.string,
  closeMenu: PropTypes.func,
};

export default withALErrorBoundary({
  name: "MenuCategory",
  priority: "P2",
})(MenuCategory);
