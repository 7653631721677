import { useEffect } from "react";
import PropTypes from "prop-types";
import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import ErrorBoundary from "./ErrorBoundary";

import "./slideOutMenu/styles.scss";

function SlideOutMenu({
  align,
  headerContent,
  isSlideoutOpen,
  toggleSlideout,
  className,
  children,
}) {
  useEffect(() => {
    try {
      if (isSlideoutOpen) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      } else {
        document.getElementsByTagName("body")[0].style.overflow = null;
      }
    } catch (error) {
      console.log("Error slideout set style");
      console.log(error);
    }
  }, [isSlideoutOpen]);

  return (
    <ErrorBoundary>
      <div className={`${className || ""}`}>
        <div
          onClick={toggleSlideout}
          className={`slideout_container ${
            isSlideoutOpen ? "slideout_container--isOpen" : "slideout_container--isClosed"
          }`}
        />
        <div
          className={`slideout slideout--${align} ${
            isSlideoutOpen ? "slideout--isOpen" : `slideout-${align}--isClose`
          }`}
        >
          {headerContent ? <div className="slideout_header">{headerContent}</div> : null}
          <div
            className={`slideout_content ${className ? `${className}_content` : ""}`}
            style={{ height: "100%", position: "relative" }}
          >
            {children}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

SlideOutMenu.propTypes = {
  align: PropTypes.oneOf(["left", "right"]),
  headerContent: PropTypes.object,
  className: PropTypes.string,
  isSlideoutOpen: PropTypes.bool.isRequired,
  toggleSlideout: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.element]),
};

export default withALErrorBoundary({
  name: "SlideOutMenu",
  priority: "P3",
})(SlideOutMenu);
