import { forwardRef } from "react";
import { ALFlags, ALSkeleton } from "../ALComponents";
import localeFlag from "../commons/LocaleFlag";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import useIsHydrationComplete from "../../hooks/useIsHydrationComplete";

import { ComponentType } from "../../constants/ComponentType";

const LocalesPopUpTrigger = forwardRef((props, ref) => {
  const {
    country,
    currency,
    countryISO,
    showISO,
    showCountry,
    onClick,
    isMobileMenu,
    componentType,
  } = props;

  const hasFlag = localeFlag[countryISO];
  const isHydrationComplete = useIsHydrationComplete();

  const renderFlag = () => {
    if (hasFlag) {
      return isHydrationComplete ? (
        <ALFlags countryISO={countryISO} />
      ) : (
        <div className="al_flag_container">
          <ALSkeleton />
        </div>
      );
    }
    return null;
  };

  return (
    <div
      ref={ref} // this ref is required by reactjs-popup
      className={
        isMobileMenu
          ? "locales_current locales_popup--trigger--mobile--menu"
          : "locales_current locales_popup--trigger"
      }
      onClick={onClick}
    >
      {renderFlag()}
      {!hasFlag && showISO && (
        <span className={isMobileMenu ? "microtext" : "subtext"}>{countryISO}</span>
      )}
      {showCountry && <span className={isMobileMenu ? "microtext" : "subtext"}>{country}</span>}
      &nbsp;
      {componentType === ComponentType.FOOTER ? (
        <span className="subtext">({currency})</span>
      ) : (
        <span className="microtext microtext--bold">{currency}</span>
      )}
    </div>
  );
});

export default withALErrorBoundary({
  name: "LocalesPopUpTrigger",
  priority: "P3",
})(LocalesPopUpTrigger);
