import { IconType } from "../../../../constants/IconType";
import Accordion from "../../../al_components/ALAccordion";

function EngravingShippingDetails({ enableSeeMore }) {
  const shippingDetails = (
    <div>
      <p>
        - Engraved jewelry is final sale and cannot be returned or exchanged. We are unable to
        accommodate cancellations or changes to orders containing engraved jewelry.
      </p>
      <p>- Engraved items may differ slightly from preview.</p>
    </div>
  );

  return (
    <div>
      <p>
        - Our engraved jewelry is made to order in our Brooklyn studio. Please allow 7-10 days for
        processing time on any orders containing engraved jewelry.
      </p>
      {enableSeeMore && (
        <Accordion title="See more" titleClassName="al_subtext" iconType={IconType.ARROW}>
          {shippingDetails}
        </Accordion>
      )}
      {!enableSeeMore && shippingDetails}
    </div>
  );
}

export default EngravingShippingDetails;
