import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import { ALButton, ALSkeleton } from "../../../ALComponents";

import * as Styles from "./optionalProductsContainer/styles.module.scss";

function OptionalProductsContainer({
  openCollectionDrawer,
  optionalProductsConfig,
  buttonLabel,
  isLoading,
  children,
}) {
  const { optionalProductsIcon, optionalProductsTitle } = optionalProductsConfig;

  return (
    <div className={Styles.container}>
      <hr className={Styles.separator} />
      <div className={Styles.titleBlock} onClick={openCollectionDrawer}>
        <div className={Styles.leftSide}>
          {optionalProductsIcon?.gatsbyImageData ? (
            <GatsbyImage image={optionalProductsIcon.gatsbyImageData} alt={optionalProductsTitle} />
          ) : (
            <ALSkeleton width="30px" height="30px" />
          )}
          <p className="subtext">{optionalProductsTitle}</p>
        </div>

        <ALButton
          type="button"
          onClick={openCollectionDrawer}
          variant="plain"
          size="small"
          disabled={isLoading}
        >
          {isLoading ? <ALSkeleton /> : buttonLabel}
        </ALButton>
      </div>
      {children}
      <hr className={Styles.separator} />
    </div>
  );
}

OptionalProductsContainer.propTypes = {
  openCollectionDrawer: PropTypes.func.isRequired,
  optionalProductsConfig: PropTypes.shape({
    optionalProductsIcon: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    optionalProductsTitle: PropTypes.string,
  }).isRequired,
  buttonLabel: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default OptionalProductsContainer;
