/* eslint-disable max-classes-per-file */
import axios from "axios";
import { getCartId, isBrowser, normalizeLines } from "./helpers";
import { expTrackCartRecovery } from "./tracking/trackingExponea";

const CART_UPDATE_EVENT_NAME = "external-cart-update";
const PRODUCT_TITLE_EXCLUSION_LIST = ["Engravable"];

if (isBrowser) {
  const createShopAPI = () => {
    const addItems = (items) => {
      const countryCode = window.__countryCode;
      const clientIp = window.__clientIp;
      const cartId = getCartId(countryCode);

      const filteredLines = items.filter(
        (item) => !PRODUCT_TITLE_EXCLUSION_LIST.some((bannedWord) => item.name.includes(bannedWord))
      );

      const body = {
        cartId,
        countryCode,
        lines: normalizeLines(
          filteredLines.map((item) => ({
            variant: { id: `gid://shopify/ProductVariant/${item.variantId}` },
            quantity: item.quantity,
          }))
        ),
      };
      return axios
        .post("/.netlify/functions/add-to-cart", body, {
          headers: { ip_address: clientIp },
        })
        .then((response) => {
          const event = new CustomEvent(CART_UPDATE_EVENT_NAME, { detail: response.data });
          window.dispatchEvent(event);
          expTrackCartRecovery({
            isSuccess: true,
            cartId,
            numberLines: filteredLines.length,
            totalValue: filteredLines.reduce((acc, item) => acc + item.price * item.quantity, 0),
            numberItems: filteredLines.reduce((acc, item) => acc + item.quantity, 0),
            countryCode,
          });
        })
        .catch((error) => {
          console.error("==> addItems API error", error);
          expTrackCartRecovery({
            isSuccess: false,
            cartId,
            numberLines: filteredLines.length,
            totalValue: filteredLines.reduce((acc, item) => acc + item.price * item.quantity, 0),
            numberItems: filteredLines.reduce((acc, item) => acc + item.quantity, 0),
            countryCode,
          });
          throw error;
        });
    };

    const getCartToken = () => {
      const countryCode = window.__countryCode;
      const cartId = getCartId(countryCode);
      return cartId ? cartId.replace("gid://shopify/Cart/", "").split("?")[0] : null;
    };

    return { addItems, getCartToken };
  };

  // this is the global symbol used to coordinate the related components

  const registryKey = Symbol.for("blotout-wallet");

  window[registryKey] = window[registryKey] ?? {};

  // these expose your store factory to the global namespace and
  // are required for the SDK to locate and use your adapter
  window[registryKey].storeAPIFactory = createShopAPI;

  // this specifies the underlying platform, which should be set to SHOPIFY
  window[registryKey].platform = "SHOPIFY";
}

export { CART_UPDATE_EVENT_NAME };
