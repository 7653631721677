import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";

import { isBrowser, isCrawlerBot } from "../../../context/helpers";
import { ALVideo } from "../../ALComponents";
import ALShopifyImage from "../../al_components/ALShopifyImage";

import { EVERGREEN_VIDEOID } from "../../../constants/Common";

function ThumbnailGallery({ setThumbsSwiper, videoId, images }) {
  return (
    <Swiper
      onSwiper={setThumbsSwiper}
      spaceBetween={10}
      slidesPerView={5.5}
      freeMode
      watchSlidesProgress
      className="thumb_gallery"
      data-testid="thumb-gallery"
    >
      {videoId && (
        <SwiperSlide key={`tg_product_video_${videoId}_pdp`} data-testid="video-slide">
          <ALVideo
            videoId={videoId}
            customClasses={{
              container: "pdp_image pdp_image_video_container",
              vimeo_container: "pdp_image_video",
              image_thumbnail: "pdp_image_video_thumbnail",
              skeleton_container: "pdp_sk_m",
              skeleton_image: "pdp_sk_image",
              video_container: "video_container",
              video: "video-vimeo",
            }}
            previewOnly
          />
        </SwiperSlide>
      )}

      {images.map((p) => {
        if (p.altText === null || !p.altText.includes("vimeo.com")) {
          return (
            <SwiperSlide key={`tg_product_image_${p.url}_pdp`}>
              <ALShopifyImage
                width={isBrowser && window?.innerWidth}
                height={isBrowser && window?.innerWidth}
                url={p.url}
                alt={p.altText}
              />
            </SwiperSlide>
          );
        }
        return null;
      })}

      {!isCrawlerBot() && (
        <SwiperSlide key={`product_video_${EVERGREEN_VIDEOID}_pdp`}>
          <ALVideo
            videoId={EVERGREEN_VIDEOID}
            customClasses={{
              container: "pdp_image pdp_image_video_container",
              vimeo_container: "",
              image_thumbnail: "pdp_image_video_thumbnail",
              skeleton_container: "pdp_sk_m",
              skeleton_image: "pdp_sk_image",
              video_container: "video_container",
              video: "video-vimeo",
            }}
            previewOnly
          />
        </SwiperSlide>
      )}
    </Swiper>
  );
}

ThumbnailGallery.propTypes = {
  setThumbsSwiper: PropTypes.func,
  videoId: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      altText: PropTypes.string,
    })
  ),
};

export default ThumbnailGallery;
