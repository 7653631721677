import PropTypes from "prop-types";
import InnerImageZoom from "react-inner-image-zoom";
import { generateHighResSrcSet } from "../../al_components/ALShopifyImage";

import "./desktopImage/styles.scss";

const PDP_DESKTOP_IMAGE_MAX_WIDTH = 518;

function DesktopImage({ image }) {
  if (!image) {
    return null;
  }
  const { altText, url } = image;

  const sources = [
    { srcSet: generateHighResSrcSet({ url, alt: altText, width: PDP_DESKTOP_IMAGE_MAX_WIDTH }) },
  ];

  return (
    <div className="pdp_image">
      <InnerImageZoom
        src={url}
        hideHint
        hideCloseButton
        zoomScale={1}
        imgAttributes={{ alt: altText, loading: "lazy" }}
        sources={sources}
      />
    </div>
  );
}

DesktopImage.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    altText: PropTypes.string,
  }),
};

export default DesktopImage;
