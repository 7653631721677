import { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { intlFormat } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { GeoContext } from "../../context/GeoProvider";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { isBrowser } from "../../context/helpers";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";

import { ComponentType } from "../../constants/ComponentType";
import { getShopifyStoreByCountry } from "../../../locale-shopifies";
import LocalesPopUp from "../locales/LocalesPopUp";
import { ALPaymentMethod, ALLink as Link } from "../ALComponents";
import CaptureEmail from "../CaptureEmail/captureEmail";

import IconArrow from "../icons/svgs/arrow.svg";
import InstagramIcon from "../icons/svgs/instagram.svg";
import TikTokIcon from "../icons/svgs/tiktok.svg";
import EmailIcon from "../icons/svgs/email.svg";
import ChatIcon from "../icons/svgs/chat.svg";
import SmsIcon from "../icons/svgs/phone.svg";
import ClimateNeutralIcon from "../icons/svgs/climate-neutral.svg";
import "./footer.scss";

function Footer() {
  const { gePriceDetails } = useContext(GeoContext);

  const dict = useLocalizedSentenceDict();
  const { isMobile } = useDeviceDetect();

  const { knowledgeBase } = getShopifyStoreByCountry(gePriceDetails.CountryCode);
  const { customerSupport } = getShopifyStoreByCountry(gePriceDetails.CountryCode);

  const [hoursCustomerSupport, updateHoursCustomerSupport] = useState({});

  const { allContentfulFooter } = useStaticQuery(graphql`
    query SiteFooter {
      allContentfulFooter {
        nodes {
          node_locale
          footeritem {
            title
            link {
              title
              link {
                link
              }
            }
          }
        }
      }
    }
  `);

  let contentfulFooter = null;
  if (gePriceDetails) {
    contentfulFooter = allContentfulFooter.nodes.find(
      (x) => x.node_locale === gePriceDetails?.Culture
    );
    if (typeof contentfulFooter === "undefined") {
      contentfulFooter = allContentfulFooter.nodes.find((x) => x.node_locale === "en-US");
    }
  } else {
    contentfulFooter = allContentfulFooter.nodes.find((x) => x.node_locale === "en-US");
  }

  const sitemapElements = [
    {
      link: "/pages/sitemap",
      title: "sitemap",
    },
    {
      link: "/policies/privacy-policy",
      title: "Privacy Policy",
    },
    {
      link: "/policies/terms-of-service",
      title: "T&C",
    },
    {
      link: "/pages/promo-sale",
      title: "PROMO T&C",
    },
    {
      link: "/pages/sms-terms",
      title: "SMS T&C",
    },
    {
      link: "/pages/luxe-program-terms/",
      title: "AL Luxe T&C",
    },
    {
      link: "/pages/sms-privacy",
      title: "SMS Privacy Policy",
    },
    {
      link: "/pages/ca-transparency-in-supply-chains-act",
      title: "CA SUPPLY CHAINS ACT",
    },
  ];
  const socialMediaElements = [
    {
      link: "https://www.instagram.com/analuisany",
      title: "Instagram",
      image: <InstagramIcon width="24" fill="none" stroke="white" />,
    },
    {
      link: "https://www.tiktok.com/@analuisany",
      title: "TikTok",
      image: <TikTokIcon width="24" fill="none" stroke="white" />,
    },
  ];

  const getCurrentYear = () => new Date().getFullYear();

  useEffect(() => {
    if (customerSupport && isBrowser) {
      // convert hours for correct timezone
      const timeZone = gePriceDetails?.Timezone;

      const startParsedDate = new Date(customerSupport?.startHours);
      const startDate = utcToZonedTime(startParsedDate, timeZone);
      const startHourStr = intlFormat(
        startDate,
        { hour: "numeric" },
        { locale: gePriceDetails?.Culture }
      );

      const endParsedDate = new Date(customerSupport?.endHours);
      const endDate = utcToZonedTime(endParsedDate, timeZone);
      const endHourStr = intlFormat(
        endDate,
        { hour: "numeric" },
        { locale: gePriceDetails?.Culture }
      );
      updateHoursCustomerSupport({
        start: startHourStr.replace(" ", ""),
        end: endHourStr.replace(" ", ""),
      });
    }
  }, [customerSupport, gePriceDetails?.Culture, gePriceDetails.Timezone]);

  const toggleFooterLinks = (e) => {
    e.preventDefault();
    e.currentTarget.classList.toggle("active");
  };

  const openKustomer = () => {
    const hasKustomer = typeof window.Kustomer !== "undefined";
    if (hasKustomer) {
      window.Kustomer.open();
    }
  };

  return (
    <footer className="footer">
      <div className="footer_container">
        <ul className="footer_primary">
          {contentfulFooter?.footeritem.map((el, i) => (
            <li className="footer_links" key={`footer_item_${el.title}`}>
              {el.link[0].link.link ? (
                <a
                  href={el.link[0].link.link}
                  className="footer_links_title_container"
                  onClick={isMobile && i < 2 ? (e) => toggleFooterLinks(e) : null}
                  disabled={!isMobile || (isMobile && i >= 2)}
                  type="button"
                >
                  {el.title} {isMobile && i < 2 && <IconArrow width="13" height="13" />}
                </a>
              ) : (
                <p className="footer_links_title_container">
                  {el.title} {isMobile && i < 2 && <IconArrow width="13" height="13" />}
                </p>
              )}
              <ul className="footer_links_content">
                {el.link.map((footerLink) => {
                  let _link = footerLink.link.link;
                  if (_link === "https://faq.analuisa.com") {
                    _link = knowledgeBase;
                  }
                  if (footerLink.link.link.includes("https:")) {
                    return (
                      <li className="footer_link" key={`footer_item_link_${footerLink.title}`}>
                        <a href={_link} className="footer_link">
                          {dict.get(footerLink.title)}
                        </a>
                      </li>
                    );
                  }
                  return (
                    <li className="footer_link" key={`footer_item_link_${footerLink.title}`}>
                      <Link className="footer_link" to={_link}>
                        {dict.get(footerLink.title)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          ))}
          <li
            className="footer_links"
            key={`footer_item_${contentfulFooter.footeritem.length + 1}`}
          >
            <span className="text--uc">{dict.get("Contact US")}</span>
            <ul className="footer_links_content">
              {Object.keys(hoursCustomerSupport).length > 0 ? (
                <li className="footer_link">
                  <span className="footer_link">
                    {dict.get(
                      customerSupport.sentence,
                      hoursCustomerSupport.start,
                      hoursCustomerSupport.end
                    )}
                  </span>
                </li>
              ) : null}
              <li className="footer_link">
                <a className="footer_icon_link footer_link" href="mailto:love@analuisa.com">
                  <EmailIcon width="23" height="14" fill="none" stroke="#2D2927" />
                  <span>{dict.get("Email")}</span>
                </a>
              </li>
              <li className="footer_link">
                <button
                  className="footer_icon_link footer_link chat_icon"
                  onClick={() => openKustomer()}
                  type="button"
                >
                  <ChatIcon width="19" height="17" fill="none" />
                  {dict.get("Chat Now")}
                </button>
              </li>
              <li className="footer_link">
                <a className="footer_icon_link footer_link" href="sms:+18446580027">
                  <SmsIcon width="16" height="22" fill="none" stroke="#2D2927" />
                  <span>+1 (844)-658-0027 (Text only)</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <div className="footer_secondary">
          <CaptureEmail />
          <div className="footer_climate_social">
            <div className="footer_social">
              {socialMediaElements.map((social) => (
                <a
                  href={social.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  title={social.title}
                  key={`footer_social_${social.title}`}
                >
                  {social.image}
                </a>
              ))}
            </div>
            <div className="footer_climate_neutral">
              <ClimateNeutralIcon width="76" />
            </div>
          </div>
        </div>
      </div>

      <div className="footer_tertiary">
        <div className="footer_payment_locales">
          {!isMobile && (
            <LocalesPopUp showCountry showISO={false} componentType={ComponentType.FOOTER} />
          )}
          <div className="footer_payment">
            <p className="text--uc text--bold mb--7">{dict.get("Secure Checkout Options")}</p>
            <ALPaymentMethod locale={gePriceDetails.CountryCode} className="footer_payment_image" />
          </div>
        </div>
        <div className="footer_legal_locale">
          {isMobile && <LocalesPopUp componentType={ComponentType.FOOTER} />}

          <ul className="footer_legal">
            <li className="microtext microtext--uc footer_copyright">
              © Ana Luisa™ {getCurrentYear()}
            </li>
            {sitemapElements.map((e) => {
              if (e.link.includes("https:")) {
                return (
                  <a
                    className="microtext microtext--uc"
                    href={`${e.link}`}
                    key={`footer_sitemap_${e.title}`}
                  >
                    {dict.get(e.title)}
                  </a>
                );
              }
              return (
                <li className="microtext microtext--uc" key={`footer_sitemap_${e.title}`}>
                  <Link className="microtext microtext--uc" to={e.link}>
                    {dict.get(e.title)}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default withALErrorBoundary({
  name: "Footer",
  priority: "P3",
})(Footer);
