import { useLocation } from "@reach/router";
import { createContext, useContext, useEffect, useState } from "react";

import { LoopReturnContext } from "../../context/LoopReturnProvider";
import { DiscountContext } from "../../context/DiscountProvider";
import { GeoContext } from "../../context/GeoProvider";
import { CartContext } from "../../context/CartProvider";
import { useAnnouncementBar } from "../../hooks/useAnnouncementBar";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import { ALLink as Link } from "../ALComponents";
import ABPopup from "./ABPopup";
import AnnouncementBarLayout from "./AnnouncementBarLayout";

import * as styles from "./AnnouncementBar.module.scss";
import AnnouncementBarLoopReturn from "./AnnouncementBarLoopReturn";
import DISCOUNT_TYPES from "../../constants/DiscountTypes";

export const AnnouncementBarPopupContext = createContext([]);

export function useAnnouncementBarPopup() {
  return useContext(AnnouncementBarPopupContext);
}

function processStrings(elements, processor) {
  const result = [];
  for (const e of elements) {
    if (typeof e === "string") {
      processor(e, result);
    } else {
      result.push(e);
    }
  }
  return result;
}

function processLinebreaks(elements) {
  return processStrings(elements, (s, outputArray) => {
    const parts = s.split("\\n");
    for (let i = 0; i < parts.length; i++) {
      outputArray.push(parts[i]);
      if (i < parts.length - 1) {
        outputArray.push(<br key={`annbar-mobile-br-${i}`} className={styles.mobile_only_br} />);
      }
    }
  });
}

function processLinks(elements, textColor) {
  const regex = /\[([^\[\]]+)\]\(([\w\/\-_?=]+)\)/;
  return processStrings(elements, (s, outputArray) => {
    let m;
    do {
      m = regex.exec(s);
      if (m) {
        const head = s.slice(0, m.index);
        if (head) outputArray.push(head);
        outputArray.push(
          <Link
            key={`annbar-link-${m.index}`}
            to={m[2]}
            className={styles.link}
            style={{ color: textColor }}
          >
            {m[1]}
          </Link>
        );
        const tail = s.slice(m.index + m[0].length);
        s = tail;
      }
    } while (m);
    if (s) outputArray.push(s);
  });
}

function getAnnouncementElements(sentence, varMap, textColor = null) {
  // resolve vars
  const regex = /\{([A-Z_]+)\}/g; // the g at the end prevents infinite-loop below
  let m;
  let resolvedSentence = "";
  let lastEnd = 0;
  do {
    m = regex.exec(sentence);
    if (m) {
      const prefix = sentence.slice(lastEnd, m.index);
      resolvedSentence += prefix + (varMap[m[1]] ?? m[1]);
      lastEnd = m.index + m[0].length;
    }
  } while (m);
  const tail = sentence.slice(lastEnd);
  resolvedSentence += tail;

  // process styles (underline and bold)
  const stack = [];
  let result = [];
  let s = "";
  for (let i = 0; i < resolvedSentence.length; i++) {
    const c = resolvedSentence[i];
    switch (c) {
      case "*":
      case "_":
        if (stack.length > 0 && stack[stack.length - 1] === c) {
          stack.pop();
          if (s.length > 0) {
            result.push(s);
            s = "";
          }
          result[result.length - 1] = (
            <span className={c === "*" ? styles.strong : styles.underline} key={i}>
              {result[result.length - 1]}
            </span>
          );
        } else {
          stack.push(c);
        }
        break;
      default:
        if (stack.length === 0) {
          if (typeof result[result.length - 1] === "string") result[result.length - 1] += c;
          else result.push(c);
        } else {
          s += c;
        }
    }
  }
  if (s.length > 0) result.push(s);

  result = processLinebreaks(result);

  result = processLinks(result, textColor);

  return result;
}

function AnnouncementBar({ hidden }) {
  const location = useLocation();
  const { discountInfo } = useContext(DiscountContext);
  const { gePriceDetails } = useContext(GeoContext);
  const { isLoopReturnEnabled } = useContext(LoopReturnContext);
  const { announcementTitleElement, discountCode, discountText, hasPopup, textColor, isSticky } =
    useAnnouncementBar();
  const [popupOpen, setPopupOpen] = useAnnouncementBarPopup();
  const { isSideCartOpen } = useContext(CartContext);
  const isOnPdpAdsPage = location.pathname.includes("/p/");
  // hasShownAnnouncementBar should be 'true' if announcement bar has been shown previously (by opening sidecart or visiting another page than PDP Ads)
  const [hasShownAnnouncementBar, setHasShownAnnouncementBar] = useState(false);

  useEffect(() => {
    if (!hasShownAnnouncementBar && (isSideCartOpen || !isOnPdpAdsPage)) {
      setHasShownAnnouncementBar(true);
    }
  }, [hasShownAnnouncementBar, isOnPdpAdsPage, isSideCartOpen]);

  // Keep previous behavior for other discounts
  if (hidden && discountInfo?.type !== DISCOUNT_TYPES.QUANTITY_PERCENT) {
    return null;
  }

  const announcementTitleElements = getAnnouncementElements(
    announcementTitleElement,
    {
      DISCOUNT: discountText,
      CODE: discountCode,
    },
    textColor
  );

  return (
    <>
      {hasPopup && (
        <ABPopup
          open={popupOpen}
          countryCode={gePriceDetails?.CountryCode}
          onClose={() => setPopupOpen(false)}
        />
      )}
      <div
        className={`${isSticky || isLoopReturnEnabled ? styles.sticky : ""} ${
          isOnPdpAdsPage && !hasShownAnnouncementBar ? styles.mobileHide : ""
        } ab_container`}
        data-testid="announcement-bar"
      >
        {isLoopReturnEnabled ? (
          <AnnouncementBarLoopReturn />
        ) : (
          <AnnouncementBarLayout
            textColor={textColor}
            hasPopup={hasPopup}
            gePriceDetails={gePriceDetails}
            announcementTitleElements={announcementTitleElements}
            setPopupOpen={setPopupOpen}
            hidden={hidden}
          />
        )}
      </div>
    </>
  );
}

export function AnnouncementBarPopupProvider({ children }) {
  const location = useLocation();
  const [popupOpen, setPopupOpen] = useState(location.search.includes("offer_details=true"));

  return (
    <AnnouncementBarPopupContext.Provider value={[popupOpen, setPopupOpen]}>
      {children}
    </AnnouncementBarPopupContext.Provider>
  );
}

export default withALErrorBoundary({
  name: "AnnouncementBar",
  priority: "P1",
})(AnnouncementBar);
