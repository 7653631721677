import PropTypes from "prop-types";
import { useCallback, useContext } from "react";

import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import StoreContext from "../../../context/store";
import { useTracking } from "../../../context/Tracking";
import useCollectionProducts from "../../../hooks/useCollectionProducts";

import OptionalProductsContainer from "./optionalProducts/OptionalProductsContainer";
import OptionalProductsSelection from "./optionalProducts/OptionalProductsSelection";
import OptionalProductsTotalPrice from "./optionalProducts/OptionalProductsTotalPrice";

function OptionalProducts({
  productTitle,
  selectedProducts,
  optionalProductsConfig,
  handleRemoveProduct,
  handleSetProducts,
}) {
  const { setOptionalProductsData } = useContext(StoreContext);
  const {
    trackOptionalProductsDrawerOpen,
    trackOptionalProductsDrawerEdit,
    trackOptionalProductsDrawerConfirmation,
  } = useTracking();

  const { optionalProductsTitle, optionalProductsCollection } = optionalProductsConfig;

  const optionalProducts = useCollectionProducts(optionalProductsCollection.handle, 50, true);

  const onSaveHandlesFromDrawer = useCallback(
    (newSelectedProducts) => {
      setOptionalProductsData(null);
      handleSetProducts(newSelectedProducts);
      trackOptionalProductsDrawerConfirmation({
        originProductTitle: productTitle,
        handles: newSelectedProducts.map((product) => product.handle),
        price: newSelectedProducts.reduce(
          (acc, product) => acc + Number(product.prices?.finalPrice || 0),
          0
        ),
        currency: newSelectedProducts[0].prices?.currencyCode,
      });
    },
    [
      setOptionalProductsData,
      handleSetProducts,
      trackOptionalProductsDrawerConfirmation,
      productTitle,
    ]
  );

  const openDrawer = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setOptionalProductsData({
        originProductTitle: productTitle,
        optionalProductsTitle,
        initialProducts: selectedProducts,
        allProducts: optionalProducts,
        onClose: () => setOptionalProductsData(null),
        onSave: onSaveHandlesFromDrawer,
      });
      if (selectedProducts.length) {
        trackOptionalProductsDrawerEdit({
          originProductTitle: productTitle,
          addedHandles: selectedProducts.map((product) => product.handle),
          price: selectedProducts.reduce(
            (acc, product) => acc + Number(product.prices?.finalPrice || 0),
            0
          ),
          currency: selectedProducts[0].prices?.currencyCode,
        });
      } else {
        trackOptionalProductsDrawerOpen({ originProductTitle: productTitle });
      }
    },
    [
      setOptionalProductsData,
      productTitle,
      optionalProductsTitle,
      selectedProducts,
      onSaveHandlesFromDrawer,
      optionalProducts,
      trackOptionalProductsDrawerEdit,
      trackOptionalProductsDrawerOpen,
    ]
  );

  const hasSelectedProducts = selectedProducts.length > 0;

  if (!optionalProductsTitle || optionalProductsTitle === "null") {
    return null;
  }

  return (
    <OptionalProductsContainer
      openCollectionDrawer={openDrawer}
      optionalProductsConfig={optionalProductsConfig}
      buttonLabel={hasSelectedProducts ? "Edit" : "Add"}
      isLoading={!optionalProducts.length}
    >
      {hasSelectedProducts && (
        <>
          <OptionalProductsSelection
            selectedProducts={selectedProducts}
            handleRemoveProduct={handleRemoveProduct}
          />
          <OptionalProductsTotalPrice
            totalPrice={selectedProducts.reduce(
              (acc, product) => acc + Number(product.prices?.finalPrice || 0),
              0
            )}
            optionalProductsConfig={optionalProductsConfig}
          />
        </>
      )}
    </OptionalProductsContainer>
  );
}

OptionalProducts.propTypes = {
  productTitle: PropTypes.string.isRequired,
  selectedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          altText: PropTypes.string.isRequired,
        })
      ),
      title: PropTypes.string.isRequired,
      prices: PropTypes.shape({
        finalPrice: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  optionalProductsConfig: PropTypes.shape({
    optionalProductsCollection: PropTypes.shape({
      handle: PropTypes.string.isRequired,
    }),
    optionalProductsTitle: PropTypes.string.isRequired,
  }),
  handleRemoveProduct: PropTypes.func.isRequired,
  handleSetProducts: PropTypes.func.isRequired,
};

export default withALErrorBoundary({
  name: "OptionalProducts",
  priority: "P3",
})(OptionalProducts);
