import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";

import IconArrow from "./icons/svgs/arrow.svg";

import "./dropDown/styles.scss";

function Dropdown({ options, sortingType, onSelect, className = "sorting_content" }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dict = useLocalizedSentenceDict();

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionSelect = (optionValue) => {
    onSelect(optionValue);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={className} ref={dropdownRef}>
      <button type="button" className="dropdown_header" onClick={toggleDropdown}>
        <span className="dropdown_header_text subtext subtext--uc">
          {dict.get("SORT")}
          {sortingType !== "all" && sortingType ? (
            <span className="filter_dropdown_value">
              ({options.find((option) => option.value === sortingType)?.label})
            </span>
          ) : null}
        </span>
        <span className="dropdown_header_arrow">
          <IconArrow
            height="14"
            width="8"
            className={`dropdown_arrow_icon ${isOpen ? "open" : "closed"}`}
          />
        </span>
      </button>

      {isOpen && (
        <div className="dropdown_options subtext">
          {options.map((option) => (
            <button
              type="button"
              key={`custom_dropdown_${option.value}`}
              className={`dropdown_option subtext ${
                sortingType === option.value ? "selected_option" : ""
              }`}
              onClick={() => handleOptionSelect(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  sortingType: PropTypes.string,
  className: PropTypes.string,
};

export default Dropdown;
