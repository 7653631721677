import useDeviceDetect from "../../hooks/useDeviceDetect";
import AutoScroll from "../al_components/AutoScroll";
import {
  IconWWD,
  IconVogue,
  IconRefinery29,
  IconGlamour,
  IconBuzzFeed,
  IconInStyle,
  IconCosmopolitan,
  IconElle,
} from "../ALIcons";

import * as Styles from "./styles.module.scss";

export default function SocialProof() {
  const { isMobile } = useDeviceDetect();

  const ICONS = [
    {
      id: "wwd",
      svg: IconWWD,
      height: isMobile ? "10.86" : "20.9",
      width: isMobile ? "37.02" : "71.22",
    },
    {
      id: "vogue",
      svg: IconVogue,
      height: isMobile ? "11.41" : "21.95",
      width: isMobile ? "45.08" : "86.74",
    },
    {
      id: "refinery29",
      svg: IconRefinery29,
      height: isMobile ? "11.41" : "21.95",
      width: isMobile ? "58.38" : "112.31",
    },
    {
      id: "glamour",
      svg: IconGlamour,
      height: isMobile ? "11.41" : "21.95",
      width: isMobile ? "67.9" : "130.63",
    },
    {
      id: "buzzfeed",
      svg: IconBuzzFeed,
      height: isMobile ? "11.08" : "21.32",
      width: isMobile ? "62.59" : "120.42",
    },
    {
      id: "in-style",
      svg: IconInStyle,
      height: isMobile ? "13.58" : "26.13",
      width: isMobile ? "61.38" : "118.09",
    },
    {
      id: "elle",
      svg: IconElle,
      height: isMobile ? "10.86" : "20.9",
      width: isMobile ? "34.3" : "66",
    },
    {
      id: "cosmopolitan",
      svg: IconCosmopolitan,
      height: isMobile ? "10.86" : "20.9",
      width: isMobile ? "63.01" : "121.22",
    },
  ];

  const socialIcons = isMobile ? ICONS : [...ICONS, ...ICONS];

  return (
    <div className={Styles.socialProof__container}>
      <p className={Styles.socialProof__label}>As seen in</p>
      <AutoScroll loop gap={isMobile ? "11px" : "22px"} speed={2}>
        <div>
          {socialIcons.map(({ svg: Icon, height, width, id }, idx) => (
            <span className={Styles.socialProof__icon} key={`${id}_${idx}`}>
              <Icon height={height} width={width} />
            </span>
          ))}
        </div>
      </AutoScroll>
    </div>
  );
}
