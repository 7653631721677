import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { roundNumber } from "../../context/helpers";
import { ProgressBar } from "../ALComponents";

import { useCurrentFormatCurrency } from "../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { Country } from "../../constants/Country";
import Timer from "../Timer";

import "./banner/styles.scss";

function Banner({ sideCartInfo, discount, country, shippingThreshold }) {
  const dict = useLocalizedSentenceDict();
  const formatCurrency = useCurrentFormatCurrency();

  const isUS = country === Country.US;
  const isMaster = discount?.isMaster;

  if (!isUS && sideCartInfo?.finalAmount === 0) {
    return (
      <div className="cart_banner">
        <div className="cart_banner_shipping">
          {dict.get("Free Shipping Above {0}", formatCurrency(shippingThreshold))}
        </div>
        <div className="cart_banner_warranty">
          {dict.get("2-Year Warranty - we’ve got you covered")}
        </div>
      </div>
    );
  }

  if (isUS && (!isMaster || sideCartInfo?.finalAmount === 0)) {
    return (
      <div className="cart_banner">
        <div className="cart_banner_shipping">{dict.get("Free Shipping & Exchanges")}</div>
        <div className="cart_banner_warranty">
          {dict.get("2-Year Warranty - we’ve got you covered")}
        </div>
      </div>
    );
  }

  if (isUS && isMaster) {
    return (
      <div className="cart_banner">
        <div className="cart_banner_shipping">{dict.get("Free Shipping & Exchanges")}</div>
        <div className="cart_banner_timer">
          {dict.get("It's in the bag - we'll save it for")}
          &nbsp;
          <Timer />
        </div>
      </div>
    );
  }

  if (!isUS && isMaster) {
    return (
      <div className="cart_banner">
        <div className="cart_banner_shipping">
          {dict.get("Free Shipping Above {0}", formatCurrency(shippingThreshold))}
        </div>
        <div className="cart_banner_timer">
          {dict.get("It's in the bag - we'll save it for")}
          &nbsp;
          <Timer />
        </div>
      </div>
    );
  }

  const percentToFreeShipping = (sideCartInfo.finalAmount / shippingThreshold) * 100;

  if (percentToFreeShipping < 100) {
    const freeShippingTitle = dict.get(
      `Spend **{0}** more for **FREE Shipping!**`,
      formatCurrency(roundNumber(shippingThreshold - sideCartInfo.finalAmount))
    );

    return (
      <div className="cart_banner">
        <div className="cart_banner_shipping_title">{freeShippingTitle}</div>
        <ProgressBar
          type="rounded"
          value={sideCartInfo.finalAmount}
          maxValue={shippingThreshold}
          steps={[
            { value: 0, label: formatCurrency(0) },
            {
              value: shippingThreshold,
              label: formatCurrency(shippingThreshold),
            },
          ]}
        />
      </div>
    );
  }

  return (
    <div className="cart_banner">
      <div className="cart_banner_shipping">
        {dict.get(`Free Shipping Above ${formatCurrency(shippingThreshold)} `)}
      </div>
      <div className="cart_banner_warranty">
        {dict.get("2-Year Warranty - we’ve got you covered")}
      </div>
    </div>
  );
}

Banner.propTypes = {
  sideCartInfo: PropTypes.shape({
    finalAmount: PropTypes.number,
    freegiftLineItemId: PropTypes.object,
    fullAmount: PropTypes.number,
    lineItems: PropTypes.array,
    nonBannedItemQuantity: PropTypes.number,
  }),
  discount: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  country: PropTypes.string,
  shippingThreshold: PropTypes.number,
};

export default withALErrorBoundary({
  name: "Banner",
  priority: "P2",
})(Banner);
