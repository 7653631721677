import { useContext, useEffect, useState } from "react";
import sample from "lodash/sample";

import { isBrowser } from "../context/helpers";
import StoreContext from "../context/store";
import { useProductData2 } from "../context/products";
import { useCurrentCountryCode } from "./usePrices";

import { ComponentType } from "../constants/ComponentType";

const recommendationIds = ["619689c476110058ec2b232a", "624c500a380a2c120559b881"];

function fetchRecommendedProductHandles(recommendationId) {
  return new Promise((resolve, reject) => {
    if (isBrowser) {
      try {
        let _recoId = recommendationId;
        // Allow AB Testing of Recommendation IDs
        if (recommendationId.includes(",")) {
          _recoId =
            recommendationId.split(",")[Math.floor(Math.random() * recommendationId.length)];
        }
        let timeout;
        const options = {
          recommendationId: _recoId,
          callback(data) {
            clearTimeout(timeout);
            if (data && data.length > 0) {
              const arrHandles = data
                .reduce((acc, curVal) => acc.concat(curVal._handle), [])
                .splice(0, 15);
              resolve(arrHandles);
            } else {
              reject("No recommendations found");
            }
          },
          size: 10, // default value
          fillWithRandom: true,
          catalogAttributesWhitelist: ["_handle"], // get only handles
        };
        if (typeof window.exponea?.getRecommendation === "function") {
          timeout = setTimeout(() => reject("Exponea call failed: CORS issue on Safari?"), 5000);
          // This function eats the error when a CORS issue occurs on Safari
          window.exponea.getRecommendation(options);
        } else {
          reject("Exponea not initialized");
        }
      } catch (error) {
        reject(error);
      }
    } else {
      resolve([]);
    }
  });
}

export default function useRecommendation(
  type = null,
  mustIncludeHandles = [],
  productFilterCallback = (product) => product
) {
  const [recommendedProductHandles, setRecommendedProductHandles] = useState([]);
  const [recommendationId, setRecommendationId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(StoreContext);
  const { recommendationExponea } = context.store;
  const countryCode = useCurrentCountryCode();
  const { data: products } = useProductData2(
    [...mustIncludeHandles, ...recommendedProductHandles],
    "long",
    countryCode
  );

  useEffect(() => {
    const defaultRecommendation = recommendationExponea?.find(
      (r) => r.node.type[0] === type && r.node.country[0].code === "RoW"
    );
    const recommendation = recommendationExponea?.find(
      (r) => r.node.type[0] === type && countryCode && countryCode === r.node.country[0].code
    );

    let recId = null;
    if (recommendation?.node?.recommendationId) {
      recId = recommendation.node.recommendationId;
    } else if (defaultRecommendation?.node?.recommendationId) {
      recId = defaultRecommendation.node.recommendationId;
    } else if (type === "Side Cart") {
      // For Side Cart recommendations, select a random id from recommendationsIds
      recId = sample(recommendationIds);
    } else if (type === ComponentType.PDP) {
      [recId] = recommendationIds;
    }

    // Fallback to default recommendation id
    if (!recId) {
      [recId] = recommendationIds;
    }

    setRecommendationId(recId);
    setIsLoading(true);
    if (type !== "Side Cart") {
      fetchRecommendedProductHandles(recId)
        .then((handles) => {
          setRecommendedProductHandles(handles);
        })
        .finally(() => setIsLoading(false));
    }
  }, [recommendationExponea, countryCode, type]);

  return [isLoading, products.filter(productFilterCallback), recommendationId];
}
