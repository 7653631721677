import PropTypes from "prop-types";
import { useContext, useRef, useState } from "react";

import { ComponentType } from "../../constants/ComponentType";
import { BYOBProductDetailPopupContext } from "../../context/BYOBProductDetailPopup";
import { BYOBContext } from "../../context/BYOBundle";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { useCurrentCountryCode } from "../../hooks/usePrices";

import RadioVariants from "../AddToCart/RadioVariants";
import { ALButton } from "../ALComponents";

import IconClose from "../icons/svgs/close.svg";

import "./buttonAddToBundle/ButtonAddToBundle.scss";

function ButtonAddToBundle({ product, selectedVariant, componentType }) {
  const countryCode = useCurrentCountryCode();
  const { addProductToCustomBundle, byobSize, productList } = useContext(BYOBContext);
  const { turnOffPopup } = useContext(BYOBProductDetailPopupContext);
  const [showVariants, toggleShowVariants] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const ref = useRef();
  const dict = useLocalizedSentenceDict();
  const { variants } = product;
  const hasMultipleVariants = variants?.length > 1;
  const disabled = productList.length >= byobSize;

  const handleButtonClick = (e) => {
    setIsButtonActive(true);

    setTimeout(() => {
      setIsButtonActive(false);
    }, 1000);
    let variantIndex = 0;

    e.preventDefault();
    e.stopPropagation();
    if (!selectedVariant && hasMultipleVariants) {
      toggleShowVariants(true);
      setTimeout(() => ref.current.focus(), 200);
      return;
    }
    if (selectedVariant) {
      variantIndex = product.variants.findIndex(
        (v) => v.title === selectedVariant[product.options[0].name]
      );
      variantIndex = variantIndex !== -1 ? variantIndex : 0;
    }
    addProductToCustomBundle(product, variantIndex);
    turnOffPopup();
  };
  const handleVariantClick = (variantIndex) => {
    toggleShowVariants(false);
    addProductToCustomBundle(product, variantIndex);
    ref.current.blur();
    turnOffPopup();
  };

  return (
    <div className="button_add_to_bundle">
      <div
        className={`
          ${disabled ? "button_add_to_bundle_content--disabled" : ""}
          ${isButtonActive ? "active" : ""}`}
        tabIndex={-1}
        ref={ref}
        onBlur={() => toggleShowVariants(false)}
      >
        {showVariants ? (
          <div className="button_add_to_bundle_variant_container">
            <ALButton
              className="button_add_to_bundle_variant_container_close"
              variant={componentType === ComponentType.BYOB_PDP ? "primary" : "secondary"}
              onClick={() => toggleShowVariants(false)}
            >
              <IconClose width="20" height="20" fill="black" />
            </ALButton>
            {variants.map((variant, index) => (
              <RadioVariants
                key={index}
                variant={variant}
                product={{ id: product?.shopifyId }}
                productSize={variant.title}
                handleOnChangeSize={() => handleVariantClick(index)}
                componentType={ComponentType.BYOB}
                countryCode={countryCode}
              />
            ))}
          </div>
        ) : (
          <ALButton
            variant={componentType === ComponentType.BYOB_PDP ? "primary" : "secondary"}
            fullWidth
            className="button_add_to_bundle_cta"
            onClick={handleButtonClick}
            disabled={disabled}
          >
            {dict.get("Add to bundle")}
          </ALButton>
        )}
      </div>
    </div>
  );
}

ButtonAddToBundle.propType = {
  product: PropTypes.shape({
    variants: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
    shopifyId: PropTypes.string,
  }).isRequired,
  onProductAdd: PropTypes.func,
};

export default withALErrorBoundary({
  name: "ButtonAddToBundle",
  priority: "P1",
})(ButtonAddToBundle);
