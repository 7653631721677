import { useStaticQuery, graphql } from "gatsby";
import { useLocalizedCartInfo } from "./useLocalizedCartInfo";
import useRecommendation from "./useRecommendation";
import TAG from "../constants/Tag";
import DISCOUNT_TYPES from "../constants/DiscountTypes";

function countUniqueProductTypes(items) {
  // Extract product types
  const productTypes = items?.reduce((red, p) => {
    const type = p?.variant?.product?.tags?.find((e) => e.includes("Type:"));
    if (type) {
      for (let i = 0; i < p?.quantity; i++) {
        red.push(type.split(":")[1]);
      }
    }
    return red;
  }, []);

  const uniqueValues = {};

  // Loop through the array and count the occurrences of each value
  for (let i = 0; i < productTypes.length; i++) {
    const value = productTypes[i];
    if (uniqueValues[value]) {
      uniqueValues[value] += 1;
    } else {
      uniqueValues[value] = 1;
    }
  }

  // Sort the unique values based on their count in descending order
  const sortedUniqueValues = Object.keys(uniqueValues).sort(
    (a, b) => uniqueValues[b] - uniqueValues[a]
  );

  // Return the sorted unique values as an array
  return sortedUniqueValues;
}

export default function useUpSellProducts(lineItems = [], discountInfo = {}) {
  const cartInfo = useLocalizedCartInfo();
  const handlesToExclude = new Set();
  const arrUpsellProducts = [];

  cartInfo.lines.forEach((line) => {
    handlesToExclude.add(line.variant.product.handle);
  });

  if (discountInfo?.type === DISCOUNT_TYPES.QUANTITY_PERCENT) {
    // Upsell Best Sellers
    const bestSellersData = useStaticQuery(graphql`
      query BestSellersQuery {
        allShopifyCollection(filter: { handle: { eq: "bestsellers" } }, limit: 1) {
          edges {
            node {
              handle
              products {
                handle
                shopifyId
                tags
                variants {
                  inventoryPolicy
                }
              }
            }
          }
        }
      }
    `);
    const productsBestSellers =
      bestSellersData?.allShopifyCollection?.edges[0]?.node?.products || [];
    for (let i = 0; i < productsBestSellers.length; i += 1) {
      if (arrUpsellProducts.length > 5) {
        break;
      }
      if (
        !(productsBestSellers[i]?.tags || []).some((tag) =>
          discountInfo?.exclusionTags.includes(tag)
        ) &&
        !productsBestSellers[i].variants.some((v) => v.inventoryPolicy === "CONTINUE") // Do not product in pre-order
      ) {
        arrUpsellProducts.push(productsBestSellers[i]?.handle);
      }
    }
  } else {
    // Upsell Mystery Products
    const typeInCart = countUniqueProductTypes(lineItems);
    const hasMysteryItemInCart =
      lineItems.length === 1 && lineItems[0].variant.product.tags.includes(TAG.MYSTERY);

    const mysteryProducts = {
      Earrings: "mystery-earrings",
      Necklaces: "mystery-necklace",
      Bracelets: "mystery-bracelet",
      other: "mystery-bundle",
    };

    if (!hasMysteryItemInCart) {
      arrUpsellProducts.push(mysteryProducts[typeInCart[0]] || mysteryProducts.other);
      Object.values(mysteryProducts).forEach((e) => {
        if (!arrUpsellProducts.includes(e)) {
          arrUpsellProducts.push(e);
        }
      });
    }
  }

  const [, recommendedProducts, recommendationId] = useRecommendation(
    "Side Cart",
    arrUpsellProducts,
    (product) =>
      !product ||
      (!handlesToExclude.has(product.shopify.handle) &&
        product.shopify.variants.length === 1 &&
        product.shopify.variants[0].availableForSale === true)
  );

  const recommendedData = recommendedProducts
    .filter((product) => product)
    .slice(0, 3)
    .map((p) => ({ ...p.shopify, title: p.contentful.title }));

  return {
    data: recommendedData,
    isLoading: recommendedProducts.some((product) => product === undefined),
    id: recommendationId,
  };
}
