import { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useAuth } from "../../context/AuthProvider";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";

import { ALLoading, ALButton } from "../ALComponents";
import IconClose from "../icons/svgs/close-round.svg";
import "./accountPopup.scss";

function Login({ onClose }) {
  const dict = useLocalizedSentenceDict();

  const { showCreateAccount, showConfirmation, login, isLoading } = useAuth();
  const { register, handleSubmit } = useForm();
  const [error, setError] = useState(null);

  const onSubmit = (data) => {
    login(data.email).then((res) => {
      if (res) {
        showConfirmation({
          text: dict.get("Check your inbox or spam folder to click on your link."),
        });
      } else {
        setError(
          dict.get(
            "Email is incorrect. If you haven't created an account yet, you can [join here](/?auth=signup)"
          )
        );
      }
    });
  };

  return (
    <form className="account-form" onSubmit={handleSubmit(onSubmit)}>
      <button type="button" className="close-icon" onClick={onClose}>
        <IconClose width="30" fill="none" stroke="#2D2927" />
      </button>
      <h2 className="h2 m-0 pb-10">{dict.get("Member Login")}</h2>
      <p className="mb-20">
        {dict.get("Login for")}{" "}
        <span className="al_p--bold">{dict.get("faster checkout, order updates, and more!")}</span>
      </p>
      <input
        placeholder={dict.get("Email")}
        type="email"
        className="account_input"
        required
        {...register("email")}
      />

      <ALButton
        type="submit"
        variant="primary"
        fullWidth
        size="large"
        className="submit-btn-login"
        disabled={isLoading}
      >
        {isLoading ? <ALLoading fill="white" /> : dict.get("Email My Link")}
      </ALButton>

      {error && <div className="error">{error}</div>}

      <p className="pt-20">
        {dict.get("Dont’t have an account?")}{" "}
        <button className="secondary-action login-button" type="button" onClick={showCreateAccount}>
          {dict.get("Join here")}
        </button>
      </p>
    </form>
  );
}

Login.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default withALErrorBoundary({
  name: "Login",
  priority: "P1",
})(Login);
