import PropTypes from "prop-types";

function ALSkeleton({ width = "100%", height = "100%", position = "relative" }) {
  return (
    <div
      className="al_loading_container"
      style={{ width, height, position }}
      data-testid="al-skeleton"
    >
      <span className="al_loading" />
    </div>
  );
}

ALSkeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default ALSkeleton;
