import { PropTypes } from "prop-types";
import { withALErrorBoundary } from "../../../../helpers/ErrorBoundary/ALErrorBoundary";

function MobileMenuButton({
  handleClick,
  status,
  title,
  color = "#2d2927",
  backgroundColor,
  image,
  useImageAsMenu,
}) {
  return (
    <button
      type="button"
      className={`mobile_menu_container_link mobile_menu_container_link_${title
        .replace(" ", "")
        .toLowerCase()} ${useImageAsMenu ? "mobile_menu_container_image" : ""}`}
      onClick={handleClick}
      aria-expanded={status && status[title]}
      style={{
        color,
        backgroundColor: !useImageAsMenu ? backgroundColor : "unset",
        backgroundImage: image ? `url(${image.mobileImage.file.url})` : "none",
      }}
    >
      {!useImageAsMenu && title}
    </button>
  );
}

MobileMenuButton.propTypes = {
  handleClick: PropTypes.func,
  status: PropTypes.shape({}),
  title: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  useImageAsMenu: PropTypes.bool,
  image: PropTypes.shape({
    altText: PropTypes.string,
    mobileImage: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
};

export default withALErrorBoundary({
  name: "MobileMenuButton",
  priority: "P2",
})(MobileMenuButton);
