// extracted by mini-css-extract-plugin
export var completed = "styles-module--completed--2127d";
export var container = "styles-module--container--2dd29";
export var dotContainer = "styles-module--dot-container--5a65f";
export var dotContainerBottomBanner = "styles-module--dot-container--bottom-banner--03a10";
export var dotted = "styles-module--dotted--be659";
export var fullBar = "styles-module--fullBar--4561d";
export var fullBarBottomBanner = "styles-module--fullBar--bottom-banner--45471";
export var iconContainer = "styles-module--icon-container--7ed00";
export var label = "styles-module--label--5d8ae";
export var label_left = "styles-module--label_left--acea5";
export var label_right = "styles-module--label_right--aa061";
export var luxeActive = "styles-module--luxe-active--63df8";
export var progress = "styles-module--progress--cd4c6";
export var progressBottomBanner = "styles-module--progress--bottom-banner--4d20c";
export var regular_container = "styles-module--regular_container--26da7";
export var rounded_container = "styles-module--rounded_container--96b25";
export var steps__progressBar = "styles-module--steps__progress-bar--b58c0";
export var withIcons = "styles-module--withIcons--4ac20";