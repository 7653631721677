import { useState, useEffect, useContext, useMemo } from "react";
import Popup from "reactjs-popup";
import debounce from "lodash/debounce";

import { ALFlags } from "../ALComponents";
import IconClose from "../icons/svgs/close.svg";
import {
  createCookie,
  createRootDomainCookie,
  isBrowser,
  getAllUrlParams,
  removeURLParam,
} from "../../context/helpers";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { GeoContext } from "../../context/GeoProvider";
import LocalePicker from "./LocalePicker";
import LocalesPopUpTrigger from "./LocalesPopupTrigger";
import ALSearchBox from "../al_components/ALSearchBox";
import LocalePickerSearchResults from "./LocalePickerSearchResults";
import localeFlag from "../commons/LocaleFlag";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { useTracking } from "../../context/Tracking";
import { locales } from "../../../locales";

import "./localesPopUp.scss";

function LocalesPopUp({ showCountry, showISO, className, isMobileMenu, componentType }) {
  const { gePriceDetails } = useContext(GeoContext);
  const { trackCountryUpdate } = useTracking();
  const currentCountry = gePriceDetails?.CountryName; // set default locale if needed
  const currentCurrency = gePriceDetails?.CurrencyCode;
  const currentISO = gePriceDetails?.CountryCode;

  const [searchResults, updateSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);

  const params = getAllUrlParams();
  const { glCountry, glCurrency } = params || {};
  const internationalLocale =
    isBrowser && "glCountry" in params && "glCurrency" in params
      ? {
          country: glCountry.toUpperCase(),
          currency: glCurrency.toUpperCase(),
        }
      : null;

  const internationalUrl = internationalLocale;

  const handleCountryChange = (isInternational, countryName, lang) => {
    let country;
    if (isInternational) {
      country = locales.find((l) => l.country == internationalUrl.country);
    } else {
      country = locales.find((c) => c.countryName == countryName && c.locale === lang);
    }

    if (country) {
      const finalObject = {
        countryISO: country.country,
        currencyCode: country.currency,
        cultureCode: country.locale,
        countryName: country.countryName,
        isCustom: true,
      };

      trackCountryUpdate(country);
      createCookie("GlobalE_Data", encodeURIComponent(JSON.stringify(finalObject)), 365);
      createRootDomainCookie("GlobalE_Data", encodeURIComponent(JSON.stringify(finalObject)), 365);

      // remove glCountry & glCurrency params (DO NOT REMOVE)
      removeURLParam("glCountry", null, true);
      removeURLParam("glCurrency", null, true);
      window.location.reload();
    }
  };

  const handleSearch = () => {
    let matchingValues = [];

    if (searchQuery !== "" && !!locales) {
      matchingValues = locales.filter((locale) => {
        const countryName = locale.countryName.toLowerCase();
        const currency = locale.currency.toLowerCase();
        const country = locale.country.toLowerCase();

        return (
          countryName.includes(searchQuery) ||
          currency.includes(searchQuery) ||
          country.includes(searchQuery)
        );
      });
    }

    updateSearchResults(matchingValues);
  };

  const onChangeHandler = (value) => {
    setSearchQuery(value.toLowerCase());
  };

  const debouncedChangeHandler = useMemo(() => debounce(onChangeHandler, 300), []);

  useEffect(
    () => () => {
      debouncedChangeHandler.cancel();
    },
    [debouncedChangeHandler]
  );

  useEffect(() => {
    if (internationalUrl && locales && gePriceDetails && "CountryName" in gePriceDetails) {
      try {
        const internationalLocale = locales.find(
          (locale) => locale.country == internationalUrl.country
        );
        if (internationalLocale && internationalLocale.country != gePriceDetails.CountryCode) {
          handleCountryChange(true);
        }
      } catch (error) {
        console.log("Error Setup from URL params");
        console.log(error);
      }
    }
  }, [internationalUrl, gePriceDetails]);

  useEffect(() => {
    handleSearch();
  }, [searchQuery]);

  const hasFlag = localeFlag[currentISO];
  const dict = useLocalizedSentenceDict();

  return locales ? (
    <div className={`locales ${className || ""}`}>
      <Popup
        className="locales-popup"
        trigger={(open) =>
          gePriceDetails ? (
            <LocalesPopUpTrigger
              country={currentCountry}
              currency={currentCurrency}
              countryISO={currentISO}
              showISO={showISO}
              showCountry={showCountry}
              onClick={open}
              isMobileMenu={isMobileMenu}
              componentType={componentType}
            />
          ) : (
            <div />
          )
        }
        modal
        nested
        contentStyle={{ margin: "0" }}
      >
        {(close) => (
          <div className="locales-popup">
            <button className="locales-popup__close" onClick={close}>
              <IconClose width="30" fill="2D2927" />
            </button>
            <h1 className="locales-popup__title al_h3">{dict.get("Select your location")}</h1>
            <div className="locales-popup__current">
              <div className="locales-popup__current-flag">
                {hasFlag && <ALFlags countryISO={currentISO} />}
                {!hasFlag && showISO && <span>{currentISO}</span>}
                {showCountry && <span className="al_p">{currentCountry}&nbsp;</span>}
                <span className="al_p">{`(${currentCurrency})`}</span>
              </div>
              <p className="al_p locales-popup__current-text">
                {currentCountry && currentCurrency
                  ? dict.get(
                      "Your current location is {0} and your order will be billed in {1}",
                      currentCountry,
                      currentCurrency
                    )
                  : null}
              </p>
            </div>
            <div className="locales-popup__search">
              <ALSearchBox
                onChange={debouncedChangeHandler}
                placeholder="Search by country or currency"
              />
            </div>
            {searchResults.length > 0 && (
              <div className="locales-popup__search-results">
                <LocalePickerSearchResults
                  results={searchResults}
                  handleClick={handleCountryChange}
                />
              </div>
            )}
            {searchResults.length === 0 && (
              <div className="locales-popup__countries-wrapper">
                <LocalePicker handleClick={handleCountryChange} />
              </div>
            )}
          </div>
        )}
      </Popup>
    </div>
  ) : null;
}

export default withALErrorBoundary({
  name: "LocalesPopUp",
  priority: "P3",
})(LocalesPopUp);
