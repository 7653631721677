export function CalculatePrice(value) {
  return value;
}

export function ConvertRoundingRangeToAbsolute(price, range) {
  let result = null;
  if (range.RangeBehavior == 1) {
    // range already has absolute behavior
    result = range;
  } else {
    result = {};
    result.RangeBehavior = range.RangeBehavior;
    result.RoundingExceptions = [];
    result.From = range.From;
    result.To = range.To;
    const int_part = Math.floor(price);
    let base = null;
    if (range.RangeBehavior == 2) {
      // Relative Decimal
      result.LowerTarget = int_part - 1 + range.LowerTarget;
      result.UpperTarget = int_part + range.UpperTarget;
      result.Threshold = int_part + range.Threshold;

      for (const ex in range.RoundingExceptions) {
        range.RoundingExceptions[ex].ExceptionValue += int_part;
        result.RoundingExceptions.push(range.RoundingExceptions[ex]);
      }
    } else if (range.RangeBehavior == 3) {
      // Relative Whole
      if (range.TargetBehaviorHelperValue == 0) {
        range.TargetBehaviorHelperValue = 10;
      }
      base = Math.floor(price / range.TargetBehaviorHelperValue) * range.TargetBehaviorHelperValue;
      result.LowerTarget = base - range.TargetBehaviorHelperValue + range.LowerTarget;
      result.UpperTarget = base + range.UpperTarget;
      result.Threshold = base + range.Threshold;
      for (const ex2 in range.RoundingExceptions) {
        range.RoundingExceptions[ex2].ExceptionValue += base;
        result.RoundingExceptions.push(range.RoundingExceptions[ex2]);
      }
    } else if (range.RangeBehavior == 4) {
      // Nearest target
      if (range.TargetBehaviorHelperValue == 0) {
        range.TargetBehaviorHelperValue = 5;
      }
      base = Math.floor(price / range.TargetBehaviorHelperValue) * range.TargetBehaviorHelperValue;
      result.LowerTarget = base - 1 + range.LowerTarget;
      result.UpperTarget = base - 1 + range.TargetBehaviorHelperValue + range.UpperTarget;

      result.Threshold = base + range.Threshold;
      for (const ex3 in range.RoundingExceptions) {
        range.RoundingExceptions[ex3].ExceptionValue += base;
        result.RoundingExceptions.push(range.RoundingExceptions[ex3]);
      }
    }
  }

  return result;
}

export function AbsoluteRounding(price, range) {
  let result = null;
  // check exceptions
  if (range.RoundingExceptions.length > 0) {
    for (const e in range.RoundingExceptions) {
      const ex = range.RoundingExceptions[e];
      if (price == ex.ExceptionValue) {
        result = price;
      }
    }
  }
  // no exception was found
  if (!result) {
    // check threshold
    if (price < range.Threshold) {
      result = range.LowerTarget;
    } else {
      result = range.UpperTarget;
    }
  }
  return result;
}
