import aa from "search-insights";

export function algoliaProductClickAfterSearch(
  userToken,
  indexName,
  queryID,
  objectIDs,
  positions
) {
  aa("clickedObjectIDsAfterSearch", {
    userToken,
    index: indexName,
    eventName: "PLP: Product Clicked",
    queryID,
    objectIDs,
    positions,
  });
}

export function algoliaConvertedObjectIDsAfterSearch(userToken, index, queryID, objectID) {
  aa("convertedObjectIDsAfterSearch", {
    userToken,
    index,
    eventName: "PDP: Product Added to Cart after Search",
    queryID,
    objectIDs: [objectID],
  });
}

export function algoliaProductAddedToCart(userToken, index, objectIDs) {
  aa("convertedObjectIDs", {
    userToken,
    index,
    eventName: "Product Added to Cart",
    objectIDs: [objectIDs],
  });
}
