// eslint-disable-next-line import/no-unresolved
import { IntelligemsGatsbyProvider } from "@intelligems/headless/gatsby";
import { getShopifyStoreByCountry } from "../../locale-shopifies";
import { useCurrentCountryCode } from "../hooks/usePrices";

export default function IntelligemsProvider({ children }) {
  const countryCode = useCurrentCountryCode();
  const config = getShopifyStoreByCountry(countryCode);

  return (
    <IntelligemsGatsbyProvider
      organizationId={process.env.GATSBY_INTELLIGEMS_ORG_ID}
      storefrontApiToken={config.headlessStorefrontAccessToken}
      antiFlicker
    >
      {children}
    </IntelligemsGatsbyProvider>
  );
}
