import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import IconClose from "./icons/svgs/close.svg";
import { ALButton } from "./ALComponents";
import { useCurrentFormatCurrency } from "../hooks/usePrices";
import { ComponentType } from "../constants/ComponentType";
import { Country } from "../constants/Country";
import { HandleType } from "../constants/HandleType";
import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import useDeviceDetect from "../hooks/useDeviceDetect";

import KlarnaLogoIcon from "./icons/svgs/klarna-logo.svg";

import "./klarnaInformation/styles.scss";

const MIN_PRICE_THRESHOLD = 3499;
const KLARNA_COUNTRIES = [Country.US, Country.DE, Country.NL];

function KlarnaInformation({ prices, type, handle, country }) {
  const dict = useLocalizedSentenceDict();
  const formatCurrency = useCurrentFormatCurrency();
  const { isMobile } = useDeviceDetect();
  const [finalPrice, setFinalPrice] = useState(0);

  useEffect(() => {
    let calculatedPrice = 0;
    if (prices && type === ComponentType.PDP) {
      calculatedPrice = prices?.final_price
        ? parseFloat(prices.final_price) * 100
        : parseFloat(prices.full_price) * 100;
    }
    setFinalPrice(parseInt(calculatedPrice, 10));
  }, [type, prices]);

  if (!KLARNA_COUNTRIES.includes(country) || handle === HandleType.GIFT_CARD) {
    return null;
  }

  return finalPrice && finalPrice > MIN_PRICE_THRESHOLD ? (
    <div className={`pdp_klarna ${type === ComponentType.SIDE_CART ? "sidecart_klarna" : ""}`}>
      {dict.get(
        "4 interest-free payments of {0} with",
        formatCurrency((finalPrice / 100 / 4).toFixed(2))
      )}{" "}
      <div className="klarna_color_logo">
        <KlarnaLogoIcon height={isMobile ? "10" : "12"} fill="#000000" />
      </div>
      <Popup
        trigger={<span className="pdp_klarna_learn">{dict.get("Learn more")}</span>}
        modal
        nested
      >
        {(close) => (
          <div className="pdp_klarna_popup">
            <button type="button" className="pdp_klarna_popup_close" onClick={close}>
              <IconClose height="30" width="30" />
            </button>
            <div className="pdp_klarna_popup_content">
              <div className="pdp_klarna_popup_content_logo">
                <KlarnaLogoIcon fill="rgba(150, 147, 145, 1)" width="81" />
              </div>
              <p className="pdp_klarna_popup_content_title al_h3">
                {dict.get("4 interest-free payments of {0}", formatCurrency(finalPrice / 4 / 100))}
              </p>
              <p className="pdp_klarna_popup_content_subtitle al_p">
                {dict.get("Buy what you love and split the cost. It's easy and interest-free")}
              </p>
              <div className="pdp_klarna_popup_content_steps">
                <p className="pdp_klarna_popup_content_step al_p">
                  <span className="pdp_klarna_popup_content_step_square" />
                  {dict.get("Add item(s) to your cart")}
                </p>
                <p className="pdp_klarna_popup_content_step al_p" style={{ alignItems: "center" }}>
                  <span className="pdp_klarna_popup_content_step_square" />
                  {dict.get("Go to checkout and choose")}{" "}
                  <KlarnaLogoIcon
                    width="38"
                    fill="#000000"
                    className="pdp_klarna_popup_klarna-pink-wrapper"
                  />
                </p>
                <p className="pdp_klarna_popup_content_step al_p">
                  <span className="pdp_klarna_popup_content_step_square" />
                  {dict.get("Enter your debit or credit card information")}
                </p>
                <p className="pdp_klarna_popup_content_step al_p">
                  <span className="pdp_klarna_popup_content_step_square" />
                  <span className="pdp_klarna_popup_content_step_detail">
                    {dict.get(
                      "Your first payment is taken when the order is processed and the remaining 3 are automatically taken every two weeks"
                    )}
                  </span>
                </p>
              </div>
              <p className="pdp_klarna_popup_content_details al_subtext">
                {dict.get(
                  'See payment [terms](https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/sliceitinx){:target="_blank"}. A higher initial payment may be required for some consumers. CA residents: Loans made or arranged pursuant to a California Finance Lenders Law license.'
                )}
              </p>
              <ALButton size="large" fullWidth className="mt-20" onClick={close}>
                {dict.get("CLOSE")}
              </ALButton>
            </div>
          </div>
        )}
      </Popup>
    </div>
  ) : null;
}

export default KlarnaInformation;
