import { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { gaCustomEvent, isOutOfStock, createCookie } from "../../context/helpers";
import StoreContext from "../../context/store";
import { WishlistContext } from "../../context/wishlistProvider";
import { BYOBContext } from "../../context/BYOBundle";
import { DiscountContext } from "../../context/DiscountProvider";
import { useTracking } from "../../context/Tracking";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentCountryCode, useProductPrices } from "../../hooks/usePrices";
import useGetABTest from "../../hooks/useCustomABTest";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useCart from "../../hooks/useCart";
import useCheckInventory from "../../hooks/useCheckInventory";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import PriceContainer from "../PriceContainer";
import QuickAddToCart from "./productCollection/QuickAddToCart";
import ButtonAddToBundle from "../byobCollection/ButtonAddToBundle";
import ProductImage from "./productCollection/ProductImage";
import ProductAlternatives from "../ProductAlternatives";
import ProductMention from "../ProductMention";
import { ALLink as Link, ALTag } from "../ALComponents";
import HeartIcon from "../icons/svgs/heart.svg";
import SearchIcon from "../icons/svgs/search-md.svg";
import { ActionType } from "../../constants/ActionType";
import { ComponentType } from "../../constants/ComponentType";
import { HandleType } from "../../constants/HandleType";
import { Country } from "../../constants/Country";
import RESTRICTED_HTS_PER_COUNTRY from "../../constants/RestrictedHtsPerCountry";

function ProductCollectionOnClick({ componentType, to, onClick, children, searchObject }) {
  if (componentType === ComponentType.BYOB) {
    return (
      <div className="product-collection-on-click" onClick={onClick}>
        {children}
      </div>
    );
  }
  return (
    <Link to={to} state={{ searchObject }} onClick={onClick}>
      {children}
    </Link>
  );
}

function ProductCollectionContent(props) {
  const {
    collectionHandle,
    collectionTitle,
    product: productWithOldInventoryData,
    componentType,
    cpPosition,
    searchObject,
    className,
    setActiveAlternative,
    prices,
  } = props;
  const contentfulProduct = productWithOldInventoryData.contentful.node;
  const { localizedDiscount } = useContext(DiscountContext);
  const context = useContext(StoreContext);
  const wishlistContext = useContext(WishlistContext);
  const { byobPercentageOff } = useContext(BYOBContext);
  const dict = useLocalizedSentenceDict();
  const { trackProductClick } = useTracking();
  const countryCode = useCurrentCountryCode();
  const price = useMemo(
    () => prices?.find((p) => p?.node?.country === countryCode)?.node,
    [prices, countryCode]
  );
  const productPrices = useProductPrices({
    product: productWithOldInventoryData.node,
    shouldUseDiscount: [ComponentType.BYOB, ComponentType.BYOB_PDP].indexOf(componentType) === -1,
    localPrice: price,
  });
  const { variants: productVariantsInventory } = useCheckInventory(
    productWithOldInventoryData.contentful.node.handle
  );

  const productWithInventory = useMemo(
    () => ({
      ...productWithOldInventoryData,
      node: {
        ...productWithOldInventoryData.node,
        variants: productWithOldInventoryData.node.variants.map((v) => {
          const currentVariantInventory = productVariantsInventory.find(
            (variantInventory) => variantInventory?.sku === v?.sku
          );
          return currentVariantInventory
            ? { ...v, availableForSale: currentVariantInventory.availableForSale }
            : v;
        }),
      },
    }),
    [productWithOldInventoryData, productVariantsInventory]
  );
  const shopifyProduct = productWithInventory.node;
  const { variants, handle, shopifyId: productId, title, productType, tags } = shopifyProduct;

  const isSoldOut = isOutOfStock(shopifyProduct, countryCode) && handle !== HandleType.GIFT_CARD;
  const isPreOrder = contentfulProduct?.isPreOrder;

  // Check if all products are not available
  const hasNoVariantsAvailable = variants?.every(
    (x) => x.availableForSale === true && x.inventoryPolicy !== "DENY"
  );

  // Only display PreOrder content when use is browsing US country
  const shouldDisplayPreOrder = isPreOrder && countryCode === Country.US && hasNoVariantsAvailable;

  const { quickAddToCart } = useCart(productWithInventory, productPrices);
  const { isMobile } = useDeviceDetect();
  const linkToPDP = `/${
    componentType === ComponentType.PDP_ADS && useGetABTest(0.5, "lp_ads") === "A"
      ? "p"
      : "products"
  }/${contentfulProduct.handle}/`;
  // const linkToPDP = `/products/${contentfulProduct.handle}/`;

  const isPurchaseRestricted = !!(RESTRICTED_HTS_PER_COUNTRY[countryCode] || []).find((code) =>
    productWithInventory?.contentful?.node?.hts?.startsWith(code)
  );

  if (!shopifyProduct) {
    return null;
  }

  const toggleWishlist = (e, action) => {
    e.preventDefault();
    if (action === ActionType.ADD) {
      gaCustomEvent(`${collectionTitle} - Add to Wishlist`, "Click", shopifyProduct.title);
      if (shopifyProduct) {
        wishlistContext.addItemToWishlist({
          type: shopifyProduct.productType,
          productHandle: shopifyProduct.handle,
          productName: shopifyProduct.title,
          productId,
          price: productPrices?.price,
        });
      }
    }
    if (action === ActionType.REMOVE) {
      gaCustomEvent(`${collectionTitle} - Remove from Wishlist`, "Click", shopifyProduct.title);
      if (shopifyProduct) {
        wishlistContext.removeItemFromWishlist({
          productHandle: shopifyProduct.handle,
          productId,
          price: productPrices?.price,
        });
      }
    }
  };
  if (!contentfulProduct) {
    return null;
  }

  const productCollectionClick = () => {
    if (typeof props.onClick === "function") {
      if (componentType === ComponentType.BYOB) {
        props.onClick(shopifyProduct);
      } else {
        props.onClick();
      }
    }

    // Set Cookies / Provider [TBD] - collection handle & title
    if (collectionHandle) {
      createCookie("bread_url", `/collections/${collectionHandle}`, 2);
      createCookie("bread_title", collectionTitle, 2);
    }

    trackProductClick({
      productId: productId.replace("gid://shopify/Product/", ""),
      price: variants[0].price,
      title,
      category: productType,
    });
  };
  const openQuickView = () => {
    context.openQuickView({
      linkToPDP,
      collectionHandle,
      cpPosition,
      product: {
        ...shopifyProduct,
        prices: productPrices,
      },
    });
  };

  const showQuickView =
    componentType === ComponentType.CP ||
    componentType === ComponentType.SEARCH ||
    componentType === ComponentType.PDP_ADS;

  const showQuickAdd =
    isMobile &&
    componentType !== ComponentType.BYOB &&
    !isSoldOut &&
    !shouldDisplayPreOrder &&
    !isPurchaseRestricted;

  const productMentionData = {
    id: productId,
    tags,
    isPreOrder: shouldDisplayPreOrder,
    preOrderShippingDate: contentfulProduct.preOrderShippingDate,
    type: productType,
  };

  return (
    <div className={className || ""}>
      <ALTag
        product={productWithInventory}
        componentType={ComponentType.CP}
        shouldDisplayPreOrder={shouldDisplayPreOrder}
      />
      <ProductCollectionOnClick
        to={linkToPDP}
        searchObject={searchObject}
        onClick={productCollectionClick}
        componentType={componentType}
      >
        <div className="product_collection_image_square">
          <div className="product_collection_image_square_container">
            {componentType === ComponentType.WISHLIST &&
              (wishlistContext?.wishlistItems?.includes(contentfulProduct.handle) ? (
                // remove
                <div
                  className={`product_collection_wishlist product_collection_wishlist_${componentType?.toLowerCase()}`}
                  onClick={(e) => toggleWishlist(e, ActionType.REMOVE)}
                >
                  <HeartIcon width="18" fill="#0B0F0E" stroke="#2D2927" />
                </div>
              ) : (
                // add
                <div
                  className={`product_collection_wishlist product_collection_wishlist_${componentType?.toLowerCase()}`}
                  onClick={(e) => toggleWishlist(e, ActionType.ADD)}
                >
                  <HeartIcon width="18" stroke="#2D2927" />
                </div>
              ))}
            {isMobile && showQuickView && (
              <button
                type="button"
                className="product_collection_quickview_icon"
                onClick={(e) => {
                  e.preventDefault();
                  openQuickView();
                }}
              >
                <SearchIcon height="24" stroke="#2D2927" />
              </button>
            )}
            <ProductImage
              shopifyProduct={shopifyProduct}
              quickAddToCart={quickAddToCart}
              componentType={componentType}
              isEngravable={contentfulProduct?.engravable?.isEngravable}
              handleQuickViewClick={openQuickView}
              isPurchaseRestricted={isPurchaseRestricted}
            />
          </div>
        </div>
      </ProductCollectionOnClick>

      <ProductCollectionOnClick
        to={linkToPDP}
        searchObject={searchObject}
        onClick={productCollectionClick}
        componentType={componentType}
      >
        <div className="product_collection_details">
          {componentType === ComponentType.BYOB && !isSoldOut && (
            <p className="product_collection_bundle_promo">
              ACT FAST: EXTRA {byobPercentageOff || 25}% OFF!
            </p>
          )}

          {!isSoldOut && (
            <ProductMention
              prices={productPrices}
              product={productMentionData}
              globalProductMention={localizedDiscount?.[0]?.globalProductText}
              componentType={componentType}
            />
          )}
          <div className="product_collection_details__wrapper">
            <div className="pr-5">
              <h3 className="m-0 subtext subtext--bold subtext--uc black">
                {contentfulProduct.title.split(" - ")[1]}
              </h3>
              {!isMobile && (
                <h2 className="product_collection_details_subtext microtext dark-grey">
                  {contentfulProduct.title.split(" - ")[0]}
                </h2>
              )}
            </div>
            {!isSoldOut && (
              <PriceContainer
                productId={productId}
                prices={productPrices}
                componentType={componentType}
                tags={shopifyProduct.tags}
                pricesLoading={productPrices === undefined}
              />
            )}
          </div>
          {isMobile && (
            <h2 className="product_collection_details_subtext product_collection_details_subtext_mobile microtext dark-grey">
              {contentfulProduct.title.split(" - ")[0]}
            </h2>
          )}

          {isSoldOut && (
            <p className="product_collection_soldout al_subtext">
              {dict.get("Out of stock")}. <u>{dict.get("Sign up for the wait list.")}</u>
            </p>
          )}
        </div>
      </ProductCollectionOnClick>

      {contentfulProduct?.productAlternatives && (
        <ProductAlternatives
          handle={contentfulProduct?.handle}
          productAlternatives={contentfulProduct.productAlternatives}
          componentType={ComponentType.CP}
          handleAlternativeClick={setActiveAlternative}
        />
      )}

      {showQuickAdd && (
        <QuickAddToCart
          product={shopifyProduct}
          onQuickAddToCart={quickAddToCart}
          collectionTitle={collectionTitle}
        />
      )}

      {/* Preorder + No variant available at all */}
      {isMobile && componentType !== ComponentType.BYOB && shouldDisplayPreOrder ? (
        <Link
          className="product_collection_pre-order link--underline"
          to={linkToPDP}
          onClick={productCollectionClick}
        >
          {!hasNoVariantsAvailable ? "View Product" : "Pre-order now"}
        </Link>
      ) : null}

      {componentType === ComponentType.BYOB && !isSoldOut && (
        <ButtonAddToBundle product={shopifyProduct} />
      )}
    </div>
  );
}

ProductCollectionContent.propTypes = {
  collectionHandle: PropTypes.string,
  collectionTitle: PropTypes.string,
  product: PropTypes.shape({
    contentful: PropTypes.shape({
      node: PropTypes.object,
    }),
    node: PropTypes.shape({
      title: PropTypes.string,
      handle: PropTypes.string,
      variants: PropTypes.array,
      shopifyId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  componentType: PropTypes.string.isRequired,
  cpPosition: PropTypes.number.isRequired,
  searchObject: PropTypes.object,
  className: PropTypes.string.isRequired,
  setActiveAlternative: PropTypes.func.isRequired,
  prices: PropTypes.array,
};

export default withALErrorBoundary({
  name: "ProductCollectionContent",
  priority: "P2",
})(ProductCollectionContent);
