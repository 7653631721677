import { createContext } from "react";
import { isBrowser, getCookie } from "./helpers";

export const defaultStoreContext = {
  _checkout: { lineItems: [], webUrl: null },
  trackCartProduct: [],
  addTrackCartProduct: () => {},
  products: [],
  shop: {},
  customerAccessToken:
    isBrowser && getCookie("accessToken") != null ? getCookie("accessToken") : null,
  sideCart: false,
  isActionInProgress: false,
  saleColors: null,
  seoDescriptionLinks: null,
  recommendationExponea: null,
  isBYOBOptionalItemPopupOpen: false,

  shippingFees: null,
  searchIndex: null,

  quickViewProductHandle: null,
  openQuickView: () => {},
  closeQuickView: () => {},
};

const StoreContext = createContext(defaultStoreContext);

export default StoreContext;
