// extracted by mini-css-extract-plugin
export var searchBox = "styles-module--search-box--726a6";
export var searchBox__autocomplete = "styles-module--search-box__autocomplete--adc3d";
export var searchBox__autocomplete__current = "styles-module--search-box__autocomplete__current--0017e";
export var searchBox__autocomplete__prediction = "styles-module--search-box__autocomplete__prediction--7b23e";
export var searchBox__input = "styles-module--search-box__input--49e10";
export var searchBox__inputRemove = "styles-module--search-box__input-remove--6251c";
export var searchBox__wrapper__autocomplete = "styles-module--search-box__wrapper__autocomplete--5c464";
export var searchClose = "styles-module--search-close--0df04";
export var searchIcon = "styles-module--search-icon--2fdcb";
export var suggestion = "styles-module--suggestion--ef0d5";
export var wrapper = "styles-module--wrapper--455b9";