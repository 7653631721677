import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

function secondsToTime(s) {
  const hours = Math.floor(s / (60 * 60));
  const divisorForMinutes = s % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);
  const divisorForSeconds = divisorForMinutes % 60;
  const seconds = Math.ceil(divisorForSeconds);

  return {
    h: hours,
    m: minutes,
    s: seconds,
  };
}

// inspo https://www.geeksforgeeks.org/how-to-create-a-countdown-timer-using-reactjs/
function CountdownTimer({ countdownSeconds }) {
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    const { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${
          seconds > 9 ? seconds : `0${seconds}`
        }`
      );
    }
  };

  const clearTimer = (e) => {
    const countdownTime = secondsToTime(countdownSeconds);
    setTimer(
      `${countdownTime.h.toString().padStart(2, "0")}:${countdownTime.m
        .toString()
        .padStart(2, "0")}:${countdownTime.s.toString().padStart(2, "0")}`
    );

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + countdownSeconds);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  return timer;
}

CountdownTimer.propTypes = {
  countdownSeconds: PropTypes.number.isRequired,
};

export default CountdownTimer;
