import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";

const CACHE_AVAILABILITY = 5 * 60 * 1000;

function useData(key, params = {}, canRetrieve = true) {
  const { sendReport } = useALError();

  const { data } = useQuery({
    queryKey: [key, params],
    queryFn: () =>
      axios
        .get(`/.netlify/functions/get-data`, {
          params: { ...params, key },
        })
        .then((res) => {
          if (res.status === 200 && res.data?.key === key) {
            return res.data.result;
          }
          console.error("Error on get-data status:", res.status);
          throw new Error("Error on get-data");
        }),
    staleTime: CACHE_AVAILABILITY,
    enabled: canRetrieve,
    meta: { name: "useData", priority: "P1", sendReport },
  });

  return data;
}

export default useData;
