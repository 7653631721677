import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { useLocalizedSentenceDict } from "../../../../../hooks/useSentenceDict";
import IconClose from "../../../../icons/svgs/close.svg";
import IconCheck from "../../../../icons/svgs/check.svg";

import "./qualityPopup/styles.scss";

function QualityPopup({ onClose }) {
  const dict = useLocalizedSentenceDict();

  return (
    <Popup closeOnDocumentClick open onClose={onClose} modal nested className="quality_popup">
      <button type="button" className="quality_popup__close" onClick={onClose}>
        <IconClose width="14" />
      </button>
      <IconCheck />
      <h4 className="h4 h4--bold">{dict.get("2-year Warranty")}</h4>
      <p className="quality_popup__subtitle subtext">{dict.get("No Risk, All Reward")}</p>
      <p className="quality_popup__main_content subtext">
        {dict.get(
          "Our 2-Year Warranty ensures your Ana Luisa online purchase placed on {0} is fully protected for two years. To use your warranty, email us at {1} with a description of the issue and a picture of the product. Upon evaluation, we’ll send a replacement or issue reimbursement based on your country of residence. Though we extensively test our products, our warranty may be applied against tarnishing, allergic reactions, water damage and breakage. We’re happy to help and want our customers to shop in total confidence!",
          "[analuisa.com](https://analuisa.com)",
          "[love@analuisa.com](mailto:love@analuisa.com)"
        )}
      </p>
    </Popup>
  );
}

QualityPopup.propTypes = {
  onClose: PropTypes.func,
};

export default QualityPopup;
