import { useContext } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import { GeoContext } from "../../../context/GeoProvider";
import { gaCustomEvent } from "../../../context/helpers";
import { useAuth } from "../../../context/AuthProvider";

import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import useIsHydrationComplete from "../../../hooks/useIsHydrationComplete";

import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { getShopifyStoreByCountry } from "../../../../locale-shopifies";

import AccountOverview from "../../AccountOverview";
import AccountLoggedOut from "../../AccountLoggedOut";

import { ALLink as Link } from "../../ALComponents";

import { Country } from "../../../constants/Country";

import IconHelp from "../../icons/svgs/help.svg";
import IconStore from "../../icons/svgs/store.svg";
import IconTrack from "../../icons/svgs/track.svg";
import IconReturns from "../../icons/svgs/returns_exchanges.svg";

import "./bottomMenu/styles.scss";

function BottomMenu({ closeMenu }) {
  const { gePriceDetails } = useContext(GeoContext);
  const { accountDetails } = useAuth();
  const hydrationComplete = useIsHydrationComplete();

  const dict = useLocalizedSentenceDict();

  const { allContentfulBottomMenu } = useStaticQuery(graphql`
    query BottomMenu {
      allContentfulBottomMenu(sort: { order: ASC, fields: [link___bottom_menu___itemOrder] }) {
        nodes {
          id
          node_locale
          title
          link {
            link {
              link
            }
          }
        }
      }
    }
  `);

  const mobileMenu = allContentfulBottomMenu.nodes.filter(
    (e) => e?.node_locale === gePriceDetails?.Culture
  );

  const onLinkClick = (label) => {
    closeMenu();
    gaCustomEvent("Menu Link", "Click", label);
  };

  const filteredMenu = mobileMenu.filter((item) => {
    const { title } = item;
    const isUS = gePriceDetails?.CountryCode === Country.US;
    return !["meet al luxe"].includes(title.toLowerCase()) || isUS;
  });

  const iconTextItems = [];
  const textItems = [];

  filteredMenu.forEach((item) => {
    const { title } = item;

    if (
      ["information", "track my order", "store", "returns & exchanges"].includes(
        title.toLowerCase()
      )
    ) {
      iconTextItems.push(item);
    } else {
      textItems.push(item);
    }
  });

  return (
    <div className="mobile_menu_footer">
      {hydrationComplete && accountDetails ? (
        <AccountOverview closeMenu={closeMenu} />
      ) : (
        <AccountLoggedOut />
      )}
      <div className="mobile_menu_footer__bottom">
        <div>
          {textItems.map((item) => {
            const { link } = item.link.link;
            const { title } = item;

            return (
              <Link
                className="mobile_menu_footer_link"
                key={`mobile_menu_footer_${item.id}`}
                to={link}
                onClick={() => onLinkClick(title.toUpperCase())}
              >
                <p>{dict.get(title)}</p>
              </Link>
            );
          })}
        </div>

        <div>
          {iconTextItems.map((item) => {
            const { link } = item.link.link;
            const { title } = item;

            if (link === "https://faq.analuisa.com") {
              return (
                <a
                  href={getShopifyStoreByCountry(gePriceDetails?.CountryCode)?.knowledgeBase}
                  className="mobile_menu_footer_link"
                  key={`mobile_menu_footer_${item.id}`}
                >
                  <IconHelp width="25" fill="none" className="mr-5" />
                  <p>{dict.get(title)}</p>
                </a>
              );
            }
            if (title.toLowerCase() === "track my order") {
              return (
                <Link
                  className="mobile_menu_footer_link"
                  key={`mobile_menu_footer_${item.id}`}
                  to={link}
                  onClick={() => onLinkClick(title.toUpperCase())}
                >
                  <IconTrack width="25" fill="none" className="mr-5" />
                  <p>{dict.get(title)}</p>
                </Link>
              );
            }
            if (title.toLowerCase() === "store") {
              return (
                <Link
                  className="mobile_menu_footer_link"
                  key={`mobile_menu_footer_${item.id}`}
                  to={link}
                  onClick={() => onLinkClick(title.toUpperCase())}
                >
                  <IconStore width="25" fill="none" stroke="#2D2927" className="mr-5" />
                  <p>{dict.get(title)}</p>
                </Link>
              );
            }
            if (title.toLowerCase() === "returns & exchanges") {
              return (
                <Link
                  className="mobile_menu_footer_link"
                  key={`mobile_menu_footer_${item.id}`}
                  to={link}
                  onClick={() => onLinkClick(title.toUpperCase())}
                >
                  <IconReturns width="22" fill="none" stroke="#2D2927" className="mr-5" />
                  <p>{dict.get(title)}</p>
                </Link>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

BottomMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};

export default withALErrorBoundary({
  name: "BottomMenu",
  priority: "P2",
})(BottomMenu);
