import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import ProductAlternativeTextBlockProduct from "./productAlternativeTextBlock/ProductAlternativeTextBlockProduct";
import * as productAlternativeStyles from "./styles.module.scss";

function ProductAlternativeTextBlock({
  pageProductHandle,
  alternatives,
  labelTitle,
  stepPrefix = "",
  componentType,
}) {
  const [sortedAlternatives, setSortedAlternatives] = useState(alternatives);
  const isMultiStep = stepPrefix !== "";

  useEffect(() => {
    const sortedAlternativesCopy = [...alternatives];

    const orderIndex = {};
    const CUSTOM_ORDER = [
      // Size example 1
      "Mini",
      "Small",
      "Medium",
      "Large",
      // Size example 2
      "Slim",
      "Bold",
    ];
    CUSTOM_ORDER.forEach((item, index) => {
      orderIndex[item] = index;
    });

    // Order alternatives
    sortedAlternativesCopy.sort((a, b) => {
      const [aLabelName] = a.attrArgs;
      const [bLabelName] = b.attrArgs;

      const indexA = orderIndex[aLabelName] !== undefined ? orderIndex[aLabelName] : Infinity;
      const indexB = orderIndex[bLabelName] !== undefined ? orderIndex[bLabelName] : Infinity;

      return indexA - indexB;
    });
    setSortedAlternatives(sortedAlternativesCopy);
  }, [alternatives]);

  if (!sortedAlternatives?.length) return null;
  const alternativesToDisplay = sortedAlternatives.filter((a) => a.isFound);

  return (
    <div
      className={
        isMultiStep
          ? productAlternativeStyles.containerMultiStep
          : productAlternativeStyles.container
      }
      key={labelTitle}
    >
      <div className={stepPrefix === "" ? "mb-5" : "mb-10"}>
        <span className={`pr-10 text ${stepPrefix === "" ? "text--bold" : ""}`}>
          {stepPrefix}
          {labelTitle}
        </span>
      </div>
      <div className={productAlternativeStyles.wrapper}>
        {alternativesToDisplay.map((a) => {
          const [labelName] = a.attrArgs;
          return (
            <ProductAlternativeTextBlockProduct
              key={a.handle}
              isActive={pageProductHandle === a.handle}
              handle={a.handle}
              availableForSale={a.availableForSale}
              activeProductHandle={pageProductHandle}
              title={labelName}
              fullWidth={alternativesToDisplay.length < 3}
              componentType={componentType}
            >
              {labelName}
            </ProductAlternativeTextBlockProduct>
          );
        })}
      </div>
    </div>
  );
}

ProductAlternativeTextBlock.propTypes = {
  pageProductHandle: PropTypes.string.isRequired,
  alternatives: PropTypes.arrayOf(
    PropTypes.shape({
      attrArgs: PropTypes.arrayOf(PropTypes.string).isRequired,
      isFound: PropTypes.bool.isRequired,
      handle: PropTypes.string.isRequired,
      attrName: PropTypes.string.isRequired,
      availableForSale: PropTypes.bool.isRequired,
    })
  ),
  labelTitle: PropTypes.string.isRequired,
  stepPrefix: PropTypes.string,
  componentType: PropTypes.string,
};

export default withALErrorBoundary({
  name: "ProductAlternativeTextBlock",
  priority: "P2",
})(ProductAlternativeTextBlock);
