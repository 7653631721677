import { useContext } from "react";
import { DiscountContext } from "../context/DiscountProvider";

// To convert eventually to constant in /shared/saleLogic
const LUXE_EXTRA_DISCOUNT_TAGS = ["Components:10K Solid Gold", "Components:10K Solid White Gold"];

export default function useIsBannedProduct(product, isLuxe = false) {
  const { discountInfo = {} } = useContext(DiscountContext);
  const { exclusionTags = [] } = discountInfo;

  if (!product || !product.tags) return false;

  const isIncludedInLuxe = isLuxe && product.tags.some((t) => LUXE_EXTRA_DISCOUNT_TAGS.includes(t));
  const isBanned = product.tags.some((tag) => exclusionTags.includes(tag));
  if (isBanned && isIncludedInLuxe) {
    return false;
  }
  return isBanned;
}
