import { ALSkeleton } from "../../ALComponents";
import "./upSellItem/styles.scss";

function UpSellItemPlaceholder() {
  return (
    <div className="upsell_item">
      <div className="upsell_item__link">
        <ALSkeleton />
      </div>
      <div className="upsell_item__content">
        <div className="upsell_item__content_title_price">
          <ALSkeleton width="60px" height="24px" />
          <ALSkeleton width="30px" height="24px" />
        </div>
        <div className="upsell_item__content_cta" style={{ padding: 0 }}>
          <ALSkeleton width="80px" height="32px" />
        </div>
      </div>
    </div>
  );
}

export default UpSellItemPlaceholder;
