import { useEffect, useState } from "react";
import { isBrowser, sanitizePhonePerCountry, validateEmail } from "../context/helpers";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";

export default function JustUnoTracking() {
  const [isJustUnoTrackedImpression, setJustUnoTrackedImpression] = useState(false);
  const { sendReport } = useALError();

  const hasJuapp = isBrowser && typeof window.juapp !== "undefined";

  useEffect(() => {
    if (hasJuapp) {
      window.juapp(
        "trackFunc",
        "engagement",
        (email, eventname, promotitle, cmid, options, form) => {
          // Extract Phone Number from form
          const _phoneNumber = form.find((e) => e.name.toLowerCase() === "phone_number")
            ? form.find((e) => e.name.toLowerCase() === "phone_number")?.value
            : null;

          if (_phoneNumber) {
            // Add phone number to Exponea
            const _phone = sanitizePhonePerCountry(_phoneNumber);
            window.exponea.identify(
              {
                phone_id: _phone,
              },
              {
                phone: _phone,
              }
            );

            try {
              window.edgetag("user", "phone", _phone);
              window.edgetag("tag", "Lead", {
                name: "JustUno Popup",
              });
            } catch (error) {
              sendReport(error, { name: "JustUnoTracking", priority: "P1" });
            }
          }

          if (validateEmail(email)) {
            window.exponea.identify(
              {
                email_id: email,
              },
              {
                email,
              }
            );

            window.exponea.track("subscribe_justuno_popup", {
              event_name: eventname,
              title: promotitle,
              id: cmid,
              phone: _phoneNumber,
            });

            // Trigger GTM subscribe_popup
            if (window.dataLayer) {
              window.dataLayer.push({
                event: "subscribe_popup",
                title: promotitle,
                id: cmid,
              });
            }

            try {
              window.edgetag("user", "email", email);
            } catch (error) {
              sendReport(error, { name: "JustUnoTracking", priority: "P1" });
            }
          }
        }
      );
      setJustUnoTrackedImpression(true);

      if (!isJustUnoTrackedImpression) {
        window.juapp("trackFunc", "impression", (email, eventname, promotitle, cmid) => {
          window.exponea.track("view_justuno_popup", {
            event_name: eventname,
            title: promotitle,
            id: cmid,
          });
        });
        setJustUnoTrackedImpression(true);
      }
    }
  }, [hasJuapp]);

  return null;
}
