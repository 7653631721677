export const mapDeliveryTimeContent = [
  {
    country: "AT", // Austria
    deliveryTime: 3,
  },
  {
    country: "BE", // Belgium
    deliveryTime: 2,
  },
  {
    country: "EE", // Estonia
    deliveryTime: 4,
  },
  {
    country: "FI", // Finland
    deliveryTime: 3,
  },
  {
    country: "FR", // France
    deliveryTime: 2,
  },
  {
    country: "DE", // Germany
    deliveryTime: 1,
  },
  {
    country: "GR", // Greece
    deliveryTime: 5,
  },
  {
    country: "IT", // Italy
    deliveryTime: 3,
  },
  {
    country: "LV", // Latvia
    deliveryTime: 4,
  },
  {
    country: "LT", // Lithuania
    deliveryTime: 4,
  },
  {
    country: "LU", // Luxembourg
    deliveryTime: 4,
  },
  {
    country: "MT", // Malta
    deliveryTime: 6,
  },
  {
    country: "NL", // Netherland
    deliveryTime: 1,
  },
  {
    country: "PT", // Portugal
    deliveryTime: 5,
  },
  {
    country: "SK", // Slovakia
    deliveryTime: 4,
  },
  {
    country: "SI", // Slovenia
    deliveryTime: 3,
  },
  {
    country: "ES", // Spain
    deliveryTime: 6,
  },
];
