import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { Popup } from "./Popup";
import useResponsiveWithHydrationFix from "../hooks/useResponsiveWithHydrationFix";
import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { ALButton } from "./ALComponents";

import IconClose from "./icons/svgs/close-round.svg";

import "./subscriptionPopUp/styles.scss";

function SubscriptionPopUp({ isOpen, onClose }) {
  const popUpImage = useResponsiveWithHydrationFix(
    <StaticImage
      src="../images/subscription/popup-mobile.png"
      alt="Mobile Subscription PopUp image"
    />,
    <StaticImage
      src="../images/subscription/popup-desktop.png"
      alt="Desktop Subscription PopUp image"
    />
  );

  return (
    <Popup className="popup_subscription" open={isOpen} closeOnDocumentClick onClose={onClose}>
      <button type="button" data-testid="close-button" className="popup_close" onClick={onClose}>
        <IconClose width="30" fill="none" stroke="#ffffff" />
      </button>
      <ALButton variant="primary" className="popup_button" onClick={onClose}>
        Continue shopping
      </ALButton>
      {popUpImage}
    </Popup>
  );
}

SubscriptionPopUp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withALErrorBoundary({
  name: "SubscriptionPopUp",
  priority: "P3",
})(SubscriptionPopUp);
