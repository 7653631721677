import PropTypes from "prop-types";
import useDeviceDetect from "../../../../hooks/useDeviceDetect";
import ALShopifyImage from "../../../al_components/ALShopifyImage";

import IconClose from "../../../icons/svgs/close-round-thick.svg";

import * as Styles from "./optionalProductsSelection/styles.module.scss";

function OptionalProductsSelection({ selectedProducts, handleRemoveProduct }) {
  const { isMobile } = useDeviceDetect();

  return (
    <div className={Styles.selected_product_container} data-testid="optional-products-selection">
      {selectedProducts.map((product, idx) => (
        <div
          key={`${product.id}_${idx}`}
          className={Styles.selected_product}
          data-testid="optional-products-selection-product"
        >
          <IconClose
            width={isMobile ? 20 : 22}
            className={Styles.close}
            stroke="#000000"
            fill="#FFFFFF"
            onClick={() => handleRemoveProduct(product)}
            data-testid="optional-products-selection-remove-button"
          />
          <ALShopifyImage
            width={110}
            height={110}
            url={product.images[0]?.url}
            alt={product.images[0]?.altText}
          />
          <p className={Styles.product_title}>{product.title.split(" - ")?.[1]}</p>
        </div>
      ))}
    </div>
  );
}

OptionalProductsSelection.propTypes = {
  selectedProducts: PropTypes.array.isRequired,
  handleRemoveProduct: PropTypes.func.isRequired,
};

export default OptionalProductsSelection;
