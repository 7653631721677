import { isBrowser } from "../helpers";

function retentionSendPageView() {
  if (!isBrowser || typeof window.geq === "undefined") {
    return false;
  }

  if (window.exponea) {
    const userCookie = window.exponea?.configuration?.customer?.cookie || null;
    if (userCookie && window.geq) {
      const userIdentifier = {
        user_id: window.exponea.configuration.customer.cookie,
      };
      window.geq.identify(userIdentifier);
    }
  }

  if (window.geq) {
    window.geq.page();
  }
  return true;
}
function retentionSendAddToCart({ price, title, productId, category, productHandle, images }) {
  if (!isBrowser) return;
  const atcitem = {
    name: title, // Replace with dynamic product name
    price, // Replace with dynamic product price
    productID: productId, // Replace with dynamic product ID
    categories: category,
    imageURL: images?.[0]?.url || "",
    URL: `https://www.analuisa.com/products/${productHandle}/`,
    brand: "Ana Luisa",
  };

  if (typeof geq !== "undefined" && typeof window.geq.addToCart === "function") {
    window.geq.addToCart(atcitem);
  }
}

function retentionViewProduct({ product }) {
  if (!isBrowser) return;
  const vpitem = {
    name: product?.title,
    price: product?.finalPrice,
    productID: product?.id?.replace("gid://shopify/Product/", "") || "",
    categories: product?.productType,
    imageURL: product?.images?.[0]?.url || "",
    URL: `https://www.analuisa.com/products/${product?.handle}/`,
    brand: "Ana Luisa",
  };

  if (typeof geq !== "undefined" && typeof window.geq.event === "function") {
    window.geq.event("Viewed Product Reclaim", vpitem);
  }
}

function retentionViewCollection({ url, name }) {
  if (!isBrowser) return;
  const categoryItem = {
    name,
    url: `https://www.analuisa.com${url}`,
  };

  if (typeof geq !== "undefined" && typeof window.geq.event === "function") {
    window.geq.event("Viewed Category Reclaim", categoryItem);
  }
}

export {
  retentionSendPageView,
  retentionSendAddToCart,
  retentionViewProduct,
  retentionViewCollection,
};
