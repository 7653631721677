const WHITELIST = {
  codes: [
    "ELISEBOERS20",
    "CARLI20",
    "MEIYAN20",
    "NADY20",
    "JUMEAUXX20",
    "ALYSSADARLAND20",
    "LAURALO20",
    "YORIKO20",
    "HOPECEE20",
    "SABIEMANCINI10",
    "MIKEALLAWEGNER10",
    "TRINITIJ20",
    "SARATEPES10",
    "ALLYVEE20",
    "COUNTRYSTITCHERS20",
    "LAURAMEACHEM20",
    "LAYOS20",
    "ANACRISTINAHARRIS20",
    "BSOOMD10",
    "OLIVIAROSE20",
    "CRISTINAN20",
    "ELEONORAMANNI20",
    "HANNAHRG20",
    "JAZZY20",
    "ASHLEYTAYLOR20",
    "AMBERLEE20",
    "SERHANT25",
    "WLSM25",
    "ACSM25",
    "BASM25",
    "SUNSM30",
    "WLEM25",
    "ACEM25",
    "BAEM25",
    "WIEM25",
    "SUEM30",
    "NEW25",
    "LAZY25",
  ],
  sources: ["yt", "youtube", "youtube+v2"], // whitelist ALL the discount from this source
  sourceAccepted: [
    "youtube",
    "yt",
    "facebook",
    "fb",
    "pinterest",
    "pt",
    "nl",
    "txt",
    "gs",
    "podcast",
    "popup",
    "exponea",
    "tiktok",
    "instagram",
    "ig",
    "impact",
    "affiliate",
    "linkedin",
    "lemon8",
    "direct",
  ],
};

export default WHITELIST;
