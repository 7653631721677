import { useContext } from "react";
import { navigate } from "gatsby";

import { BYOBContext } from "../../context/BYOBundle";

import { mapAttributeToProductList, separateLineItems } from "../../context/cartHelpers";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { getShopifyId } from "../../../functions/helpers";
import LineItem from "./LineItem";
import BYOBItems from "./BYOBItems";
import OptionalItemPopup from "./sideCartItems/OptionalItemPopup";
import { ComponentType } from "../../constants/ComponentType";
import {
  BYOB_ATTR_KEY,
  BYOB_URL_PARAM,
  BYOB_SOURCE_PAGE,
  BYOB_DEFAULT_SIZE,
  BYOB_CAN_HAVE_OPTIONAL_ITEM,
} from "../../constants/BYOBConfig";
import { CartContext } from "../../context/CartProvider";
import { HandleType } from "../../constants/HandleType";

const OPTIONAL_ITEM_EXTRA_PERCENT_OFF = 5;

function SideCartItems({ context, lineItems, toggleCart, freegiftLineItemId, isLuxe }) {
  const { byobPercentageOff, setOptionalItemEnabled } = useContext(BYOBContext);
  const { removeSingleProductsFromCart, isLoadingCart, setIsSideCartOpen } =
    useContext(CartContext);

  const { regularItems, customItems } = separateLineItems(lineItems, BYOB_ATTR_KEY);
  const customBundles = mapAttributeToProductList(customItems, BYOB_ATTR_KEY);

  const lineItemsbyContainers = [
    ...regularItems.map((item) => ({ componentType: ComponentType.LINE_ITEM, lineItems: [item] })),
    ...Object.keys(customBundles).map((bundleHash) => ({
      componentType: ComponentType.BYOB_ITEMS,
      lineItems: customBundles[bundleHash],
    })),
  ].sort(
    ({ lineItems: itemsA }, { lineItems: itemsB }) =>
      lineItems.findIndex((item) => item.id === itemsA[0].id) -
      lineItems.findIndex((item) => item.id === itemsB[0].id)
  );

  const removeItem = (lineItemId, lineItem) => {
    removeSingleProductsFromCart({ lineToRemove: lineItem });
  };

  /**
   * This method is used for:
   * - editing an existing bundle
   * - adding an optional item to an existing one
   */
  const onByobEdit = (items, optionalItemAdding) => {
    // customAttributes are the same for all items in a byob bundle
    const { attributes } = items[0];

    // byob_source_page
    const collectionPath = attributes.find(({ key }) => key === BYOB_SOURCE_PAGE)?.value;

    const canHaveOptionalItem = attributes.find(
      ({ key: attrKey }) => attrKey === BYOB_CAN_HAVE_OPTIONAL_ITEM
    )?.value;

    let editRoute = `${collectionPath}?${items
      .map(({ variant }) => `${BYOB_URL_PARAM}=${getShopifyId(variant.id)}`)
      .join("&")}&edit=${attributes.find(({ key }) => key === BYOB_ATTR_KEY)?.value}`;

    if (
      optionalItemAdding ||
      (canHaveOptionalItem === "true" && items.length === BYOB_DEFAULT_SIZE + 1)
    ) {
      editRoute += `&optionalItem=true`;
      setOptionalItemEnabled(true);
    }

    navigate(editRoute);

    setIsSideCartOpen(false);
    context.setBYOBOptionalItemPopup(false);
  };

  return (
    <div>
      {lineItemsbyContainers.map(({ componentType, lineItems }) => {
        if (componentType === ComponentType.LINE_ITEM) {
          const lineItem = lineItems[0];

          if (
            lineItem?.variant?.product?.handle === HandleType.ORDER_PROTECTION ||
            lineItem?.variant?.product?.handle === HandleType.SUBSCRIPTION
          )
            return null;
          return (
            <div key={`side-cart-item--${lineItem.id}`} className="sidecart-item">
              <LineItem
                key={lineItem.id}
                lineItem={lineItem}
                toggleCart={toggleCart}
                onRemove={(lineItemId) => removeItem(lineItemId, lineItem)}
                isFreeGift={freegiftLineItemId && freegiftLineItemId === lineItem?.id}
                isLuxe={isLuxe}
              />
            </div>
          );
        }
        if (componentType === ComponentType.BYOB_ITEMS) {
          return (
            <BYOBItems
              productList={lineItems}
              key={`byob-sidecart-${lineItems[0].id}`}
              onRemove={() => removeItem(lineItems[0].id, lineItems[0], false)}
              onEdit={() => onByobEdit(lineItems)}
              onOptionalItemAdd={() => onByobEdit(lineItems, true)}
            />
          );
        }
      })}
      {!isLoadingCart && (
        <OptionalItemPopup
          byobLineItems={lineItemsbyContainers[0].lineItems}
          handleOptionalItemAdd={onByobEdit}
          percentageOff={byobPercentageOff + OPTIONAL_ITEM_EXTRA_PERCENT_OFF}
        />
      )}
    </div>
  );
}

export default withALErrorBoundary({
  name: "SideCartItems",
  priority: "P1",
})(SideCartItems);
