import { isBrowser } from "../helpers";

export function pinSendPageVisit() {
  const hasPintrk = isBrowser && typeof window.pintrk !== "undefined";
  if (!hasPintrk) {
    return false;
  }
  if (isBrowser && hasPintrk && !window.location.pathname.includes("/products/")) {
    window.pintrk("track", "pagevisit");
  }
  return true;
}

// View Collection Page
export function pinSendViewCategory() {
  const hasPintrk = isBrowser && typeof window.pintrk !== "undefined";
  if (isBrowser && hasPintrk) {
    window.pintrk("track", "viewcategory");
  }
}

export function pinSendViewProductPage({ title, productId, productType, price }) {
  const hasPintrk = isBrowser && typeof window.pintrk !== "undefined";
  if (isBrowser && hasPintrk) {
    window.pintrk("track", "pagevisit", {
      line_items: [
        {
          product_name: title,
          product_id: productId,
          product_category: productType,
          product_price: parseFloat(price),
        },
      ],
    });
  }
}

export function pinSendAddToCart({
  category,
  currency,
  price,
  productId,
  variantId,
  quantity,
  title,
}) {
  const hasPintrk = isBrowser && typeof window.pintrk !== "undefined";
  if (isBrowser && hasPintrk) {
    window.pintrk("track", "addtocart", {
      value: price * quantity,
      order_quantity: quantity,
      currency,
      line_items: [
        {
          product_name: title,
          product_id: productId,
          product_category: category,
          product_variant_id: variantId,
          product_price: price,
        },
      ],
    });
  }
}
