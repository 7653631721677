import { useContext, useEffect, useMemo, useState } from "react";
import { GeoContext } from "../context/GeoProvider";
import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { isCrawlerBot } from "../context/helpers";
import { Popup } from "./Popup";
import { ALButton } from "./ALComponents";
import { locales } from "../../locales";
import IconCloseRound from "./icons/svgs/close-round.svg";
import ArrowIcon from "./icons/svgs/arrow.svg";

import * as Styles from "./countryConfirmationPopup/countryConfirmationPopup.module.scss";

function CountryConfirmationPopup() {
  const { countryDiffersPopupProps, closeCountryDiffersPopup, updateCountry } =
    useContext(GeoContext);
  const [selectedCountry, setSelectedCountry] = useState("US");

  useEffect(() => {
    if (countryDiffersPopupProps) {
      setSelectedCountry(countryDiffersPopupProps.CountryCode);
    }
  }, [countryDiffersPopupProps]);

  // Don't show the country confirmation popup for bots
  if (isCrawlerBot()) {
    return null;
  }

  const sortedLocales = useMemo(() => {
    const localesCopy = [...locales];
    localesCopy.sort((a, b) =>
      a.countryName.localeCompare(b.countryName, "en", { sensitivity: "base" })
    );
    return localesCopy;
  }, []);

  return (
    <Popup
      open={!!countryDiffersPopupProps}
      modal
      className="country-differ-popup"
      closeOnDocumentClick={false}
      onClose={closeCountryDiffersPopup}
    >
      <button
        type="button"
        className={Styles.buttonClose}
        onClick={closeCountryDiffersPopup}
        label="close"
      >
        <IconCloseRound fill="none" stroke="#2D2927" width="30px" />
      </button>
      <h3 className={`${Styles.title} h3 h3--bold h3--uc`}>Shipping somewhere else?</h3>
      <p className={`${Styles.description} text`}>
        It looks like you’re in a different country! We got you covered. Just select your location
        below and we’ll take care of the rest.
      </p>
      <form className={Styles.selectCountryForm} onSubmit={() => updateCountry(selectedCountry)}>
        <div className={Styles.selectCountryForm__selectContainer}>
          <label htmlFor="countries" className={`${Styles.selectCountryForm__label} subtext`}>
            Deliver to
            <select
              className={Styles.selectCountryForm__select}
              id="countries"
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
            >
              {sortedLocales.map((locale) => (
                <option key={locale.country} value={locale.country}>
                  {locale.countryName}
                </option>
              ))}
            </select>
            <div className={Styles.selectCountryForm__selectArrow}>
              <ArrowIcon width="8" height="14" fill="#2D2927" />
            </div>
          </label>
        </div>
        <ALButton className={Styles.selectCountryForm__submit} fullWidth type="submit">
          SAVE & CONTINUE
        </ALButton>
      </form>
    </Popup>
  );
}
export default withALErrorBoundary({ name: "CountryDiffersPopup", priority: "P2" })(
  CountryConfirmationPopup
);
