import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import IconSearch from "../icons/svgs/search.svg";

function ALSearchBox({ onChange, placeholder }) {
  return (
    <div className="al_search-box">
      <IconSearch height="17" width="17" stroke="#2D2927" fill="none" />
      <input
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        type="text"
        className="al_search-box--input al_p"
        autoFocus
      />
    </div>
  );
}

ALSearchBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default withALErrorBoundary({
  name: "ALSearchBox",
  priority: "P1",
})(ALSearchBox);
