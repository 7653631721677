import PropTypes from "prop-types";

import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { PaymentOption } from "../constants/PaymentOption";

import AmazonPaymentIcon from "./icons/payment/amazon.svg";
import AmexPaymentIcon from "./icons/payment/amex.svg";
import ApplePayPaymentIcon from "./icons/payment/applePay.svg";
import DinersPaymentIcon from "./icons/payment/diners.svg";
import DiscoverPaymentIcon from "./icons/payment/discover.svg";
import GiroPayPaymentIcon from "./icons/payment/giroPay.svg";
import IdealPaymentIcon from "./icons/payment/iDEAL.svg";
import JcbPaymentIcon from "./icons/payment/JCB.svg";
import KlarnaPaymentIcon from "./icons/payment/klarna.svg";
import MastercardPaymentIcon from "./icons/payment/mastercard.svg";
import PaypalPaymentIcon from "./icons/payment/paypal.svg";
import UnionPayPaymentIcon from "./icons/payment/unionPay.svg";
import VisaPaymentIcon from "./icons/payment/visa.svg";

function PaymentOptionImage({ payment }) {
  switch (payment) {
    case PaymentOption.AMAZON_PAY:
      return <AmazonPaymentIcon />;
    case PaymentOption.AMERICAN_EXPRESS:
      return <AmexPaymentIcon />;
    case PaymentOption.APPLE_PAY:
      return <ApplePayPaymentIcon />;
    case PaymentOption.DINERS:
      return <DinersPaymentIcon />;
    case PaymentOption.DISCOVER:
      return <DiscoverPaymentIcon />;
    case PaymentOption.GIRO_PAY:
      return <GiroPayPaymentIcon />;
    case PaymentOption.IDEAL:
      return <IdealPaymentIcon />;
    case PaymentOption.JCB:
      return <JcbPaymentIcon />;
    case PaymentOption.KLARNA:
      return <KlarnaPaymentIcon />;
    case PaymentOption.MASTERCARD:
      return <MastercardPaymentIcon />;
    case PaymentOption.PAYPAL:
      return <PaypalPaymentIcon />;
    case PaymentOption.UNION_PAY:
      return <UnionPayPaymentIcon />;
    case PaymentOption.VISA:
      return <VisaPaymentIcon />;
    default:
      return null;
  }
}

PaymentOptionImage.propTypes = {
  payment: PropTypes.string.isRequired,
};

export default withALErrorBoundary({
  name: "PaymentOptionImage",
  priority: "P3",
})(PaymentOptionImage);
