import { cloneElement, useCallback, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import useEmblaCarousel from "embla-carousel-react";

import * as Styles from "./autoScroll/styles.module.scss";

/**
 * This component was built to make the Embla (https://www.embla-carousel.com/) reusable
 * In order to use this Component in its current state, the children props must be a wrapper div with an array of elements nested as its direct children
 *
 * NOTE: This component can be scaled as needed
 * The wrapper div can have its own specified class
 */

function AutoScroll({
  children,
  speed = 5,
  delay = 1, // value used as milliseconds
  loop = true,
  draggable = false,
  gap = "5px",
}) {
  const [viewportRef, embla] = useEmblaCarousel({
    loop,
    dragFree: true,
    draggable,
    containScroll: "trimSnaps",
    containSnaps: false,
    align: "start",
  });
  const rafId = useRef(0); // requestAnimationFrame ID
  const emblaStyle = {
    overflowX: draggable ? "scroll" : "hidden",
  };

  const emblaContainerStyle = {
    columnGap: gap,
    paddingLeft: gap,
  };

  // Custom animate function to remove the default "snap" effect of Embla
  const animate = useCallback(() => {
    if (!embla || !rafId.current) return;

    const engine = embla.internalEngine();
    engine.location.add(-0.1 * speed);
    engine.target.set(engine.location);
    engine.scrollLooper.loop(-1);
    engine.slideLooper.loop();
    engine.translate.to(engine.location);
    rafId.current = requestAnimationFrame(animate);
  }, [embla]);

  const startAutoScroll = useCallback(() => {
    rafId.current = requestAnimationFrame(animate);
  }, [animate]);

  const stopAutoScroll = useCallback(() => {
    rafId.current = cancelAnimationFrame(rafId.current) || 0;
  }, []);

  useEffect(() => {
    if (!embla) return;

    embla.on("pointerUp", startAutoScroll);
    embla.on("pointerDown", stopAutoScroll);
    embla.on("settle", startAutoScroll);

    const timeoutId = setTimeout(() => startAutoScroll(), delay);
    return () => {
      embla.off("pointerDown", stopAutoScroll);
      embla.off("pointerUp", startAutoScroll);
      embla.off("settle", startAutoScroll);
      stopAutoScroll();
      clearTimeout(timeoutId);
    };
  }, [embla, startAutoScroll, stopAutoScroll]);

  return (
    <div
      className={`${Styles.horizontalScroll} ${children?.props?.className || ""}`}
      style={emblaStyle}
      ref={viewportRef}
    >
      <div className={`${Styles.horizontalScroll__content}`} style={emblaContainerStyle}>
        {children?.props?.children?.map((child, index) => {
          if (typeof child !== "undefined") {
            return cloneElement(child, {
              className: `${child?.props?.className || ""} ${Styles.horizontalScroll__slide}`,
              "data-index": index,
            });
          }
        })}
      </div>
    </div>
  );
}

export default AutoScroll;

AutoScroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  speed: PropTypes.number,
  delay: PropTypes.number,
  loop: PropTypes.bool,
  draggable: PropTypes.bool,
  gap: PropTypes.string,
};
