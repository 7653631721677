import { useState } from "react";
import PropTypes from "prop-types";
import { Popup } from "reactjs-popup";
import IconClose from "../../icons/svgs/close.svg";

function ProductEngraving({
  fonts,
  numberOfCharacters,
  engravableText,
  engravableFont,
  handleInputChange,
  setFont,
}) {
  const [openDetailsEngravable, setOpenDetailsEngravable] = useState(false);

  const closeEngravableModal = () => setOpenDetailsEngravable(false);

  return (
    <div className="pdp_details_engravable">
      <div className="container_engravable_text">
        <p className="container_engravable_title">
          {fonts.length === 1 ? "Text" : "Text and Font"}
        </p>
        <div className="container_engravable_input">
          <input
            className="container_engravable_text_input"
            title="Enter a number"
            type="text"
            placeholder={`Enter up to ${numberOfCharacters} characters`}
            name="engravable_value"
            required
            value={engravableText.toUpperCase()}
            onChange={(e) => handleInputChange(e, numberOfCharacters)}
          />
          <span className="container_engravable_text_size">
            {engravableText.length}/{numberOfCharacters}
          </span>
        </div>
      </div>
      {fonts.length > 1 ? (
        <div className="container_engravable_fonts">
          {fonts.map((font, i) => (
            <button
              type="button"
              key={`engravable_font_${i}`}
              style={{ fontFamily: font }}
              className={`container_engravable_font ${
                engravableFont === font ? "container_engravable_font--selected" : ""
              }`}
              onClick={() => setFont(font)}
            >
              {font}
            </button>
          ))}
        </div>
      ) : null}
      <div className="container_engravable_preview">
        <p
          className="container_engravable_title"
          style={{ color: engravableText ? "#000" : "#757575" }}
        >
          Preview
        </p>
        <p
          className={`container_engravable_preview_text engravable_${engravableFont
            .replace(/ /g, "_")
            .toLowerCase()}`}
          style={{ borderColor: engravableText ? "#000" : "#757575" }}
        >
          {engravableText}
        </p>
      </div>
      <p className="container_engravable_final al_p">
        Engraved items are final sale.{" "}
        <span
          style={{ cursor: "pointer" }}
          className="al_p--underline"
          onClick={() => setOpenDetailsEngravable((o) => !o)}
        >
          See more details
        </span>
      </p>

      <Popup open={openDetailsEngravable} closeOnDocumentClick onClose={closeEngravableModal}>
        <div className="notify_me_container">
          <div
            className="notify_me_container--close"
            onClick={closeEngravableModal}
            role="button"
            tabIndex="0"
            onKeyDown={closeEngravableModal}
          >
            <IconClose width="30" />
          </div>
          <div className="notify_me_content engravable_details_content">
            <p className="engravable_details_content_title al_h3-d">Custom engravings</p>
            <p className="al_p">Custom engraving is available in English alphabet A-Z uppercase</p>
            <p className="al_p">{`Engravings may include the following special characters: 0-9, ~ ! @ # $ % ^ & * ( ) - + = { [ } ] \ : ; " ' < , > . ? /`}</p>
            <p className="al_p">Custom engraving is available in English alphabet A-Z uppercase</p>
            <p className="al_p">
              Our engraved jewelry is made to order in our Brooklyn studio. Please allow 7-10 days
              for processing time on any orders containing engraved jewelry.
            </p>
            <p className="al_p">
              Engraved jewelry is final sale and cannot be returned or exchanged. We are unable to
              accommodate cancellations or changes to orders containing engraved jewelry.
            </p>
            <p className="al_p">Engraved items may differ slightly from preview</p>
          </div>
        </div>
      </Popup>
    </div>
  );
}

ProductEngraving.propTypes = {
  fonts: PropTypes.arrayOf(PropTypes.string),
  numberOfCharacters: PropTypes.number,
  engravableText: PropTypes.string,
  engravableFont: PropTypes.string,
  handleInputChange: PropTypes.func,
  setFont: PropTypes.func,
};

export default ProductEngraving;
