// extracted by mini-css-extract-plugin
export var popup_freegift = "ABPopupFreeGift-module--popup_freegift--1b95d";
export var popup_freegift_condition = "ABPopupFreeGift-module--popup_freegift_condition--a57ec";
export var popup_freegift_container = "ABPopupFreeGift-module--popup_freegift_container--6cb7a";
export var popup_freegift_container_details = "ABPopupFreeGift-module--popup_freegift_container_details--cf755";
export var popup_freegift_container_image = "ABPopupFreeGift-module--popup_freegift_container_image--93ab2";
export var popup_freegift_container_image_block = "ABPopupFreeGift-module--popup_freegift_container_image_block--d980e";
export var popup_freegift_container_img = "ABPopupFreeGift-module--popup_freegift_container_img--3a23b";
export var popup_freegift_container_price = "ABPopupFreeGift-module--popup_freegift_container_price--c2012";
export var popup_freegift_container_title = "ABPopupFreeGift-module--popup_freegift_container_title--99126";
export var popup_freegift_subtitle = "ABPopupFreeGift-module--popup_freegift_subtitle--4ae48";
export var popup_freegift_title = "ABPopupFreeGift-module--popup_freegift_title--56fb3";