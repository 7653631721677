import { createElement, useMemo, Fragment } from "react";

// Poorman's markdown, doesn't support nesting of formatting tags.
const toJsx = (s) => {
  const regexes = [
    {
      regex: /\*\*([^\*]+)\*\*/g,
      replaceFun: (match) => <span className="al_subtext--bold">{match[1]}</span>,
    },
    {
      regex: /\[([^[\]()]*)\]\(([^[\]()]+)\)(\{:target="_blank"\})*/g,
      replaceFun: (match) => {
        const target = match[3] ? match[3].replace(/[{}]/g, "").split("=")[1] : "_self";
        return (
          <a className="al_link" href={match[2]} target={target}>
            {match[1]}
          </a>
        );
      },
    },
  ];
  const matches = regexes
    .map((r) => {
      const ms = [...s.matchAll(r.regex)];
      return ms.map((m) => ({ match: m, replaceFun: r.replaceFun }));
    })
    .flat()
    .sort((ma, mb) => ma.match.index - mb.match.index);
  const children = [];
  let lastIdx = 0;
  for (const m of matches) {
    const prefix = s.slice(lastIdx, m.match.index);
    children.push(prefix);
    children.push(m.replaceFun(m.match));
    lastIdx = m.match.index + m.match[0].length;
  }
  if (lastIdx < s.length) children.push(s.slice(lastIdx));
  // matches.length > 0 && console.log("!!!", { matches, children });
  if (children.length === 1 && typeof children[0] === "string") return s;

  return createElement(Fragment, null, ...children);
};

export function useLocalizedSentenceDict() {
  return useMemo(
    () => ({
      get: function localizedSentence(englishString, ...args) {
        const sentence = args.reduce(
          (localizedString, arg, index) => localizedString.replace(`{${index}}`, arg),
          englishString
        );

        return toJsx(sentence);
      },
    }),
    []
  );
}
